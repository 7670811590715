<script>
import Labels from "@/config/types/label-types";
import { mergeData } from "vue-functional-data-merge";
import { bgColours, borderColours, textColours } from "@/config/labelColours";

export default {
  functional: true,
  props: {
    colour: {
      type: String,
      required: false,
      default: Labels.GREY,
      validator: colour => {
        return Object.values(Labels).indexOf(colour) !== -1;
      },
    },
  },
  render(h, { slots, props, data }) {
    const classes = [
      "ribbon",
      "text-left",
      "rounded-l",
      "p-2",
      "inline-block",
      "pr-6",
      "absolute",
      bgColours[props.colour],
      textColours[props.colour],
      borderColours[props.colour],
    ];

    return h("div", mergeData(data, { class: classes }), slots().default);
  },
};
</script>

<style lang="postcss">
$triangleSize: 1rem;

.ribbon {
  right: -1rem;
  /*transform: translateX(-100%);
  left: calc(100% + 2rem + $triangleSize);*/

  &:after {
    @apply absolute;
    content: "";
    top: 100%;
    left: auto;
    right: 0;
    border-style: solid;
    border-color: transparent;
    border-top-color: inherit;
    border-width: $triangleSize $triangleSize 0 0;
  }
}
</style>
