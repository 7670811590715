<template lang="pug">
.h-full.w-full.flex.flex-row.items-center(:class="{ 'cursor-pointer': params.enableSorting }" @click="toggleSort")
  .label {{ params.displayName }}
  .sort-direction(v-if="params.enableSorting")
    app-icon.ml-4(:icon="sortIcon", v-if="sortIcon")
</template>

<script>
import Vue from "vue";
import AppIcon from "@/components/elements/AppIcon.vue";

const DEFAULT_ORDER = "";

export default Vue.extend({
  components: {
    AppIcon,
  },
  data() {
    return {
      sortIcon: null,
      order: DEFAULT_ORDER,
    };
  },
  mounted() {
    this.params.column.addEventListener("sortChanged", this.onSortChanged);
    this.onSortChanged();
  },
  methods: {
    onSortChanged() {
      if (this.params.column.isSortAscending()) {
        this.order = "asc";
        this.sortIcon = "caretUp";
      } else if (this.params.column.isSortDescending()) {
        this.order = "desc";
        this.sortIcon = "caretDown";
      } else {
        this.order = DEFAULT_ORDER;
        this.sortIcon = null;
      }
    },
    nextOrder() {
      if (this.params.column.isSortAscending()) {
        return "desc";
      } else if (this.params.column.isSortDescending()) {
        return DEFAULT_ORDER;
      } else {
        return "asc";
      }
    },
    toggleSort() {
      if (this.params.enableSorting) {
        if (this.params.customSort) {
          const order = this.nextOrder();
          if (order === DEFAULT_ORDER) {
            this.params.columnApi.applyColumnState({
              defaultState: { sort: null },
            });
          } else {
            this.params.customSort(order, this.params.column.colId);
            const sort = [
              { colId: "position", sort: "asc", sortIndex: 0 },
              { colId: this.params.column.colId, sort: order, sortIndex: 1 },
            ];

            this.params.columnApi.applyColumnState({
              state: sort,
            });
          }
        } else {
          this.params.progressSort();
        }
      }
    },
  },
});
</script>
