<template lang="pug">
  .flex.flex-row.h-full
    app-label.self-center.ml-2.mr-2(v-for="label in labels", :key="label") label

</template>

<script>
import Vue from "vue";
import AppLabel from "@/components/elements/AppLabel.vue";

export default Vue.extend({
  components: {
    AppLabel,
  },
  computed: {
    labels() {
      return this.params.value;
    },
  },
});
</script>
