<template lang="pug">
  .mb-4
    .flex.flex-row
      .step-button.rounded-l(@click="decrement") -

      input.w-20.h-10.px-4.outline-none.border-t.border-b.border-grey-50.text-center(
        v-if="!readOnly"
        type="text"
        v-model="amount"
        @change="updateValue"
        @blur="onBlur"
        @focus="onFocus"
        class="focus:border-tribal-aqua")

      span.w-20.h-10.px-4.border-t.border-b.border-grey-50.text-center.inline-flex.items-center(v-else) {{ amount }}

      .step-button.rounded-r(@click="increment") +
</template>

<script>
import AppTextInput from "@/components/elements/AppTextInput.vue";
import FieldMixin from "@/mixins/Field";

export default {
  components: {
    AppTextInput,
  },
  mixins: [FieldMixin],
  props: {
    value: {
      type: Number,
      required: true,
    },
    suffix: {
      type: String,
      required: false,
      default: "",
    },
    stepSize: {
      type: Number,
      required: false,
      default: 1,
    },
    min: {
      type: Number,
      required: false,
      default: Number.MIN_SAFE_INTEGER || -9007199254740991,
    },
    max: {
      type: Number,
      required: false,
      default: Number.MAX_SAFE_INTEGER || 9007199254740991,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      amount: this.format(this.value),
      focused: false,
    };
  },
  methods: {
    decrement() {
      this.setValue(this.numberValue - this.stepSize);
    },
    increment() {
      this.setValue(this.numberValue + this.stepSize);
    },
    updateValue() {
      this.setValue(this.numberValue);
    },
    onBlur(evt) {
      // Return the input back to the formatted state
      this.$emit("blur", evt);
      this.focused = false;
      this.amount = this.format(this.numberValue);
    },
    onFocus(evt) {
      // Ensure the input shows the unformatted value when
      // the user focuses on the input
      this.$emit("focus", evt);
      this.focused = true;
      this.amount = this.numberValue;
    },
    format(value) {
      if (this.suffix !== "") {
        return `${value}${this.suffix}`;
      }

      return value;
    },
    setValue(val) {
      const processed = this.process(val);
      this.amount = this.focused ? processed : this.format(processed);
      this.$emit("input", processed);
    },
    process(val) {
      if (val >= this.max) {
        // Hit the mix limit
        return this.max;
      } else if (val <= this.min) {
        return this.min;
      } else {
        return val;
      }
    },
  },
  computed: {
    numberValue() {
      if (typeof this.amount === "string") {
        // Remove all non-number related characters
        const cleaned = this.amount.replace(/[^\d.,-]/g, "");
        const floatVal = cleaned ? parseFloat(cleaned) : null;

        if (isNaN(floatVal)) {
          return this.value;
        } else {
          return floatVal;
        }
      }

      return this.amount;
    },
  },
  watch: {
    value(newValue) {
      this.setValue(newValue);
    },
  },
};
</script>

<style lang="postcss" scoped>
.step-button {
  @apply bg-tribal-aqua;
  @apply h-10;
  @apply w-10;
  @apply text-white;
  @apply cursor-pointer;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply text-xl;
  @apply select-none;

  &:hover {
    @apply bg-diving-sapphire;
    @apply shadow-lg;
  }

  &:active {
    @apply shadow-none;
  }
}
</style>
