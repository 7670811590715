/**
 * Capitalise the first letter of the input string.
 * For example, "hello world" would become "Hello world".
 *
 * Note that it will not change the casing of the rest
 * of the string, only the first character
 */
function initCap(input) {
  if (typeof input !== "string") {
    return "";
  }

  return input.charAt(0).toUpperCase() + input.slice(1);
}

function random() {
  return Math.random()
    .toString(36)
    .substr(2, 9);
}

export { initCap, random };
