<template lang="pug">
  .fixed.right-0.z-50.p-8.top-0.mt-16.pointer-events-none
    transition-group(name="list")
      app-flash-message.mb-2(v-for="(message, id) in messages",
        :key="message.id",
        :type="message.type",
        :title="message.title",
        :text="message.text",
        :action="message.action",
        @click="actionClicked(message)")
</template>

<script>
import { mapGetters } from "vuex";
import AppFlashMessage from "@/components/elements/AppFlashMessage.vue";

export default {
  computed: {
    ...mapGetters("flashMessages", ["messages"]),
  },
  components: {
    AppFlashMessage,
  },
  methods: {
    actionClicked(message) {
      message.action.handler(() => {
        this.$store.commit("flashMessages/dismissFlashMessage", message);
      });
    },
  },
};
</script>

<style lang="postcss">
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.list-item {
  display: block;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}

.list-enter-active {
  animation-name: fadeInUp;
  animation-duration: 0.25s;
}

.list-move {
  transition: transform 0.25s;
}

.list-leave-active {
  animation-name: fadeOutDown;
  animation-duration: 0.25s;
}
</style>
