<script>
import Vue from "vue";
import AppLabel from "@/components/elements/AppLabel.vue";
import Labels from "@/config/types/label-types";
import { initCap } from "@/components/helpers/string-helpers";

export default Vue.extend({
  components: {
    AppLabel,
  },
  computed: {
    colour() {
      const status = this.params.value;
      switch (status.toLowerCase()) {
        case "not_started":
          return Labels.GREY;

        case "started":
          return Labels.BLUE;

        case "complete":
          return Labels.GREEN;
      }
      return this.params.value;
    },
    labelText() {
      const status = this.params.value.toLowerCase().replace(/_/g, " ");
      return initCap(status);
    },
  },
  render(h) {
    return h(AppLabel, { props: { colour: this.colour } }, this.labelText);
  },
});
</script>
