<template lang="pug">
  app-dropdown.w-full.h-full.flex.flex-col.justify-center(:options="menuItems", @click="optionClicked", :inline="false", v-if="menuItems.length")
    template(#trigger)
      .w-full.h-full.text-center.flex.flex-col.justify-center.cursor-pointer.items-center
        app-icon(icon="actions", block)

</template>

<script>
import Vue from "vue";
import AppDropdown from "@/components/collections/AppDropdown.vue";
import AppIcon from "@/components/elements/AppIcon.vue";

export default Vue.extend({
  components: {
    AppDropdown,
    AppIcon,
  },
  data() {
    return {
      menuItems: [],
    };
  },
  mounted() {
    this.refreshMenuItems();
  },
  methods: {
    /**
     * ag-grid method to ensure the options are refreshed
     * correctly
     */
    refresh() {
      this.refreshMenuItems();
      return true;
    },
    optionClicked(option) {
      this.params.context.tableComponent.contextMenuItemClicked(
        option,
        this.params.node
      );
    },
    refreshMenuItems() {
      this.menuItems = this.params.context.tableComponent.getContextMenuItems(
        this.params.node
      );
    },
  },
});
</script>
