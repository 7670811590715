<template lang="pug">
  .buttons
    app-panel
      .flex.flex-row
        app-button(primary) Primary button
        app-button.ml-4 Secondary button
        app-button.ml-4(icon="addDocument" primary) Primary icon
        app-button.ml-4(icon="addFolder") Secondary icon
        app-button.ml-4(danger) Danger
        app-dropdown.ml-4(:options="options", @click="actionClicked")
          template(#title) I am a custom title

        app-button.ml-4(round, primary)
          app-icon(icon="edit", colour="white")

      .flex.flex-row.mt-4
        app-button(primary, slim) Slim primary
        app-button(slim).ml-4 Slim secondary
        app-button.ml-4(slim, danger) Slim danger
        app-dropdown.ml-4(slim, :options="options", @click="actionClicked")
          template(#title) I am a different title

      .flex.flex-row.mt-4
        app-button(primary, :loading="true") I'm a loading button
        app-button.ml-4(primary, :loading="loading", loading-text="Yay!", @click="startLoading") Click to load

      .flex.flex-row.mt-4
        app-dropdown(:options="options")
          template(#trigger="props")
            app-avatar(:avatar="avatar", :class="{ border: props.open }")

          template(#title)
            p Signed in as
            strong John Andrews

        app-dropdown.ml-8(:options="options", pointing)
          template(#trigger="props")
            app-avatar(:avatar="avatar", :class="{ border: props.open }")

          template(#title)
            p Pointing dropdown

      .flex.flex-row.mt-4
        app-dropdown(pointing)
          template(#trigger)
            | Click me!!
          template(#content)
            .p-8
              | I am a custom dropdown. No options here

      .flex.flex-row.mt-4
        textarea.border-grey-50.border(ref='customOrigin' placeholder="I am the target of a dropdown which has a random offset"
                                       cols="30" rows="3" @click.prevent="openCustomDropdown" @focus="openCustomDropdown")
        app-dropdown(trigger="manual" :origin="customOrigin" placement="top-start" :flip="false")
          template(#content)
            .p-8 I am placed on the top-start with a random offset

      .flex.flex-row.mt-4
        app-dropdown(trigger="hover")
          template(#content)
            .p-8 Hello world

    app-panel
      app-header Toggle buttons

      p Toggle buttons allow you to present a small number of options, where only one can be
      p selected. The options are presented similar to a button that slides when selected.

      app-tabs
        app-tab(name="Demo", selected)

          p By default, a toggle button will act as an enable/disable toggle

          .flex.flex-row.mt-4
            app-toggle-button(v-model="toggles.default")
            strong.ml-8.self-center Chosen value: {{ toggles.default }}

          p.mt-8 If you have specific options, you can specify an array of strings to display instead

          .flex.flex-row.mt-4
            app-toggle-button(v-model="toggles.option", :options="['Annually', 'Quarterly', 'Monthly']")
            strong.ml-8.self-center Chosen value: {{ toggles.option }}

          p.mt-8 Optionally, you can also provide an array of objects if you want to use a different value,
          p or show an icon

          .flex.flex-row.mt-4
            app-toggle-button(v-model="toggles.objectOption", :options="toggles.objectOptions")
            strong.ml-8.self-center Chosen value: {{ toggles.objectOption }}

        app-tab(name="Examples")
          pre.my-8
            code.language-javascript
              | app-toggle-button(v-model="enabled")
              |
              | app-toggle-button(v-model="displayType", :options="displayOptions")

    app-panel
      app-header Split buttons

      p Split buttons allow you to present a button with a default action, that also contains a
      p dropdown with additional options.

      app-tabs
        app-tab(name="Demo" selected)
          
          app-split-button(:options="options") Default action
</template>

<script>
import AppAvatar from "@/components/elements/AppAvatar.vue";
import AppButton from "@/components/elements/AppButton.vue";
import AppDropdown from "@/components/collections/AppDropdown.vue";
import AppHeader from "@/components/elements/AppHeader.vue";
import AppIcon from "@/components/elements/AppIcon.vue";
import AppPanel from "@/components/elements/AppPanel.vue";
import AppSplitButton from "@/components/elements/AppSplitButton.vue";
import AppTab from "@/components/elements/AppTab.vue";
import AppTabs from "@/components/elements/AppTabs.vue";
import AppToggleButton from "@/components/elements/AppToggleButton.vue";

export default {
  components: {
    AppAvatar,
    AppButton,
    AppDropdown,
    AppHeader,
    AppIcon,
    AppPanel,
    AppSplitButton,
    AppTab,
    AppTabs,
    AppToggleButton,
  },
  data() {
    return {
      avatar: {
        url: "https://via.placeholder.com/200",
      },
      loading: false,
      customOrigin: null,
      options: [
        { type: "content", slot: "title" },
        { type: "divider" },
        { label: "Edit project", icon: "edit" },
        { label: "Edit permissions", icon: "padlock" },
        { type: "divider" },
        { label: "Copy project", icon: "copy" },
        { label: "Import tasks", icon: "import" },
        { label: "Export project", icon: "export" },
        { label: "Snapshot report", icon: "snapshot" },
        { type: "divider" },
        { label: "Close project", icon: "error" },
        {
          label: "Delete",
          icon: "delete",
          iconColour: "red",
        },
      ],
      toggles: {
        default: false,
        option: "Annually",
        objectOption: { value: "app", label: "Approved", icon: "tick" },
        objectOptions: [
          { value: "app", label: "Approved", icon: "tick" },
          { value: "rej", label: "Rejected", icon: "close" },
        ],
      },
    };
  },
  methods: {
    openCustomDropdown() {
      this.customOrigin = {
        getBoundingClientRect: () => {
          const customOriginRect = this.$refs.customOrigin.getBoundingClientRect();
          const customOffsetX = Math.random() * 100;
          // const customOffsetY = Math.random() * 100;
          customOriginRect.x += customOffsetX;
          // customOriginRect.y += customOffsetY;

          return customOriginRect;
        },
      };
    },
    actionClicked(action) {
      alert(`Clicked: ${action.label}`);
    },
    startLoading() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 3000);
    },
  },
};
</script>
