export default {
  install(Vue, { store }) {
    const normaliseArgs = actionOrOpts => {
      if (actionOrOpts === null) {
        return {};
      }

      if (actionOrOpts.constructor === Object) {
        return actionOrOpts;
      }

      return {
        action: actionOrOpts,
      };
    };

    const showFlash = (type, text, actionOrOpts) => {
      store.dispatch(`flashMessages/${type}`, {
        text,
        opts: normaliseArgs(actionOrOpts),
      });
    };

    const fns = {
      info(text, actionOrOpts = null) {
        showFlash("info", text, actionOrOpts);
      },
      warn(text, actionOrOpts = null) {
        showFlash("warn", text, actionOrOpts);
      },
      error(text, actionOrOpts = null) {
        showFlash("error", text, actionOrOpts);
      },
      success(text, actionOrOpts = null) {
        showFlash("success", text, actionOrOpts);
      },
    };

    Vue.prototype.$flash = fns;
    this.info = fns.info;
    this.warn = fns.warn;
    this.error = fns.error;
    this.success = fns.success;
  },
};
