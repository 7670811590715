<template lang="pug">
  .flex.flex-row.px-4.py-2.relative.items-center(:class="{ active }")
    app-icon.mr-4(v-if="option.icon" :icon="option.icon" colour="inherit")
    span.cursor-pointer.flex-1(@click="$emit('click', option)") {{ option.label }}
    app-dropdown.flex-initial(:options="actions" v-if="actions" @click="$emit('action-click', $event)")
      template(#trigger)
        app-icon.cursor-pointer(icon="actions" v-if="actions" colour="inherit")
</template>

<script>
import AppDropdown from "@/components/collections/AppDropdown.vue";
import AppIcon from "@/components/elements/AppIcon.vue";

export default {
  components: {
    AppDropdown,
    AppIcon,
  },
  props: {
    actions: {
      type: Array,
      required: false,
      default: () => null,
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    option: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="postcss" scoped>
.active {
  @apply text-white;
  z-index: 1;

  &:after {
    content: "";
    z-index: -1;
    @apply absolute;
    @apply inset-0;
    @apply bg-tribal-aqua;
    @apply rounded-lg;
  }
}
</style>
