<template lang="pug">
  div
    mixin avatar(klasses)
      div(:is="componentElement" v-bind="componentAttrs" :class=klasses)
        template(v-if="!avatar") ?

    app-dropdown(trigger="hover" v-if="user" pointing :inline="false")
      template(#trigger)
        +avatar("classes()")
      template(#content)
        .flex.flex-row.w-64.h-full.p-4.border-grey-30
          +avatar("classes('flex-none self-center')")
          .flex-1.ml-4.self-center
            p.leading-none.grey {{ user.name }}
            p.leading-none.text-grey-60.text-sm.mt-1(v-if="user.roleName")  {{ user.roleName }}

    template(v-else)
      +avatar("classes()")
</template>
<script>
// import { mergeData } from "vue-functional-data-merge";
import AppDropdown from "@/components/collections/AppDropdown.vue";

const sizes = {
  normal: "w-12 h-12",
};

export default {
  components: {
    AppDropdown,
  },

  props: {
    user: {
      type: Object,
      required: false,
    },
    avatar: {
      type: Object,
      required: false,
    },
    size: {
      type: String,
      required: false,
      default: "normal",
      validator: prop => Object.keys(sizes).indexOf(prop) !== -1,
    },
    bordered: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    classes() {
      let classes = [sizes[this.size], "rounded-full"];

      if (this.bordered) {
        classes.push("border-2 border-white");
      }

      if (!this.avatar) {
        classes.push(
          "inline-block bg-grey-30 text-center text-xl text-grey-60 leading-12"
        );
      }

      return klasses => [...classes, klasses];
    },

    componentElement() {
      let avatar = this.avatar || (this.user && this.user.avatar);

      return avatar ? "img" : "div";
    },

    componentAttrs() {
      let avatar = this.avatar || (this.user && this.user.avatar);

      return avatar ? { src: avatar.url } : {};
    },
  },
};
</script>
