<template lang="pug">
  .notices
    app-panel
      app-header Notice bars

      p A notice bar provides the user with some information, attached to the bottom of the screen.
      p Multiple notices will automatically rotate, showing only one at a time.

    app-panel
      app-header Basic usage

      p A basic notice provides nothing more than a line of text. There are two styles of notice that you can use

      app-header(size="h4") Info notices

      p An info notice should be used to provide useful, neutral information.

      pre.my-8
        code.language-javascript
          | this.$notice.info("You are logged in")

      app-button(@click="singleInfo()") Add info notice

      app-header.mt-8(size="h4") Warning notices

      p Warning notices should be used to provide a persistent warning to users

      pre.my-8
        code.language-javascript
          | this.$notice.warn("You are logged in as a superadmin")

      app-button(@click="singleWarning()") Add warning notice

    app-panel
      app-header Action notices

      p Optionally, a notice can contain one or more actions. These are shown as links after the notice text

      pre.my-8
        code.language-javascript
          | this.$notice.info("You are logged in", [{
          |   text: "OK Thanks",
          |   action() { alert("Thank you"); }
          | }, {
          |   text: "That's not good",
          |   action() { alert("Bye"); }
          | }])

      app-button(@click="actionWarning()") Add warning with action
</template>

<script>
import AppButton from "@/components/elements/AppButton.vue";
import AppHeader from "@/components/elements/AppHeader.vue";
import AppPanel from "@/components/elements/AppPanel.vue";

export default {
  components: {
    AppButton,
    AppHeader,
    AppPanel,
  },
  data() {
    return {};
  },
  methods: {
    actionWarning() {
      this.$notice.warn("You need to do something", [
        {
          text: "Do it",
          action() {
            alert("You clicked me");
          },
        },
        {
          text: "Don't do it",
          action() {
            alert("Uh oh");
          },
        },
      ]);
    },
    singleInfo() {
      this.$notice.info("Hello, I am an info notice");
    },
    singleWarning() {
      this.$notice.warn("Hello, I am a warning notice");
    },
  },
};
</script>
