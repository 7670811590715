<template lang="pug">
  .mb-4
    label(v-if="showLabel" :for="name" :class="errorClasses")
      slot(name="label" :invalid="hasErrors")
        span(:class="labelClasses") {{ fieldLabel }}
      slot(name="caption", v-if="description")
        span(:class="descriptionClasses") {{ description }}

    app-checkbox(:error="hasErrors", v-model="fieldValue", :label="optionLabel")
</template>

<script>
import FieldMixin from "@/mixins/Field";
import AppCheckbox from "@/components/elements/AppCheckbox.vue";

export default {
  mixins: [FieldMixin],
  components: {
    AppCheckbox,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    optionLabel: {
      type: String,
      required: true,
    },

    showLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    checkedOptions: {
      get() {
        return this.value;
      },

      set(value) {
        if (this.preferredValidator.$touch) {
          this.preferredValidator.$touch();
        }

        this.$emit("input", value);
      },
    },
  },
  methods: {
    optionClasses(option) {
      let classes = [];

      if (this.optionChecked(option)) {
        classes.push("text-tribal-aqua font-bold");
      } else {
        classes.push("text-grey-50");
      }

      return classes;
    },

    checkOption(option) {
      const checkedOptions = [...this.checkedOptions];
      if (this.optionChecked(option)) {
        let idx = checkedOptions.indexOf(option.value);
        checkedOptions.splice(idx, 1);
      } else {
        checkedOptions.push(option.value);
      }

      this.checkedOptions = checkedOptions;
    },

    optionChecked(option) {
      return this.checkedOptions.indexOf(option.value) !== -1;
    },
  },
};
</script>

<style lang="postcss" scoped>
input[type="checkbox"] {
  & + label span {
    transition: background 0.2s, transform 0.2s;
  }

  &:checked + label span {
    box-shadow: 0px 0px 0px 2px var(--white) inset;
  }
}
</style>
