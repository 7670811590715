<template lang="pug">
  .mb-4
    label(:for="name" :class="errorClasses")
      slot(name="label" :invalid="hasErrors")
        span(:class="labelClasses") {{ fieldLabel }}
      slot(name="caption", v-if="description")
        span(:class="descriptionClasses") {{ description }}

    app-toggle-switch(v-model="fieldValue" :true-label="trueLabel" :false-label="falseLabel")
</template>

<script>
import FieldMixin from "@/mixins/Field";
import AppToggleSwitch from "@/components/elements/AppToggleSwitch.vue";

export default {
  mixins: [FieldMixin],
  components: {
    AppToggleSwitch,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },

    trueLabel: {
      type: String,
      required: false,
      default: "Yes",
    },

    falseLabel: {
      type: String,
      required: false,
      default: "No",
    },
  },
  computed: {
    // We'll mark this as required, as radio buttons always have
    // one thing selected at least
    required() {
      return true;
    },
  },
};
</script>
