<template lang="pug">
  .mb-4
    label(:for="name" :class="errorClasses")
      slot(name="label" :invalid="hasErrors")
        span(:class="labelClasses") {{ fieldLabel }}
      slot(name="caption", v-if="description")
        span(:class="descriptionClasses") {{ description }}

    vue-numeric(
      :class='inputClasses'
      v-model='fieldValue',
      :currency='symbol',
      :separator='thousandsSeparator',
      :decimal-separator='decimalPoint',
      :precision='numberPrecision',
      :currency-symbol-position='symbolPosition',
      :empty-value='null',
      :minus='true',
      v-on='$listeners',
    )
</template>

<script>
import FieldMixin from "@/mixins/Field";
import VueNumeric from "vue-numeric";

export default {
  components: {
    VueNumeric,
  },
  mixins: [FieldMixin],
  props: {
    type: {
      type: String,
      default: "number",
    },
    currency: {
      type: Object,
    },
    precision: {
      type: Number,
      default: 2,
    },
    classes: String,
  },
  computed: {
    inputClasses() {
      let classes = [
        "input outline-none h-12 px-3 py-2 border border-solid rounded-lg",
      ];

      if (this.error) {
        classes.push("text-split-crimson", "border-withered-cherry");
      } else {
        classes.push("focus:border-tribal-aqua", "border-grey-50");
      }

      return classes;
    },
    isCurrency() {
      return this.type === "currency" && !!this.currency;
    },
    symbol() {
      if (this.isCurrency) {
        return this.currency.symbol;
      }
      if (this.type === "percentage") {
        return "%";
      }
      return "";
    },
    symbolPosition() {
      if (this.isCurrency) {
        return this.currency.symbolFirst ? "prefix" : "suffix";
      }
      if (this.type === "percentage") {
        return "suffix";
      }
      return "prefix";
    },
    decimalPoint() {
      return this.isCurrency ? this.currency.decimalMark : ".";
    },
    thousandsSeparator() {
      return this.isCurrency ? this.currency.thousandsSeparator : ",";
    },
    numberPrecision() {
      return this.isCurrency ? this.currency.precision : this.precision;
    },
  },
  methods: {
    onBlurHandler(e) {
      this.$emit("blur", e);
    },
  },
};
</script>
