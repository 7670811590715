import Vue from "vue";
import Container from "@/components/elements/dialog/AppDialogContainer.vue";
import ConfirmationDialog from "@/components/elements/dialog/AppConfirmationDialog.vue";

const show = (Dialog, props) => {
  let vm;
  let okFns = [];
  let cancelFns = [];
  const api = {
    hide() {
      vm.$refs.dialog.hide();
    },
    onOk(fn) {
      okFns.push(fn);
      return api;
    },
    onCancel(fn) {
      cancelFns.push(fn);
      return api;
    },
  };

  const node = document.createElement("div");
  document.body.appendChild(node);

  const dialogListeners = {
    ok(data) {
      okFns.forEach(fn => fn({ data, api }));
    },
    cancel(data) {
      cancelFns.forEach(fn => fn({ data, api }));
      vm.$refs.dialog.hide();
    },
  };

  const vmListeners = {
    hide() {
      vm.$destroy();
      vm.$el.remove();
      vm = null;
    },
  };

  vm = new Vue({
    ...Vue.prototype.$dialog.vueOpts,
    el: node,
    render(h) {
      const dialog = h(Dialog, {
        on: dialogListeners,
        props: {
          ...props,
        },
      });

      return h(
        Container,
        {
          ref: "dialog",
          on: vmListeners,
        },
        [dialog]
      );
    },
    mounted() {
      this.$refs.dialog.show();
    },
  });

  return api;
};
const confirm = props => {
  return show(ConfirmationDialog, props);
};

export default {
  install(Vue, options) {
    Vue.prototype.$dialog = {
      confirm,
      show,
      vueOpts: options.vueOpts || {},
    };

    this.confirm = confirm;
    this.show = show;
  },
};
