import { singleErrorExtractorMixin } from "vuelidate-error-extractor";

const FieldMixin = {
  mixins: [singleErrorExtractorMixin],

  props: {
    value: {
      type: [String, Number],
      default: "",
    },

    description: {
      type: String,
      default: "",
    },
  },

  computed: {
    fieldValue: {
      get() {
        return this.value;
      },

      set(value) {
        if (this.preferredValidator.$touch) {
          this.preferredValidator.$touch();
        }
        this.$emit("input", value);
      },
    },

    fieldLabel() {
      let label = this.label;

      if (!this.required && this.$attrs["show-optional"] !== false) {
        label += " (optional)";
      }

      return label;
    },

    required() {
      if (!this.preferredValidator) return false;
      return typeof this.preferredValidator["required"] !== "undefined";
    },

    errorClasses() {
      return this.hasErrors ? "text-withered-cherry font-bold error" : "";
    },

    labelClasses() {
      let classes = this.errorClasses;
      classes += " block text-lg text-grey-80";

      if (!this.hasDescription) {
        classes += " mb-3";
      }

      return classes;
    },

    descriptionClasses() {
      let classes = this.errorClasses;
      classes += " block text-grey-70";

      if (this.hasDescription) {
        classes += " mb-3";
      }

      return classes;
    },

    hasDescription() {
      return !!this.description;
    },
  },
};

export default FieldMixin;
