<template lang="pug">
  .bg-white.w-full.h-full.overflow-hidden
    .flex.flex-row.border-b.border-grey-30.py-4.px-8.items-center(v-for="n in 20")
      .h-8.w-8.mr-8.flex-initial.shimmer
      .h-6.flex-initial.shimmer(class="w-1/4")
      .h-6.w-12.flex-initial.ml-20.shimmer
      .h-6.w-16.flex-initial.ml-20.shimmer
</template>

<script>
import Vue from "vue";
export default Vue.extend({});
</script>
