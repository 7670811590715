<template lang="pug">
  .dialog.bg-white(@keyup.enter="$emit('ok')")
    app-header(margin="mb-4") {{ title }}
    p.mb-8 {{ message }}

    .text-right
      app-button.ml-4(@click.prevent="$emit('cancel')") {{ cancelLabel }}
      app-button.ml-4(:primary="!danger", :danger="danger", @click.prevent="$emit('ok', 'test')") {{ confirmLabel }}
</template>

<script>
import AppButton from "@/components/elements/AppButton.vue";
import AppHeader from "@/components/elements/AppHeader.vue";

export default {
  components: {
    AppButton,
    AppHeader,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: "Are you sure?",
    },
    message: {
      type: String,
      required: true,
    },
    confirmLabel: {
      type: String,
      required: false,
      default: "Yes, I'm sure",
    },
    cancelLabel: {
      type: String,
      required: false,
      default: "Cancel",
    },
    danger: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="postcss" scoped>
.dialog {
  @apply p-8;
  @apply shadow-lg;
  @apply rounded-lg;
  width: 500px;
  min-height: 200px;
}
</style>
