import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      value: "",
      cancelled: false,
    };
  },
  created() {
    this.value = this.params.value;
  },
  validations() {
    let validations = {
      value: {},
    };

    if (this.params.required) {
      validations.value.required = required;
    }

    return validations;
  },
  methods: {
    cancelEdit(e) {
      this.stopEditing(true, e);
    },
    isCancelAfterEnd() {
      return this.cancelled || this.$v.value.$error;
    },
    getValue() {
      return this.value;
    },
    setValue(value) {
      this.value = value;
    },
    setValueAndClose(value) {
      this.value = value;
      this.stopEditing(false);
    },
    finishEditing() {
      this.stopEditing(false);
    },
    stopEditing(cancelled, evt) {
      this.cancelled = cancelled;
      this.params.api.stopEditing();
      if (evt && evt.preventDefault) {
        evt.preventDefaut();
      }
    },
  },
};
