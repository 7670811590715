<script>
import { mergeData } from "vue-functional-data-merge";
import extMappings from "@/config/fileExtensionMapping";

const sizeClasses = {
  small: "w-6",
  medium: "w-8",
};

const icons = {
  image: require("!html-loader!@/assets/svg/file_icons/image.svg"),
  folder: require("!html-loader!@/assets/svg/file_icons/folder.svg"),
  pdf: require("!html-loader!@/assets/svg/file_icons/pdf.svg"),

  word: require("!html-loader!@/assets/svg/file_icons/word.svg"),
  excel: require("!html-loader!@/assets/svg/file_icons/excel.svg"),
  powerpoint: require("!html-loader!@/assets/svg/file_icons/powerpoint.svg"),

  csv: require("!html-loader!@/assets/svg/file_icons/csv.svg"),
  html: require("!html-loader!@/assets/svg/file_icons/html.svg"),
  text: require("!html-loader!@/assets/svg/file_icons/text.svg"),
  video: require("!html-loader!@/assets/svg/file_icons/video.svg"),
  xd: require("!html-loader!@/assets/svg/file_icons/xd.svg"),

  rar: require("!html-loader!@/assets/svg/file_icons/rar.svg"),
  zip: require("!html-loader!@/assets/svg/file_icons/zip.svg"),

  default: require("!html-loader!@/assets/svg/file_icons/default.svg"),
};

export default {
  functional: true,
  props: {
    extension: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: "medium",
      validator: size => Object.keys(sizeClasses).indexOf(size) !== -1,
    },
  },
  render(createElement, { props, data }) {
    let html = "";
    let style = {};
    // Load up the SVG file itself

    const ext = extMappings[props.extension.toLowerCase()];
    html = icons[ext];

    if (!html) {
      html = icons["default"];
    }

    return createElement(
      "div",
      mergeData(data, {
        class: [sizeClasses[props.size]],
        style,
        domProps: { innerHTML: html },
      })
    );
  },
};
</script>
