import Vue from "vue";
import Vuex from "vuex";

import flashMessages from "@/store/modules/flash-messages";
import notices from "@/store/modules/notices";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    dateFormats: {
      dateFns: "dd-MM-yyyy",
    },
  },
  mutations: {},
  actions: {},
  modules: {
    flashMessages,
    notices,
  },
});
