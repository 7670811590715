<template lang="pug">
  filter-panel(:title="title" :description="description" :enabled="isEnabled" @enable="enableFilter" @disable="disableFilter")
    radio-buttons(:options="radioOptions" :value="selected" @input="selectionChanged")
      template(#equalTo)
        multiselect(v-model="equalTo" ref="equalTo" @input="valueChanged('equalTo', $event)" :multiple="false" :options="internalOptions" placeholder="" select-label="" deselect-label="" label="label")

      template(#anyOf)
        multiselect(v-model="anyOf" ref="anyOf" @input="valueChanged('anyOf', $event)" :multiple="true" :options="internalOptions" placeholder="" select-label="" deselect-label="" label="label" track-by="value")
          template(#tag="{ option, remove }")
            .bg-grey-30.mb-2.mr-2.inline-block.rounded-lg
              span.font-bold.p-2 {{ option.label }}
              button.bg-grey-30.px-2.py-1.rounded-lg.rounded-tl-none.rounded-bl-none(class="hover:bg-grey-40" @click.prevent="remove(option)") X

      template(#isNot)
        multiselect(v-model="isNot" ref="isNot" @input="valueChanged('isNot', $event)" :multiple="false" :options="internalOptions" placeholder="" select-label="" deselect-label="" label="label")

      template(#noneOf)
        multiselect(v-model="noneOf" ref="noneOf" @input="valueChanged('noneOf', $event)" :multiple="true" :options="internalOptions" placeholder="" select-label="" deselect-label="" label="label" track-by="value")
          template(#tag="{ option, remove }")
            .bg-grey-30.mb-2.mr-2.inline-block.rounded-lg
              span.font-bold.p-2 {{ option.label }}
              button.bg-grey-30.px-2.py-1.rounded-lg.rounded-tl-none.rounded-bl-none(class="hover:bg-grey-40" @click.prevent="remove(option)") X
</template>

<script>
import AppTextInput from "@/components/elements/AppTextInput.vue";
import FilterPanel from "@/components/collections/filters/AppFilterSidebarFilterPanel.vue";
import FilterPanelMixin from "@/components/collections/filters/AppFilterSidebarFilterPanelMixin";
import Multiselect from "vue-multiselect";
import RadioButtons from "@/components/collections/filters/AppFilterSidebarFilterRadioButtons.vue";

export default {
  name: "OptionFilter",
  components: {
    AppTextInput,
    FilterPanel,
    Multiselect,
    RadioButtons,
  },
  mixins: [FilterPanelMixin],
  props: {
    options: {
      type: [Array, Function],
      required: true,
    },
  },
  data() {
    return {
      internalOptions: this.generateOptions(),
      radioOptions: [
        { label: "Is equal to", value: "equalTo", slot: "equalTo" },
        { label: "Is any of", value: "anyOf", slot: "anyOf" },
        { label: "Is not", value: "isNot", slot: "isNot" },
        { label: "Is none of", value: "noneOf", slot: "noneOf" },
      ],
      selected: "equalTo",
      equalTo: null,
      anyOf: null,
      isNot: null,
      noneOf: null,
    };
  },
  mounted() {
    if (this.value && this.value[this.field]) {
      let appliedFilter = this.value[this.field];
      if (appliedFilter.not) {
        appliedFilter = appliedFilter.not;
      }

      this.selected = this.value.filterName;

      if (appliedFilter.constructor === Array) {
        this[this.selected] = this.internalOptions.filter(
          opt => appliedFilter.find(f => opt.value === f) !== undefined
        );
      } else {
        this[this.selected] = this.internalOptions.find(
          opt => opt.value === appliedFilter
        );
      }
    }

    // if (this.value?.length) {
    //   const appliedFilter = this.value[0];

    //   let condition = appliedFilter.condition;
    //   condition = condition.replace(`${this.field}_`, "");

    //   const selected = Object.keys(conditions).find(
    //     key => conditions[key] === condition
    //   );

    //   this.selected = selected;

    //   if (appliedFilter.value.constructor === Array) {
    //     this[selected] = this.options.filter(
    //       opt => appliedFilter.value.find(f => opt.value === f) !== undefined
    //     );
    //   } else {
    //     this[selected] = this.options.find(
    //       opt => opt.value === appliedFilter.value
    //     );
    //   }
    // }
  },
  computed: {
    appliedFilters() {
      let value = this[this.selected];

      if (value) {
        if (value.constructor === Array) {
          value = value.map(val => val.value);
        } else {
          value = value.value;
        }
      }

      let condition = value;

      if (this.selected === "isNot" || this.selected === "noneOf") {
        condition = { not: condition };
      }

      return {
        filterName: this.selected,
        [this.field]: condition,
      };
    },
  },
  methods: {
    generateOptions() {
      if (typeof this.options === "function") {
        const options = this.options();
        return [...options];
      } else {
        return this.options;
      }
    },
    valueChanged(field, val) {
      this[field] = val;
      this.$emit("input", this.appliedFilters);
    },
  },
};
</script>
