<script>
import AppDropdown from "@/components/collections/AppDropdown.vue";
import AppIcon from "@/components/elements/AppIcon.vue";
import AppPanel from "@/components/elements/AppPanel.vue";
import AppSidebarContainer from "@/components/containers/AppSidebarContainer.vue";
import AppTextInput from "@/components/elements/AppTextInput.vue";
import AppLabel from "@/components/elements/AppLabel.vue";
import ColumnToggleSidebar from "./base_table/BaseTableColumnToggleSidebar.vue";
import { AgGridVue } from "ag-grid-vue";
import {
  setupGridOptions,
  setupGridProps,
} from "@/components/options/base_table/agGridOptions";
import Hooks from "./base_table/HooksMixin";

// Cell renderers
import BaseTableActionsCellRenderer from "./base_table/cell_renderers/BaseTableActionsCellRenderer.vue";
import BaseTableActionsHeaderRenderer from "./base_table/cell_renderers/BaseTableActionsHeaderRenderer.vue";
import BaseTableDateCellRenderer from "./base_table/cell_renderers/BaseTableDateCellRenderer.vue";
import BaseTableDefaultHeaderRenderer from "./base_table/cell_renderers/BaseTableDefaultHeaderRenderer.vue";
import BaseTableIconButtonCellRendererVue from "./base_table/cell_renderers/BaseTableIconButtonCellRenderer.vue";
import BaseTableLabelsCellRenderer from "./base_table/cell_renderers/BaseTableLabelsCellRenderer.vue";
import BaseTableLinkCellRenderer from "./base_table/cell_renderers/BaseTableLinkCellRenderer.vue";
import BaseTableStatusCellRenderer from "./base_table/cell_renderers/BaseTableStatusCellRenderer.vue";
import BaseTableTeamMemberCellRenderer from "./base_table/cell_renderers/BaseTableTeamMemberCellRenderer.vue";
import BaseTableStandardCellEditor from "./base_table/cell_editors/BaseTableStandardCellEditor.vue";
import BaseTableDropdownCellEditor from "./base_table/cell_editors/BaseTableDropdownCellEditor.vue";
import BaseTableDateCellEditor from "./base_table/cell_editors/BaseTableDateCellEditor.vue";
import BaseTablePriorityCellRenderer from "./base_table/cell_renderers/BaseTablePriorityCellRenderer.vue";
import FilterSidebar from "./AppFilterSidebar.vue";

export default {
  mixins: [Hooks],
  data() {
    const vm = this;

    return {
      /**
       * The structure of the table. Specifies the columns to be displayed
       * with any options for each column as defined by ag-grid
       */
      columns: null,
      defaultColumn: {
        sortable: true,
        cellEditor: "standardCellEditor",
        resizable: true,
      },
      columnToggleOpen: false,
      filterSidebarOpen: false,

      components: {
        vue: {
          agColumnHeader: BaseTableDefaultHeaderRenderer,
          actionsMenu: BaseTableActionsCellRenderer,
          actionsHeader: BaseTableActionsHeaderRenderer,
          iconButton: BaseTableIconButtonCellRendererVue,
          date: BaseTableDateCellRenderer,
          labels: BaseTableLabelsCellRenderer,
          link: BaseTableLinkCellRenderer,
          teamMember: BaseTableTeamMemberCellRenderer,
          standardCellEditor: BaseTableStandardCellEditor,
          dropdownCellEditor: BaseTableDropdownCellEditor,
          dateCellEditor: BaseTableDateCellEditor,
          status: BaseTableStatusCellRenderer,
          priority: BaseTablePriorityCellRenderer,
        },
        agGrid: {},
      },

      /**
       * The location that the user right clicked to open the context
       * menu from.
       */
      contextMenuOrigin: null,
      contextMenuDataItem: null,

      /**
       * Event listeners that will be attached to the underlying grid
       */
      listeners: {
        cellContextMenu(evt) {
          vm.showContextMenu(evt);
        },
        gridReady: vm.gridReady,
        selectionChanged({ api }) {
          const selected = api.getSelectedRows().length;
          vm.rowsSelected = selected;
          vm.rowSelectionChanged();
        },
        rowDataChanged() {
          vm.rowsSelected = 0;
        },
      },

      /**
       * An object containing options for the underlying ag-grid instance.
       * All available options can be found in the ag-grid documentation:
       *
       * https://www.ag-grid.com/javascript-grid-properties/
       *
       * These will be merged into the internally generated options.
       */
      options: {},

      /**
       * Specifies whether the table should allow one or more rows to be
       * selected. Valid options are:
       *
       * - none
       * - single
       * - multiple
       */
      selection: "none",

      /**
       * Whether or not there is currently a row selected.
       */
      rowsSelected: 0,

      /**
       * agGrid specific data. Don't touch
       */
      context: {},

      /**
       * Configuration for the base table itself.
       */
      config: {
        actionBar: true,

        /**
         * Toggles whether the table is being rendered inside something
         * else. If set to true, it will not render itself inside an app
         * panel
         */
        embedded: false,

        /**
         * Used when displaying text about the data in the rows. For
         * example stating how many rows have been selected.
         */
        resourceType: "resource",

        /**
         * Specifies whether a search bar should be made available. Defaults
         * to `true`.
         */
        searchable: true,

        datasource: {
          rowModelType: "clientSide",
        },

        filters: {},
      },

      searchTerm: "",
      rowData: [],
      datasource: null,
      appliedFilters: {},
    };
  },
  computed: {
    numberOfFiltersApplied() {
      return Object.values(this.appliedFilters).reduce(
        (total, filters) => (total += Object.keys(filters).length ? 1 : 0),
        0
      );
    },
    binds() {
      return Object.assign(
        {},
        this.$attrs,
        setupGridProps(this),
        this.tableBinds
      );
    },
    columnDefs() {
      const available = [...this.columns];
      if (!this.columnPreferences) {
        return available;
      }
      return available.map(col => {
        const preference = this.columnPreferences[col.field];
        let column = { ...col };

        if (preference) {
          // Update the column def with the preferences
          column.width = preference.width || col.width;
          column.hide = preference.hide ?? column.hide;
        }

        // No preferences for this column, so leave it as it is
        return column;
      });
    },
    tableBinds() {
      return {
        loadingOverlayComponent: "loadingOverlay",
        noRowsOverlayComponent: "noResultsOverlay",
      };
    },
    gridListeners() {
      return Object.assign({}, this.$listeners, this.listeners);
    },
    showBulkActions() {
      return this.rowsSelected > 1;
    },
    selectedRowsLabel() {
      let { resourceType } = this.config;
      if (this.rowsSelected > 1) {
        resourceType += "s";
      }

      return `${this.rowsSelected} ${resourceType} selected`;
    },
  },
  beforeMount() {
    this.gridOptions = setupGridOptions(this);
    this.context.tableComponent = this;
  },
  mounted() {
    this.addColumnOptions();
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
  methods: {
    /**
     *  Adds additional options to the columns array, to ensure
     *  that they are reactive
     */
    addColumnOptions() {
      this.columns.forEach(col => {
        this.$set(col, "hide", col.hide ?? false);
      });
    },

    /**
     * Get the context menu items for the given node (row). This
     * will be called when a row is right-clicked, or the actions
     * menu is opened.
     */
    getContextMenuItems() {
      return [];
    },

    generateFilterHash(filters) {
      let result = {};
      let negated = {};

      Object.keys(filters).forEach(key => {
        const filter = filters[key];
        const definition = this.config.filters[key];

        if (filter[key]) {
          // Normal filter, just take that value
          result[key] = filter[key];
        } else if (filter._not) {
          // Negated filter
          negated = Object.assign(negated, filter._not);
        } else if (filter.field && filter[filter.field]) {
          result[filter.field] = filter[filter.field];
        } else if (filter[definition.field]) {
          // Used a different name
          const { field } = definition;
          result[field] = filter[field];
        }
      });

      if (Object.keys(negated).length) {
        result._not = negated;
      }
      return result;
    },

    /**
     * Get the bulk actions menu items. Only applicable if
     * the table is selectable
     */
    getBulkActions() {
      return [];
    },

    /**
     *  Presents the column toggle sidebar
     */
    openColumnToggle() {
      this.columnToggleOpen = true;
    },

    /**
     * Filters have been applied, so the table subclass needs
     * to apply those filters
     */
    performFiltering(filters) {}, // eslint-disable-line no-unused-vars

    /**
     * Search the data for the specified search term
     */
    performSearch(searchTerm) {
      this.gridApi.setQuickFilter(searchTerm);
    },

    /**
     * Called when the `filter` button is clicked
     */
    presentFilters() {
      const vm = this;
      if (!this.filterSidebarOpen) {
        this.filterSidebarOpen = true;
        this.$sidebar.open(FilterSidebar, {
          props: {
            filters: this.config.filters,
            value: this.appliedFilters,
            title: `Filter ${this.config.resourceType}s`,
          },
          on: {
            close() {
              vm.filterSidebarOpen = false;
            },
            input(filters) {
              // vm.appliedFilters = filters;
              Object.keys(filters).forEach(key => {
                vm.$set(vm.appliedFilters, key, filters[key]);
              });

              vm.performFiltering(vm.generateFilterHash(filters));
            },
          },
        });
      }
    },

    /**
     * Forces the actions cell to be re-rendered for
     * the given row. This is required sometimes if
     * the actions are generated conditionally, as
     * ag-grid will not detect any changes and require
     * it to be refreshed manually
     **/
    refreshActions(rows) {
      this.gridApi.refreshCells({
        rowNodes: [rows].flat(),
        columns: ["actions"],
        force: true,
      });
    },

    /**
     * RENDER FUNCTIONS
     */

    getActionBar(h) {
      let child;

      if (this.showBulkActions) {
        child = [
          h(AppDropdown, {
            props: { options: this.getBulkActions() },
            on: { click: this.performBulkAction },
          }),
          h("span", { staticClass: "ml-4" }, this.selectedRowsLabel),
        ];
      } else {
        child = this.getActionBarContent(h);
      }

      return h("div", { staticClass: "w-full h-24 p-8 flex-none" }, child);
    },
    getActionBarContent() {
      return this.$slots["action-bar"];
    },
    getBody(h) {
      let children = [];

      if (this.config.actionBar) {
        children.push(this.getActionBar(h));
      }

      children.push(this.getTable(h));
      children.push(this.getColumnToggleSidebar(h));
      children.push(...this.getOtherComponents(h));

      if (this.config.embedded) {
        return children;
      } else {
        return h(
          AppPanel,
          { props: { compact: true }, staticClass: "flex-auto flex flex-col" },
          children
        );
      }
    },
    getButtons() {
      return this.$slots.buttons;
    },
    getColumnToggleSidebar(h) {
      const vm = this;

      const sidebar = h(ColumnToggleSidebar, {
        props: { columns: vm.columns },
        on: {
          toggle: vm.toggleColumn,
          input() {
            vm.columnToggleOpen = false;
          },
        },
      });

      return h(AppSidebarContainer, { props: { value: vm.columnToggleOpen } }, [
        sidebar,
      ]);
    },
    getContextMenu(h, node) {
      const items = this.getContextMenuItems(node);
      const vm = this;

      if (items.length) {
        return h(AppDropdown, {
          props: {
            options: items,
            origin: this.contextMenuOrigin,
            trigger: "manual",
          },
          on: {
            click(option) {
              vm.contextMenuItemClicked(option, vm.contextMenuDataItem);
            },
            close() {
              vm.contextMenuOrigin = null;
              vm.contextMenuDataItem = null;
            },
          },
        });
      }

      return null;
    },
    getHeader(h) {
      let children = [];

      if (this.config.searchable) {
        children.push(this.getSearchInput(h));
      }

      const buttons = h(
        "div",
        { staticClass: "flex-1 text-right" },
        this.getButtons(h)
      );
      children.push(buttons);

      const filters = this.getFilterBar(h);

      const searchAndButtons = h(
        "div",
        {
          staticClass: `flex-initial flex flex-row ${
            filters ? "mb-2" : "mb-4"
          }`,
        },
        children
      );

      return [searchAndButtons, filters];
    },
    getFooter() {
      return [];
    },
    getOtherComponents() {
      return [];
    },
    getFilterBar(h) {
      if (this.numberOfFiltersApplied > 0) {
        const vm = this;
        const label = h(
          "span",
          { staticClass: "flex-initial text-grey-70" },
          "Filters:"
        );
        const clear = h(
          "a",
          {
            staticClass: "flex-initial",
            on: {
              click() {
                vm.appliedFilters = {};
                vm.performFiltering();
              },
            },
          },
          "Clear all filters"
        );

        const filterLabels = Object.keys(this.appliedFilters)
          .filter(key => Object.keys(this.appliedFilters[key]).length > 0)
          .map(key =>
            h(
              AppLabel,
              {
                staticClass: "mr-2",
                props: { removable: true, colour: "blue" },
                on: {
                  "remove-click": () => {
                    this.$delete(this.appliedFilters, key);
                    this.performFiltering();
                  },
                },
              },
              this.config.filters[key].title
            )
          );

        const labelsWrapper = h(
          "div",
          { staticClass: "flex-1 px-4" },
          filterLabels
        );

        return h(
          "div",
          {
            staticClass:
              "flex flex-row bg-white rounded-lg border border-grey-30 px-4 h-12 mb-4 items-center",
          },
          [label, labelsWrapper, clear]
        );
      }
      return null;
    },
    getSearchInput(h) {
      let children = [];
      let inputOpts = {
        props: {
          icon: "search",
          value: this.searchTerm,
          clearable: true,
          standalone: true,
          button: false,
        },
        on: {
          input: term => {
            this.searchTerm = term;
            this.performSearch(term);
          },
        },
      };

      if (Object.keys(this.config.filters).length) {
        inputOpts.props.button = true;

        let filterIcon;
        if (this.numberOfFiltersApplied > 0) {
          filterIcon = h(
            "div",
            {
              staticClass:
                "bg-tribal-aqua rounded-full text-white font-bold h-6 px-2",
            },
            [this.numberOfFiltersApplied]
          );
        } else {
          filterIcon = h(AppIcon, { props: { icon: "filter" } });
        }

        const filterText = h(
          "span",
          { staticClass: "text-grey-60 ml-2" },
          "Filters"
        );

        inputOpts.scopedSlots = {
          button: () => {
            return [filterIcon, filterText];
          },
        };

        inputOpts.on["button-click"] = this.presentFilters;
      }

      const input = h(AppTextInput, inputOpts);

      children.push(input);

      return h("div", { staticClass: "flex-1 relative" }, children);
    },
    getTable(h) {
      const vm = this;
      return h(AgGridVue, {
        staticClass: "ag-theme-material w-full h-full",
        on: this.gridListeners,
        ref: "grid",
        nativeOn: {
          contextmenu(e) {
            e.preventDefault();
          },
          drop(e) {
            e.preventDefault();
            let el = e.target;
            if (el.classList) {
              // There are no classes, so it's not been dropped on a row
              vm.fileDropped(null, e);
            } else {
              while (!el.classList.contains("ag-row")) {
                el = el.parentNode;
                if (!el) {
                  return null;
                }
              }

              const rowIdx = el.getAttribute("row-id");
              const row = vm.gridApi.getRowNode(rowIdx);
              vm.fileDropped(row, e);
            }
          },
          dragenter(e) {
            e.preventDefault();
          },
          dragover(e) {
            e.preventDefault();
          },
        },
        props: {
          ...this.binds,
          context: this.context,
          "grid-options": this.gridOptions,
          // domLayout: "autoHeight",
        },
      });

      // const container = h(
      //   "div",
      //   {
      //     staticClass: "absolute top-0 left-0 right-0 bottom-0",
      //   },
      //   [table]
      // );

      // return h(
      //   "div",
      //   {
      //     staticClass: "flex-1 relative",
      //   },
      //   [container]
      // );
    },
    setRowData(data) {
      this.rowData = Object.freeze(
        data.map(row => ({
          ...row,
        }))
      );
    },
    showContextMenu(evt) {
      const { clientX, clientY } = evt.event;
      this.contextMenuDataItem = evt.node;
      this.contextMenuOrigin = {
        getBoundingClientRect() {
          return {
            width: 0,
            height: 0,
            top: clientY,
            right: clientX,
            bottom: clientY,
            left: clientX,
          };
        },
      };
    },
    startLoading() {
      this.gridOptions.suppressNoRowsOverlay = true;
      this.gridApi.showLoadingOverlay();
    },
    stopLoading() {
      this.gridOptions.suppressNoRowsOverlay = false;

      if (!this.rowData.length) {
        this.gridApi.showNoRowsOverlay();
      } else {
        this.gridApi.hideOverlay();
      }
    },
    toggleColumn(colField) {
      const idx = this.columns.findIndex(c => c.field === colField);
      if (idx !== -1) {
        const column = this.columns[idx];
        const hide = !(column.hide ?? false);
        this.$set(column, "hide", hide);
        this.gridColumnApi.setColumnVisible(colField, !hide);

        this.columnVisibilityChanged(column);
      }
    },
  },
  render(h) {
    let children = [this.getHeader(h), this.getBody(h), this.getFooter(h)];

    if (this.contextMenuDataItem) {
      children.push(this.getContextMenu(h, this.contextMenuDataItem));
    }

    return h("div", { class: "w-full h-full flex flex-col" }, children);
  },
};
</script>

<style lang="postcss" scoped>
>>> .ag-row .ag-cell {
  @apply flex;
  @apply items-center;
}
</style>
