// function getDataPathFunction({ hierarchy }) {
//   if (hierarchy === null) {
//     return null;
//   }

//   let { path } = hierarchy;
//   if (!path) {
//     path = "path";
//   }

//   return row => row[path];
// }

function behavioralOptions() {
  return {
    suppressScrollOnNewData: true,
    animateRows: true,
    // getDataPath: getDataPathFunction(props),
    getRowNodeId: data => data.id,
  };
}

export default behavioralOptions;
