<template lang="pug">
  app-sidebar(:actions="actions" @action-clicked="actionClicked" :tabs="tabs" v-on="$listeners")
    template(#title) I am a really long title that will hopefully wrap itself onto a new line

    template(#tab1)
      .p-8
        app-panel(secondary)
          app-header Panels
          p Sidebars can contain anything you like. Panels are used to break up the content into logicial sections

        app-panel.mt-4(secondary)
          app-header Scrolling
          p Having a lot of content in the sidebar will make it scroll automatically. Look
          p.mt-4 Bacon ipsum dolor amet biltong brisket corned beef jowl tail. Ham cow chislic, pig salami ball tip capicola sausage strip steak. Bacon chislic shoulder, burgdoggen tongue pork chuck pig ball tip ham. Hamburger pork belly ham hock, swine brisket strip steak sausage bacon beef ribs tri-tip ball tip flank. Fatback ribeye bresaola, salami short loin filet mignon boudin. Landjaeger hamburger turkey kielbasa short loin bresaola shank spare ribs chuck jowl prosciutto picanha. Beef ribs bresaola ball tip doner, drumstick pork chop short loin. 
          p Bacon ipsum dolor amet biltong brisket corned beef jowl tail. Ham cow chislic, pig salami ball tip capicola sausage strip steak. Bacon chislic shoulder, burgdoggen tongue pork chuck pig ball tip ham. Hamburger pork belly ham hock, swine brisket strip steak sausage bacon beef ribs tri-tip ball tip flank. Fatback ribeye bresaola, salami short loin filet mignon boudin. Landjaeger hamburger turkey kielbasa short loin bresaola shank spare ribs chuck jowl prosciutto picanha. Beef ribs bresaola ball tip doner, drumstick pork chop short loin. 

    template(#tab2)
      .h-full.flex.flex-col.relative
        .flex-1.overflow-auto.mb-3.flex.flex-col.h-0
          .flex-initial.w-64.h-12.bg-tribal-aqua(v-for="i in [1,2,3,4,5,6,7,8,9,10, 11,12,13,14,15,16]") Hello world
         
        .flex-initial.input.flex.flex-col.border.border-grey-60.rounded.overflow-hidden
          textarea.p-2.flex-1.w-full.h-16.resize-none(ref="commentInput" type="text", name="test")
          app-dropdown(trigger='manual')
            template(#content)
              .w-64.overflow-y-auto(ref="usersDropdown" style="maxHeight: 250px;")
 
          .flex-initial.flex.items-center.justify-end.p-2.bg-grey-10.border-t.border-grey-30
            app-icon.flex-initial.mr-3.cursor-pointer(ref="dropdownTriggerIcon" icon="email")
            app-button.flex-initial(icon="send", primary, slim) Send

    template(#tab3) Tab 3

    template(#footer) Hello
</template>

<script>
import AppButton from "@/components/elements/AppButton.vue";
import AppDropdown from "@/components/collections/AppDropdown.vue";
import AppHeader from "@/components/elements/AppHeader.vue";
import AppIcon from "@/components/elements/AppIcon.vue";
import AppPanel from "@/components/elements/AppPanel.vue";
import AppSidebar from "@/components/elements/AppSidebar.vue";

export default {
  components: {
    AppButton,
    AppDropdown,
    AppHeader,
    AppIcon,
    AppPanel,
    AppSidebar,
  },
  data() {
    return {
      actions: [
        { label: "Action number 1", icon: "edit" },
        { label: "Action number 2", icon: "error" },
      ],
      tabs: [
        { name: "tab1", slot: "tab1" },
        { name: "tab2", slot: "tab2" },
        { name: "tab3", slot: "tab3" },
      ],
    };
  },
  methods: {
    actionClicked(item) {
      console.log("CLICKED", item);
    },
  },
};
</script>
