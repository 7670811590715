<template lang="pug">
  mounting-portal(mount-to="body", append)
    app-dialog-container(:value="value", v-on="$listeners")
      .dialog.bg-white.shadow.shadow-lg.p-8
        .flex.flex-row.border-b.border-grey-30.pb-8
          .flex-1
            slot(name="header")
              app-header(margin="")
                slot(name="title")
          app-icon.flex-none.cursor-pointer(v-if="dismissable", icon="close", @click.native="$emit('input', false)", colour="lightGrey")

        .pt-8
          slot

        .pt-8.mt-8.border-t.border-grey-30(v-if="$slots.footer")
          slot(name="footer")

</template>

<script>
import AppDialogContainer from "./AppDialogContainer.vue";
import AppHeader from "@/components/elements/AppHeader.vue";
import AppIcon from "@/components/elements/AppIcon.vue";

export default {
  components: {
    AppDialogContainer,
    AppHeader,
    AppIcon,
  },
  props: {
    dismissable: {
      type: Boolean,
      required: false,
      default: true,
    },
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: Number,
      required: false,
      default: 500,
    },
  },
};
</script>

<style lang="postcss" scoped>
.dialog {
  min-width: 500px;
}
</style>
