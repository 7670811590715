<template lang="pug">
  .w-full.h-full
    router-view
    flash-messages

    notices-container
</template>

<script>
import FlashMessages from "@/components/containers/FlashMessageContainer.vue";
import NoticesContainer from "@/components/containers/NoticesContainer.vue";

export default {
  components: {
    FlashMessages,
    NoticesContainer,
  },
};
</script>
