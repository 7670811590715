<template lang="pug">
  filter-panel(:title="title" :description="description" :enabled="isEnabled" @enable="enableFilter" @disable="disableFilter")
    radio-buttons(:options="options" :value="selected" @input="selectionChanged")
</template>

<script>
import FilterPanel from "@/components/collections/filters/AppFilterSidebarFilterPanel.vue";
import FilterPanelMixin from "@/components/collections/filters/AppFilterSidebarFilterPanelMixin";
import RadioButtons from "@/components/collections/filters/AppFilterSidebarFilterRadioButtons.vue";

export default {
  name: "BooleanFilter",
  components: {
    FilterPanel,
    RadioButtons,
  },
  mixins: [FilterPanelMixin],
  props: {
    trueLabel: { type: String, required: false, default: "Enabled" },
    falseLabel: { type: String, required: false, default: "Disabled" },
  },
  data() {
    return {
      options: [
        { label: this.trueLabel, value: true },
        { label: this.falseLabel, value: false },
      ],

      selected: true,
    };
  },
  mounted() {
    if (this.value && this.field in this.value) {
      this.selected = this.value[this.field];
    }
  },
  computed: {
    appliedFilters() {
      return {
        [this.field]: this.selected,
      };
    },
  },
};
</script>
