<script>
import { mergeData } from "vue-functional-data-merge";

import accounts from "@streamlinehq/streamlinehq/images/streamline-light/performance-graph-calculator.svg";
import actions from "@streamlinehq/streamlinehq/images/streamline-light/navigation-menu-vertical.svg";
import activity from "@streamlinehq/streamlinehq/images/streamline-light/graph-stats-circle.svg";
import add from "@streamlinehq/streamlinehq/images/streamline-light/add-circle.svg";
import addDocument from "@streamlinehq/streamlinehq/images/streamline-light/common-file-text-upload.svg";
import addFolder from "@streamlinehq/streamlinehq/images/streamline-light/folder-add.svg";
import address from "@streamlinehq/streamlinehq/images/streamline-light/envelope-letter.svg";
import addTask from "@streamlinehq/streamlinehq/images/streamline-light/task-checklist-add.svg";
import addUserLicense from "@streamlinehq/streamlinehq/images/streamline-light/single-neutral-actions-key.svg";
import backArrow from "@streamlinehq/streamlinehq/images/streamline-light/keyboard-arrow-left.svg";
import baselines from "@streamlinehq/streamlinehq/images/streamline-light/insert-image-bottom.svg";
import bill from "@streamlinehq/streamlinehq/images/streamline-light/cash-payment-bills.svg";
import bin from "@streamlinehq/streamlinehq/images/streamline-light/bin-2.svg";
import books from "@streamlinehq/streamlinehq/images/streamline-light/book-library-2.svg";
import calendar from "@streamlinehq/streamlinehq/images/streamline-light/calendar-1.svg";
import caretDown from "@streamlinehq/streamlinehq/images/streamline-regular/arrow-down-1.svg";
import caretLeft from "@streamlinehq/streamlinehq/images/streamline-regular/arrow-left-1.svg";
import caretRight from "@streamlinehq/streamlinehq/images/streamline-regular/arrow-right-1.svg";
import caretUp from "@streamlinehq/streamlinehq/images/streamline-regular/arrow-up-1.svg";
import changeOrder from "@streamlinehq/streamlinehq/images/streamline-light/accounting-invoice-hand.svg";
import checklist from "@streamlinehq/streamlinehq/images/streamline-light/checklist-pen.svg";
import circleSolid from "@streamlinehq/streamlinehq/images/streamline-bold/sign-badge-circle.svg";
import close from "@streamlinehq/streamlinehq/images/streamline-light/close.svg";
import collapseAll from "@streamlinehq/streamlinehq/images/streamline-light/folder-empty-1.svg";
import columns from "@streamlinehq/streamlinehq/images/streamline-light/layout-array.svg";
import company from "@streamlinehq/streamlinehq/images/streamline-light/office-outdoors.svg";
import contact from "@streamlinehq/streamlinehq/images/streamline-light/single-neutral-circle.svg";
import contacts from "@streamlinehq/streamlinehq/images/streamline-light/single-neutral-id-card-double.svg";
import convert from "@streamlinehq/streamlinehq/images/streamline-light/synchronize-arrows-square-1.svg";
import copy from "@streamlinehq/streamlinehq/images/streamline-light/copy-paste-1.svg";
import creditCard from "@streamlinehq/streamlinehq/images/streamline-light/credit-card-1.svg";
import dashboard from "@streamlinehq/streamlinehq/images/streamline-light/gauge-dashboard-1.svg";
import document from "@streamlinehq/streamlinehq/images/streamline-light/folder-file.svg";
import download from "@streamlinehq/streamlinehq/images/streamline-light/cloud-download.svg";
import dragHandle from "@streamlinehq/streamlinehq/images/streamline-light/navigation-menu.svg";
import edit from "@streamlinehq/streamlinehq/images/streamline-light/pencil-2.svg";
import email from "@streamlinehq/streamlinehq/images/streamline-light/read-email-at.svg";
import envelope from "@streamlinehq/streamlinehq/images/streamline-light/email-action-unread.svg";
import error from "@streamlinehq/streamlinehq/images/streamline-light/remove-circle.svg";
import estimate from "@streamlinehq/streamlinehq/images/streamline-light/accounting-calculator.svg";
import expandAll from "@streamlinehq/streamlinehq/images/streamline-light/folder-open.svg";
import external from "@streamlinehq/streamlinehq/images/streamline-light/share-2.svg";
import facebookSolid from "@streamlinehq/streamlinehq/images/streamline-bold/social-media-facebook-1.svg";
import filter from "@streamlinehq/streamlinehq/images/streamline-light/filter-1.svg";
import finance from "@streamlinehq/streamlinehq/images/streamline-light/accounting-coins-bill.svg";
import financeVoid from "@streamlinehq/streamlinehq/images/streamline-light/delete.svg";
import form from "@streamlinehq/streamlinehq/images/streamline-light/paragraph-bullets.svg";
import fullscreen from "@streamlinehq/streamlinehq/images/streamline-light/expand-8.svg";
import globe from "@streamlinehq/streamlinehq/images/streamline-light/earth-2.svg";
import gantt from "@streamlinehq/streamlinehq/images/streamline-light/analytics-bars-horizontal.svg";
import info from "@streamlinehq/streamlinehq/images/streamline-light/information-circle.svg";
import invoice from "@streamlinehq/streamlinehq/images/streamline-light/accounting-document.svg";
import kpi from "@streamlinehq/streamlinehq/images/streamline-light/analytics-graph.svg";
import link from "@streamlinehq/streamlinehq/images/streamline-light/hyperlink-3.svg";
import linkedInSolid from "@streamlinehq/streamlinehq/images/streamline-bold/professional-network-linkedin.svg";
import login from "@streamlinehq/streamlinehq/images/streamline-light/login.svg";
import logout from "@streamlinehq/streamlinehq/images/streamline-light/logout.svg";
import lowPriority from "@streamlinehq/streamlinehq/images/streamline-light/navigation-down-circle.svg";
import milestone from "@streamlinehq/streamlinehq/images/streamline-light/flag.svg";
import mobilePhone from "@streamlinehq/streamlinehq/images/streamline-light/mobile-phone.svg";
import move from "@streamlinehq/streamlinehq/images/streamline-light/diagram-all-direction-expand.svg";
import moveDocument from "@streamlinehq/streamlinehq/images/streamline-light/common-file-rotate.svg";
import moveFolder from "@streamlinehq/streamlinehq/images/streamline-light/folder-exchange.svg";
import normalPriority from "@streamlinehq/streamlinehq/images/streamline-light/navigation-menu-horizontal-1.svg";
import notificationSolid from "@streamlinehq/streamlinehq/images/streamline-bold/alarm-bell.svg";
import notification from "@streamlinehq/streamlinehq/images/streamline-light/alarm-bell.svg";
import padlock from "@streamlinehq/streamlinehq/images/streamline-light/lock-1.svg";
import paid from "@streamlinehq/streamlinehq/images/streamline-light/credit-card-check.svg";
import phone from "@streamlinehq/streamlinehq/images/streamline-light/phone.svg";
import plan from "@streamlinehq/streamlinehq/images/streamline-light/tag-dollar-1.svg";
import preview from "@streamlinehq/streamlinehq/images/streamline-light/view-1.svg";
import print from "@streamlinehq/streamlinehq/images/streamline-light/print-text.svg";
import project from "@streamlinehq/streamlinehq/images/streamline-light/project-blueprint-home.svg";
import purchaseOrder from "@streamlinehq/streamlinehq/images/streamline-light/accounting-invoice-mail.svg";
import question from "@streamlinehq/streamlinehq/images/streamline-light/question-circle.svg";
import rateCard from "@streamlinehq/streamlinehq/images/streamline-light/credit-card-dollar-1.svg";
import refresh from "@streamlinehq/streamlinehq/images/streamline-light/button-refresh-arrow.svg";
import removeUserLicense from "@streamlinehq/streamlinehq/images/streamline-light/single-neutral-actions-block.svg";
import report from "@streamlinehq/streamlinehq/images/streamline-light/data-file-information.svg";
import roles from "@streamlinehq/streamlinehq/images/streamline-light/single-neutral-focus.svg";
import search from "@streamlinehq/streamlinehq/images/streamline-light/search-1.svg";
import send from "@streamlinehq/streamlinehq/images/streamline-light/send-email.svg";
import share from "@streamlinehq/streamlinehq/images/streamline-light/share-1.svg";
import snapshot from "@streamlinehq/streamlinehq/images/streamline-light/taking-pictures-circle.svg";
import subscription from "@streamlinehq/streamlinehq/images/streamline-light/shopping-cart-cash.svg";
import subtract from "@streamlinehq/streamlinehq/images/streamline-light/subtract-circle.svg";
import success from "@streamlinehq/streamlinehq/images/streamline-light/check-circle-1.svg";
import support from "@streamlinehq/streamlinehq/images/streamline-light/headphones-customer-support.svg";
import table from "@streamlinehq/streamlinehq/images/streamline-light/layout-2.svg";
import task from "@streamlinehq/streamlinehq/images/streamline-light/task-list-to-do.svg";
import taxRate from "@streamlinehq/streamlinehq/images/streamline-light/discount-circle.svg";
import template from "@streamlinehq/streamlinehq/images/streamline-light/tool-box.svg";
import thumbsDown from "@streamlinehq/streamlinehq/images/streamline-light/dislike-1.svg";
import thumbsUp from "@streamlinehq/streamlinehq/images/streamline-light/like-1.svg";
import tick from "@streamlinehq/streamlinehq/images/streamline-light/check-1.svg";
import timesheetEntry from "@streamlinehq/streamlinehq/images/streamline-light/stopwatch.svg";
import twitterSolid from "@streamlinehq/streamlinehq/images/streamline-bold/social-media-twitter.svg";
import unpaid from "@streamlinehq/streamlinehq/images/streamline-light/credit-card-disable.svg";
import upload from "@streamlinehq/streamlinehq/images/streamline-light/cloud-upload.svg";
import user from "@streamlinehq/streamlinehq/images/streamline-light/single-neutral.svg";
import users from "@streamlinehq/streamlinehq/images/streamline-light/multiple-neutral-2.svg";
import warning from "@streamlinehq/streamlinehq/images/streamline-light/alert-circle.svg";
import youtube from "@streamlinehq/streamlinehq/images/streamline-light/social-video-youtube-clip.svg";
import youtubeSolid from "@streamlinehq/streamlinehq/images/streamline-bold/social-video-youtube-clip.svg";
import zoomIn from "@streamlinehq/streamlinehq/images/streamline-light/zoom-in-1.svg";
import zoomOut from "@streamlinehq/streamlinehq/images/streamline-light/zoom-out-1.svg";

const ICONS = {
  accounts: { icon: accounts, variant: "light" },
  actions: { icon: actions, variant: "light" },
  activity: { icon: activity, variant: "light" },
  add: { icon: add, variant: "light" },
  addDocument: { icon: addDocument, variant: "light" },
  addFolder: { icon: addFolder, variant: "light" },
  address: { icon: address, variant: "light" },
  addTask: { icon: addTask, variant: "light" },
  addUserLicense: { icon: addUserLicense, variant: "light" },
  backArrow: { icon: backArrow, variant: "light" },
  baselines: { icon: baselines, variant: "light" },
  bill: { icon: bill, variant: "light" },
  books: { icon: books, variant: "light" },
  calendar: { icon: calendar, variant: "light" },
  caretDown: { icon: caretDown, variant: "light" },
  caretLeft: { icon: caretLeft, variant: "light" },
  caretRight: { icon: caretRight, variant: "light" },
  caretUp: { icon: caretUp, variant: "light" },
  changeOrder: { icon: changeOrder, variant: "light" },
  checklist: { icon: checklist, variant: "light" },
  circleSolid: { icon: circleSolid, variant: "solid" },
  close: { icon: close, variant: "light" },
  collapseAll: { icon: collapseAll, variant: "light" },
  columns: { icon: columns, variant: "light" },
  company: { icon: company, variant: "light" },
  contact: { icon: contact, variant: "light" },
  contacts: { icon: contacts, variant: "light" },
  convert: { icon: convert, variant: "light" },
  copy: { icon: copy, variant: "light" },
  creditCard: { icon: creditCard, variant: "light" },
  dashboard: { icon: dashboard, variant: "light" },
  delete: { icon: bin, variant: "light" },
  document: { icon: document, variant: "light" },
  download: { icon: download, variant: "light" },
  dragHandle: { icon: dragHandle, variant: "light" },
  edit: { icon: edit, variant: "light" },
  email: { icon: email, variant: "light" },
  envelope: { icon: envelope, variant: "light" },
  error: { icon: error, variant: "light" },
  estimate: { icon: estimate, variant: "light" },
  expandAll: { icon: expandAll, variant: "light" },
  export: { icon: logout, variant: "light" },
  external: { icon: external, variant: "light" },
  facebookSolid: { icon: facebookSolid, variant: "solid" },
  filter: { icon: filter, variant: "light" },
  finance: { icon: finance, variant: "light" },
  form: { icon: form, variant: "light" },
  fullscreen: { icon: fullscreen, variant: "light" },
  gantt: { icon: gantt, variant: "light" },
  globe: { icon: globe, variant: "light" },
  info: { icon: info, variant: "light" },
  invoice: { icon: invoice, variant: "light" },
  import: { icon: login, variant: "light" },
  kpi: { icon: kpi, variant: "light" },
  link: { icon: link, variant: "light" },
  linkedInSolid: { icon: linkedInSolid, variant: "solid" },
  lowPriority: { icon: lowPriority, variant: "light" },
  milestone: { icon: milestone, variant: "light" },
  mobilePhone: { icon: mobilePhone, variant: "light" },
  move: { icon: move, variant: "light" },
  moveDocument: { icon: moveDocument, variant: "light" },
  moveFolder: { icon: moveFolder, variant: "light" },
  normalPriority: { icon: normalPriority, variant: "light" },
  notification: { icon: notification, variant: "light" },
  notificationSolid: { icon: notificationSolid, variant: "solid" },
  padlock: { icon: padlock, variant: "light" },
  paid: { icon: paid, variant: "light" },
  phone: { icon: phone, variant: "light" },
  plan: { icon: plan, variant: "light" },
  preview: { icon: preview, variant: "light" },
  print: { icon: print, variant: "light" },
  project: { icon: project, variant: "light" },
  purchaseOrder: { icon: purchaseOrder, variant: "light" },
  question: { icon: question, variant: "light" },
  rateCard: { icon: rateCard, variant: "light" },
  refresh: { icon: refresh, variant: "light" },
  removeUserLicense: { icon: removeUserLicense, variant: "light" },
  report: { icon: report, variant: "light" },
  roles: { icon: roles, variant: "light" },
  search: { icon: search, variant: "light" },
  send: { icon: send, variant: "light" },
  share: { icon: share, variant: "light" },
  snapshot: { icon: snapshot, variant: "light" },
  subscription: { icon: subscription, variant: "light" },
  subtract: { icon: subtract, variant: "light" },
  success: { icon: success, variant: "light" },
  support: { icon: support, variant: "light" },
  table: { icon: table, variant: "light" },
  task: { icon: task, variant: "light" },
  taxRate: { icon: taxRate, variant: "light" },
  template: { icon: template, variant: "light" },
  thumbsDown: { icon: thumbsDown, variant: "light" },
  thumbsUp: { icon: thumbsUp, variant: "light" },
  tick: { icon: tick, variant: "light" },
  timesheetEntry: { icon: timesheetEntry, variant: "light" },
  twitterSolid: { icon: twitterSolid, variant: "solid" },
  unpaid: { icon: unpaid, variant: "light" },
  upload: { icon: upload, variant: "light" },
  user: { icon: user, variant: "light" },
  users: { icon: users, variant: "light" },
  void: { icon: financeVoid, variant: "light" },
  warning: { icon: warning, variant: "light" },
  youtube: { icon: youtube, variant: "light" },
  youtubeSolid: { icon: youtubeSolid, variant: "solid" },
  zoomIn: { icon: zoomIn, variant: "light" },
  zoomOut: { icon: zoomOut, variant: "light" },
};
const SIZES = {
  xs: 12,
  small: 16,
  normal: 21,
  medium: 28,
  large: 42,
  xl: 60,
};

export default {
  functional: true,
  props: {
    icon: {
      type: String,
      required: true,
      validator: icon => Object.keys(ICONS).indexOf(icon) !== -1,
    },
    size: {
      type: String,
      required: false,
      default: "small",
      validator: size => Object.keys(SIZES).indexOf(size) !== -1,
    },
    colour: {
      type: String,
      required: false,
      default: "grey",
    },
    block: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  render(createElement, { props, data }) {
    const icon = ICONS[props.icon];
    const display = props.block ? "block" : "inline";

    return createElement(
      icon.icon,
      mergeData(data, {
        staticClass: `${icon.variant} ${props.colour} ${display}`,
        attrs: {
          width: `${SIZES[props.size]}px`,
          height: `${SIZES[props.size]}px`,
          viewBox: "0 0 24 24",
        },
      })
    );
  },
};
</script>

<style lang="postcss">
svg {
  &.grey {
    &.light > path {
      stroke: var(--grey-80);
    }

    &.solid > path {
      fill: var(--grey-80);
    }
  }

  &.lightGrey {
    &.light > path {
      stroke: var(--grey-50);
    }

    &.solid > path {
      fill: var(--grey-50);
    }
  }

  &.red {
    &.light > path {
      stroke: var(--withered-cherry);
    }

    &.solid > path {
      fill: var(--withered-cherry);
    }
  }

  &.darkRed {
    &.light > path {
      stroke: var(--split-crimson);
    }

    &.solid > path {
      fill: var(--split-crimson);
    }
  }

  &.white {
    &.light > path {
      stroke: var(--white);
    }

    &.solid > path {
      fill: var(--white);
    }
  }

  &.green {
    &.light > path {
      stroke: var(--subatomic-sky);
    }

    &.solid > path {
      fill: var(--subatomic-sky);
    }
  }

  &.darkGreen {
    &.light > path {
      stroke: var(--delicate-pistachio);
    }

    &.solid > path {
      fill: var(--delicate-pistachio);
    }
  }

  &.blue {
    &.light > path {
      stroke: var(--tribal-aqua);
    }

    &.solid > path {
      fill: var(--tribal-aqua);
    }
  }

  &.yellow {
    &.light > path {
      stroke: var(--laughing-amber);
    }

    &.solid > path {
      fill: var(--laughing-amber);
    }
  }

  &.darkYellow {
    &.light > path {
      stroke: var(--rough-mustard);
    }

    &.solid > path {
      fill: var(--rough-mustard);
    }
  }

  &.inherit {
    &.light > path {
      stroke: currentColor;
    }

    &.solid > path {
      fill: currentColor;
    }
  }
}
</style>
