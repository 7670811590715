const INFO = "info";
const WARNING = "warning";
const ERROR = "error";
const SUCCESS = "success";

export default {
  INFO,
  WARNING,
  ERROR,
  SUCCESS,
};

export const icons = {
  [ERROR]: "error",
  [WARNING]: "warning",
  [INFO]: "info",
  [SUCCESS]: "success",
};

export const darkIconColours = {
  [ERROR]: "white",
  [WARNING]: "darkYellow",
  [INFO]: "grey",
  [SUCCESS]: "white",
};

export const lightIconColours = {
  [ERROR]: "darkRed",
  [WARNING]: "darkYellow",
  [INFO]: "grey",
  [SUCCESS]: "darkGreen",
};

export const bgColours = {
  [INFO]: "bg-grey-30",
  [ERROR]: "bg-breezy-pearl",
  [WARNING]: "bg-laughing-amber",
  [SUCCESS]: "bg-roasted-ivory",
};

export const textColours = {
  [INFO]: "text-grey-80",
  [ERROR]: "text-split-crimson",
  [WARNING]: "text-rough-mustard",
  [SUCCESS]: "text-delicate-pistachio",
};
