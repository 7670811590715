<template lang="pug">
  .mb-4
    label(:for="name" :class="errorClasses")
      slot(name="label" :invalid="hasErrors")
        span(:class="labelClasses") {{ fieldLabel }}
      slot(name="caption", v-if="description")
        span(:class="descriptionClasses") {{ description }}

    multiselect(v-model="fieldValue" v-bind="binds" v-on="listeners" ref="dropdown" :label="labelAttr" :multiple="multiple" :options="options" :class="errorClasses" placeholder="" select-label="" deselect-label="")
      template(v-slot:selection="scope")
        slot(name="selection" v-bind="scope")

      template(#singleLabel="{ option }")
        slot(name="singleLabel" v-bind="option")
          app-icon.mr-4(:icon="option.icon", :colour="iconColour(option)", v-if="option.icon")
          .inline-block.p-1 {{ option[labelAttr] }}

      template(#tag="{ option, remove }")
        .bg-tribal-aqua.mb-2.mr-2.inline-block.rounded-lg
          span.text-white.font-bold.p-2 {{ option[labelAttr] }}
          button.text-white.bg-tribal-aqua.p-2.rounded-lg.rounded-tl-none.rounded-bl-none(class="hover:bg-diving-sapphire" @click.prevent="remove(option)") X

      template(#option="{ option }")
        slot(name="option" v-bind="option")
          .flex.flex-row.justify-start.text-grey-80
            app-icon.mr-4(:icon="option.icon", :colour="iconColour(option)", v-if="option.icon")
            span {{ option[labelAttr] }}

    slot(name="errors" v-if="hasErrors" :errors="activeErrors" :errorMessages="activeErrorMessages" :has-errors="hasErrors" :first-error-message="firstErrorMessage")
      div.font-bold.text-right.mt-1(:class="errorClasses") {{ firstErrorMessage }}
</template>

<script>
import FieldMixin from "@/mixins/Field";
import Multiselect from "vue-multiselect";
import AppIcon from "@/components/elements/AppIcon.vue";

export default {
  name: "DropdownField",
  components: { AppIcon, Multiselect },

  mixins: [FieldMixin],

  props: {
    value: {
      type: [String, Number, Array, Object],
      default: "",
    },

    valueAttr: {
      type: String,
      default: null,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    labelAttr: {
      type: String,
      default: "label",
    },

    options: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    childrenAttr() {
      return this.$attrs["group-values"];
    },

    allChildren() {
      const { childrenAttr } = this;

      const children = [];

      this.options.forEach(opt => {
        if (childrenAttr && Array.isArray(opt[childrenAttr])) {
          opt[childrenAttr].forEach(child => children.push(child));
        } else {
          children.push(opt);
        }
      });

      return children;
    },

    fieldValue: {
      get() {
        const { valueAttr, value } = this;
        let selectedValue = value;

        // Meaning that the options are object but we don't need the full object returned
        // just a property of it like for example the 'id'
        if (valueAttr) {
          if (this.multiple) {
            if (value) {
              selectedValue = [];
              value.forEach(v => {
                const valObj = this.findOption(v);
                if (valObj) selectedValue.push(valObj);
              });
            } else {
              return [];
            }
          } else {
            selectedValue = this.findOption(value);
          }
        }

        return selectedValue;
      },

      set(value) {
        let valueToEmit = value;
        const { valueAttr } = this;

        if (value && valueAttr) {
          if (this.multiple) {
            const selectedValue = value.map(val => val[valueAttr]);
            valueToEmit = selectedValue;
          } else {
            valueToEmit = value[valueAttr];
          }
        }

        this.$emit("input", valueToEmit);
      },
    },
    listeners() {
      const listeners = Object.assign({}, this.$listeners);
      // We don't want the parent component being able to bind
      // the `input` event directly to the dropdown, since we
      // are handling that event ourselves. If it were bound
      // directly to the dropdown as well, it results in two
      // events being sent.
      delete listeners.input;

      return listeners;
    },
    binds() {
      const binds = Object.assign(
        {},
        {
          "select-label": "",
        },
        this.$attrs
      );

      return binds;
    },
  },

  methods: {
    findOption(value) {
      const { valueAttr } = this;
      let valObj = value;

      if (valueAttr) {
        valObj = this.allChildren.find(option => option[valueAttr] === value);
      }

      return valObj;
    },
    focus() {
      this.$refs.dropdown.activate();
    },
    iconColour(option) {
      if (option.iconColour) {
        return option.iconColour;
      }

      return undefined;
    },
  },
};
</script>
<style lang="postcss">
@import "vue-multiselect/dist/vue-multiselect.min.css";

.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: var(--font-family);
  @apply text-grey-80;
}

.multiselect .multiselect__tags {
  @apply rounded-lg;
}

.multiselect--above .multiselect__content-wrapper {
  @apply rounded-t-lg;
  @apply rounded-b-none;
}

.multiselect--below.multiselect__content-wrapper {
  @apply rounded-b-lg;
  @apply rounded-t-none;
}

.multiselect {
  .multiselect__tags {
    @apply border-grey-50;
    min-height: 3rem;
  }

  &.multiselect--active {
    .multiselect__tags,
    .multiselect__content-wrapper {
      @apply border-tribal-aqua;
    }

    .multiselect__content-wrapper {
      @apply shadow-lg;
    }
  }
}

.multiselect.error {
  .multiselect__tags,
  &.multiselect--active .multiselect__tags,
  &.multiselect--active .multiselect__content-wrapper {
    @apply border-withered-cherry;
  }
}

.multiselect__tag {
  background: var(--tribal-aqua) !important;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 1rem;
}

.multiselect__single {
  margin-bottom: 0;
}

.multiselect__option--highlight,
.multiselect__option.multiselect__option--selected.multiselect__option--highlight {
  background: var(--grey-30);
  color: var(--grey-80);

  &:after {
    background: none;
    color: var(--grey-80);
  }
}

.multiselect__option--selected {
  @apply border-l-4;
  @apply border-tribal-aqua;

  &:after {
    color: var(--grey-60);
  }
}

.multiselect.multiselect--active {
}
</style>
