<script>
const VALID_SIZES = ["h1", "h2", "h3", "h4", "h5", "h6"];
const SUBHEADER_ALLOWED = ["h1", "h2", "h3"];
const HEADER_SIZES = {
  h1: "text-2xl",
  h2: "text-xl",
  h3: "text-lg",
  h4: "",
  h5: "text-sm",
  h6: "text-xs",
};
const SUBHEADER_SIZES = {
  h1: "text-xl",
  h2: "text-lg",
  h3: "text-base",
};
export default {
  functional: true,
  props: {
    size: {
      type: String,
      required: false,
      default: "h2",
      validator(val) {
        return VALID_SIZES.indexOf(val) !== -1;
      },
    },
    margin: {
      type: String,
      required: false,
      default: "mb-8",
    },
  },
  render(createElement, { data, props, slots }) {
    const hasSubheader = !!slots().subheader;
    let children = [slots().default];
    const headerClasses = [
      "font-medium",
      HEADER_SIZES[props.size],
      ...(data.class || []),
    ];

    if (props.margin) {
      headerClasses.push(props.margin);
    }

    // Create a subheader element if it has been provided,
    // and it is actually allowed for the specified size
    if (hasSubheader && SUBHEADER_ALLOWED.indexOf(props.size) !== -1) {
      const subheader = createElement(
        "div",
        {
          class: `${SUBHEADER_SIZES[props.size]} text-grey-70 font-normal`,
        },
        slots().subheader
      );
      children = [...children, subheader];
    }

    return createElement(
      props.size,
      Object.assign(data, { class: headerClasses }),
      children
    );
  },
};
</script>
