const GREEN = "green";
const RED = "red";
const GREY = "grey";
const BLUE = "blue";
const YELLOW = "yellow";

export default {
  GREEN,
  RED,
  GREY,
  BLUE,
  YELLOW,
};
