<template lang="pug">
app-panel
  app-header Filters

  p Filters are used by the base table, to allow the user to filter the data that they see.
  p There are a number of different filters that can be applied. Below is a list of the available filters and how they can be used
  p.mt-8
    strong.mr-4 Note:
    | The panels aren't used directly, and area instead managed by the base table. The props shown here however, need to be supplied to the base table

  hr.h-px.border-0.bg-grey-30.my-16

  app-header Boolean filters

  p Boolean filters are used to toggle a filter value between `true` and `false`. It can present different labels such as `Enabled` or `Disabled`, however internally it will just filter a boolean

  demo-code-container
    .grid.grid-cols-2.gap-4
      .col-span-1
        boolean-filter(
          title="Boolean filter"
          description="Filtering on a true or false value"
          field="enabled"
          true-label="Enabled"
          false-label="Disabled"
          v-model="boolFilterValue")

      .col-span-1
        app-panel.ml-8(secondary)
          app-header(size="h3") Applied filter
          | {{ boolFilterValue }}

    template(#code)
      pre
        | boolean-filter(
        |   title="Boolean filter"
        |   description="Filtering on a true or false value"
        |   field="enabled"
        |   true-label="Enabled"
        |   false-label="Disabled"
        |   v-model="boolFilterValue")

  hr.h-px.border-0.bg-grey-30.my-16

  app-header Date filters

  p Date filters allow you to choose pre-determined date ranges, or choose two dates

  demo-code-container
    .grid.grid-cols-2.gap-4
      .col-span-1
        date-filter(
          title="Date filter"
          description="Filter a date field"
          field="date"
          v-model="dateFilterValue")

      .col-span-1
        app-panel(secondary)
          app-header(size="h3") Applied filter

          | {{ dateFilterValue }}

    template(#code)
      pre
        | date-filter(
        |   title="Date filter"
        |   description="Filter a date field"
        |   field="date"
        |   v-model="dateFilterValue")

  hr.h-px.border-0.bg-grey-30.my-16

  app-header Text filters

  p Text filters allow you to enter arbitrary text, and specify if this should be included or not in the resource being searched

  demo-code-container
    .grid.grid-cols-2.gap-4
      .col-span-1
        text-filter(
          title="Text filter"
          description="Filter a text field"
          field="text"
          v-model="textFilterValue")

      .col-span-1
        app-panel(secondary)
          app-header(size="h3") Applied filter

          | {{ textFilterValue }}

    template(#code)
      pre
        | text-filter(
        |   title="Text filter"
        |   description="Filter a text field"
        |   field="text"
        |   v-model="textFilterValue")

  hr.h-px.border-0.bg-grey-30.my-16

  app-header Option filters

  p Allows you to filter a field based on a defined list of options

  demo-code-container
    .grid.grid-cols-2.gap-4
      .col-span-1
        option-filter(
          title="Option filter"
          description="Lets you choose nothing, any, or all options"
          field="status"
          :options="optionFilterOptions"
          v-model="optionFilterValue")

      .col-span-1
        app-panel(secondary)
          app-header(size="h3") Applied filter

          | {{ optionFilterValue }}

  hr.h-px.border-0.bg-grey-30.my-16

  app-header Single-choice option filters

  p Pick from one of a number of options

  demo-code-container
    .grid.grid-cols-2.gap-4
      .col-span-1
        single-choice-filter(
          title="Single choice filter"
          description="Pick from the options provided"
          :options="options"
          v-model="singleChoiceFilterValue")

      .col-span-1
        app-panel(secondary)
          app-header(size="h3") Applied filter

          | {{ singleChoiceFilterValue }}

    template(#code)
      pre
        | single-choice-filter(
        |   title="Single choice filter"
        |   description="Pick from the options provided"
        |   field="field"
        |   :options="options"
        |   v-model="singleChoiceFilterValue")

</template>

<script>
import AppHeader from "@/components/elements/AppHeader.vue";
import AppPanel from "@/components/elements/AppPanel.vue";
import BooleanFilter from "@/components/collections/filters/AppFilterSidebarBooleanFilterPanel.vue";
import DateFilter from "@/components/collections/filters/AppFilterSidebarDateFilterPanel.vue";
import DemoCodeContainer from "@/views/demos/components/DemoCodeContainer.vue";
import SingleChoiceFilter from "@/components/collections/filters/AppFilterSidebarSingleChoiceFilterPanel.vue";
import OptionFilter from "@/components/collections/filters/AppFilterSidebarOptionFilterPanel.vue";
import TextFilter from "@/components/collections/filters/AppFilterSidebarTextFilterPanel.vue";

export default {
  components: {
    AppHeader,
    AppPanel,
    BooleanFilter,
    DateFilter,
    DemoCodeContainer,
    SingleChoiceFilter,
    OptionFilter,
    TextFilter,
  },
  data() {
    return {
      options: [
        {
          label: "Include templates",
          value: "includeTemplates",
          field: "isTemplate",
          filterValue: [true, false],
        },
        {
          label: "Show only templates",
          value: "onlyTemplates",
          field: "isTemplate",
          filterValue: true,
        },
      ],

      optionFilterOptions: () => [
        { label: "Not started", value: "NOT_STARTED" },
        { label: "Started", value: "STARTED" },
        { label: "Complete", value: "COMPLETE" },
      ],

      boolFilterValue: {},
      dateFilterValue: {},
      textFilterValue: {},
      optionFilterValue: {},
      singleChoiceFilterValue: {},
    };
  },
};
</script>
