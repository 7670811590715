<template lang="pug">
  transition-group(name="slide", tag="div", appear, v-if="currentNotice")
    .fixed.z-30.text-center.py-1.w-full.notice.bottom-0(
      v-for="notice in [currentNotice]"
      :key="noticeIdx"
      :class="[bgColour, textColour]"
      @mouseover="stopRotation"
      @mouseout="startRotation")

      | {{ notice.text }}
      a.cursor-pointer.underline.text-diving-sapphire.ml-2(v-for="action in notice.actions", @click="actionClicked(action)") {{ action.text }}

</template>

<script>
import SuccessTypes from "@/config/types/success-types";

const bgColours = {
  [SuccessTypes.WARNING]: "bg-laughing-amber",
  [SuccessTypes.INFO]: "bg-grey-40",
};

const textColours = {
  [SuccessTypes.WARNING]: "text-rough-mustard",
  [SuccessTypes.INFO]: "text-grey-80",
};

export default {
  data() {
    return {
      noticeIdx: 0,
      timer: null,
    };
  },
  mounted() {
    this.startRotation();
  },
  destroyed() {
    this.stopRotation();
  },
  computed: {
    bgColour() {
      return bgColours[this.currentNotice.type];
    },
    textColour() {
      return textColours[this.currentNotice.type];
    },
    currentNotice() {
      return this.$store.state.notices.notices[this.noticeIdx];
    },
    count() {
      return this.$store.state.notices.notices.length;
    },
  },
  methods: {
    startRotation() {
      this.timer = setInterval(this.next, 3000);
    },
    stopRotation() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
    next() {
      this.noticeIdx += 1;

      if (this.noticeIdx + 1 > this.count) {
        this.noticeIdx = 0;
      }
    },
    actionClicked({ action }) {
      action();
    },
  },
};
</script>

<style lang="postcss" scoped>
.slide-enter-active,
.slide-leave-active {
  transition: bottom 0.5s;
}
.slide-enter,
.slide-leave-to {
  bottom: -2rem;
}
</style>
