import { datasourceOptions, datasourceProps } from "./agGridDatasourceOptions";
import rowSelectionOptions from "./agGridRowSelectionOptions";
import behavioralOptions from "./agGridBehavioralOptions";
import { componentOptions, componentProps } from "./agGridComponentOptions";
import appearanceOptions from "./agGridAppearanceOptions";
import editingOptions from "./agGridEditingOptions";

/**
 * Generates an ag-grid options object based on the supplied component.
 *
 * @param {*} component The component object of the table component
 */
function setupGridOptions(component) {
  return Object.assign(
    {
      ...datasourceOptions(component),
      ...rowSelectionOptions(component),
      ...behavioralOptions(component),
      ...componentOptions(component),
      ...appearanceOptions(component),
      ...editingOptions(component),
    },
    component.options
  );
}

function setupGridProps(component) {
  return {
    ...datasourceProps(component),
    ...componentProps(component),
    columnDefs: component.columnDefs,
    defaultColDef: component.defaultColumn,
  };
}

export { setupGridOptions, setupGridProps };
