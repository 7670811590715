<template lang="pug">
  .relative.border.border-grey-50.w-48.rounded-lg
    flat-pickr.h-full.w-full.p-3.rounded-lg(
      v-model="date"
      :config="config",
      :placeholder="placeholder",
      :wrap="true",
      v-on="$listeners",
      ref="datePicker"
    )
    .absolute.inline.mr-4.transform.right-0.mt-5(class="-translate-y-1/2")
      app-icon(icon="calendar" size="small")
</template>

<script>
import FlatPickr from "vue-flatpickr-component";
import AppIcon from "@/components/elements/AppIcon.vue";

export default {
  components: {
    FlatPickr,
    AppIcon,
  },

  props: {
    classes: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    value: {
      type: [String, Date],
      default: () => new Date(),
    },

    disable: {
      type: [Array, Date, Function],
      default: () => [],
    },

    maxDate: {
      type: [String, Date],
      default: null,
    },

    minDate: {
      type: [String, Date],
      default: null,
    },

    format: {
      type: String,
      default: "Y-m-d",
    },
  },

  data() {
    return {};
  },

  computed: {
    date: {
      get() {
        return this.value ? new Date(this.value) : new Date();
      },

      set(value) {
        this.$emit("input", value);
      },
    },

    dateFormat() {
      return this.format || this.$store.state.dateFormat;
    },

    config() {
      return {
        altInput: true,
        altFormat: this.dateFormat,
        dateFormat: "Y/m/d",
        defaultDate: new Date(),
        minDate: this.minDate,
        maxDate: this.maxDate,
        allowInput: true,
        disable: this.disable,
      };
    },
  },

  methods: {
    focus() {
      this.$refs.datePicker.$el.nextSibling.focus();
    },
  },
};
</script>

<style lang="stylus">
$calendarBackground = #fff
$calendarBorderColor = alpha(#484848, 0.2)

$monthForeground = #3d3d3c
$monthBackground = var(--white)

$weekdaysBackground = var(--white)

$dayForeground = #3d3d3c
$dayHoverBackground = #e0e3e5

$arrow_hover_color = var(--tribal-aqua)
$todayColor = #bbb

$selectedDayBackground = var(--tribal-aqua)
$selectedDayForeground = #fff

$noCalendarBorder = false
$calendarBorderColor = var(--grey-30)
@require '~flatpickr/src/style/flatpickr.styl'
</style>
