<template lang="pug">
app-panel.mt-8(compact)
  .p-8(v-if="$slots.default")
    slot(name="default")

  pre.rounded-b-lg.m-0(:class="codeClasses")
    code.rounded-b-lg(:class="codeClasses")
      slot(name="code")

</template>

<script>
import AppPanel from "@/components/elements/AppPanel.vue";
import Prism from "prismjs";
// import "prismjs/themes/prism.css";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-pug";

export default {
  components: {
    AppPanel,
  },
  props: {
    language: {
      type: String,
      required: false,
      default: "pug",
    },
  },
  computed: {
    codeClasses() {
      let classes = [`language-${this.language}`];
      if (!this.$slots.default) {
        classes.push("rounded-t-lg");
      }

      return classes;
    },
  },
  mounted() {
    Prism.highlightAll();
  },
};
</script>
