<template lang="pug">
  span.relative.block(v-if="icon || clearable || button")
    app-icon.absolute.ml-4.mt-6.transform(v-if="icon", class="-translate-y-1/2", :icon="icon")
    input.input.w-full.h-12.px-3.pr-2(:value="fieldValue", :type="type", v-on="listeners", :class="inputClasses", v-bind="$attrs")

    .absolute.right-0.top-0.h-full.flex.flex-row.items-center
      app-icon.mr-4.cursor-pointer(v-if="clearable && fieldValue", icon="error", @click.native="clear")
      .cursor-pointer.border-l.border-grey-30.h-full.px-4.flex.flex-row.items-center(v-if="button" @click="$emit('button-click')")
        slot(name="button")

  input.input.h-12.px-3.py-2(v-else, :value="fieldValue", :type="type", v-on="listeners", :class="inputClasses", v-bind="$attrs")

</template>

<script>
import AppIcon from "@/components/elements/AppIcon.vue";

export default {
  components: {
    AppIcon,
  },
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    standalone: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    button: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    inputClasses() {
      let classes = ["outline-none", "border", "border-solid", "rounded-lg"];

      if (this.icon) {
        classes.push("pl-12");
      }

      if (this.button) {
        classes.push("button");

        if (this.clearable) {
          classes.push("pr-40");
        } else {
          classes.push("pr-24");
        }
      } else if (this.clearable) {
        classes.push("pr-12");
      }

      if (this.error) {
        classes.push("text-split-crimson", "border-withered-cherry");
      } else {
        classes.push("focus:border-tribal-aqua");

        if (this.formInput) {
          classes.push("border-grey-50");
        }

        if (this.standalone) {
          classes.push("focus:border", "border-grey-30");
        } else {
          classes.push("border-grey-50");
        }
      }

      return classes;
    },
    fieldValue() {
      return this.value;
    },
    listeners() {
      return Object.assign({}, this.$listeners, {
        input: evt => {
          this.$emit("input", evt.target.value);
        },
      });
    },
  },
  methods: {
    clear() {
      this.$emit("input", "");
    },
  },
};
</script>

<style lang="postcss" scoped>
input.button + .clear-button {
  right: 7.5rem;
}
</style>
