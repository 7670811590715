import Labels from "./types/label-types";

export const bgColours = {
  [Labels.GREY]: "bg-grey-30",
  [Labels.RED]: "bg-breezy-pearl",
  [Labels.BLUE]: "bg-yawning-snow",
  [Labels.GREEN]: "bg-roasted-ivory",
  [Labels.YELLOW]: "bg-laughing-amber",
};

export const textColours = {
  [Labels.GREY]: "text-grey-80",
  [Labels.RED]: "text-split-crimson",
  [Labels.BLUE]: "text-diving-sapphire",
  [Labels.GREEN]: "text-delicate-pistachio",
  [Labels.YELLOW]: "text-rough-mustard",
};

export const borderColours = {
  [Labels.GREY]: "border-grey-40",
  [Labels.RED]: "border-split-crimson",
  [Labels.BLUE]: "border-diving-sapphire",
  [Labels.GREEN]: "border-delicate-pistachio",
  [Labels.YELLOW]: "border-rough-mustard",
};
