<template lang="pug">
  .flex.flex-row.w-full.h-full
    .flex-none.w-64.bg-white.border-r.border-grey-30.overflow-auto
      .p-4
        app-header(margin="") Components

      .flex.flex-col
        router-link.sidebar-item(to="/alerts") Alerts
        router-link.sidebar-item(to="/buttons") Buttons
        router-link.sidebar-item(to="/filters") Filters
        router-link.sidebar-item(to="/icons") Icons
        router-link.sidebar-item(to="/inputs") Inputs
        router-link.sidebar-item(to="/labels") Labels
        router-link.sidebar-item(to="/messages") Messages
        router-link.sidebar-item(to="/notices") Notices
        router-link.sidebar-item(to="/other") Other
        router-link.sidebar-item(to="/panels") Panels
        router-link.sidebar-item(to="/progress") Progress bars
        router-link.sidebar-item(to="/sidebars") Sidebars
        router-link.sidebar-item(to="/split-view") Split view
        router-link.sidebar-item(to="/tables") Tables

    .flex-1.overflow-auto
      .p-16
        router-view

</template>

<script>
import AppHeader from "@/components/elements/AppHeader.vue";
import AppPanel from "@/components/elements/AppPanel.vue";

export default {
  components: {
    AppHeader,
    AppPanel,
  },
};
</script>

<style lang="postcss" scoped>
.sidebar-item {
  @apply relative;
  @apply flex-initial;
  @apply block;
  @apply p-4;
  @apply w-full;
  @apply no-underline;
  @apply text-grey-80;
  z-index: 1;

  &.router-link-active {
    @apply text-white;

    &:after {
      content: "";
      @apply absolute;
      z-index: -1;
      left: 0.5rem;
      right: 0.5rem;
      top: 0.5rem;
      bottom: 0.5rem;
      @apply bg-tribal-aqua;
      @apply rounded;
    }
  }
}
</style>
