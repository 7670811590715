<template lang="pug">

  .alerts
    app-panel
      app-header(size="h1" margin="mb-16")
        | Alerts
        template(#subheader) Alerts, or flash messages are used to temporarily present a bit of information to the user


      app-tabs
        app-tab(name="Details" selected)

          app-header Introduction

          p Alerts, also known as #[code flash] messages

          hr.h-px.border-0.bg-grey-30.my-8

          app-header Info alert

          p An #[code info] alert is used to present some basic information that has no other status. It is not an error, or a warning, or a success. Just information.

          demo-code-container
            template(#default)
              app-flash-message.mb-4(type="info", title="Info", text="I am some useful information")
              app-flash-message.mb-4(type="info", title="Info", text="I am some useful information", @click="onClick", :action="action")

            template(#code)
              pre
                | app-flash-message.mb-4(type="info", title="Info", text="I am some useful information")
                | app-flash-message.mb-4(type="info", title="Info", text="I am some useful information", @click="onClick", :action="action")

          hr.h-px.border-0.bg-grey-30.my-8

          app-header Warning alert

          p A #[code warning] alert is used to warn users of potential issues. They're not as severe as an error, but it's not successful either
          demo-code-container
            template(#default)
              app-flash-message.mb-4(type="warning", title="Warning", text="I'm warning you about something")
              app-flash-message.mb-4(type="warning", title="Warning", text="I'm warning you about something", @click="onClick", :action="action")

            template(#code)
              pre
                | app-flash-message.mb-4(type="warning", title="Warning", text="I'm warning you about something")
                | app-flash-message.mb-4(type="warning", title="Warning", text="I'm warning you about something", @click="onClick", :action="action")

          hr.h-px.border-0.bg-grey-30.my-8

          app-header Error alert

          p An #[code error] alert is used to let users know that an action they've performed has failed, or some other unexpected error has occurred
          demo-code-container
            template(#default)
              app-flash-message.mb-4(type="error", title="Error", text="I'm an error alert")
              app-flash-message.mb-4(type="error", title="Error", text="I'm an error alert", @click="onClick", :action="action")

            template(#code)
              pre
                | app-flash-message.mb-4(type="error", title="Error", text="I'm an error alert")
                | app-flash-message.mb-4(type="error", title="Error", text="I'm an error alert", @click="onClick", :action="action")

          hr.h-px.border-0.bg-grey-30.my-8

          app-header Success alert

          p A #[code success] alert is used to let users know that an action they've performed succeeded
          demo-code-container
            template(#default)
              app-flash-message.mb-4(type="success", title="Success", text="I'm letting you know it worked")
              app-flash-message.mb-4(type="success", title="Success", text="I'm letting you know it worked", @click="onClick", :action="action")

            template(#code)
              pre
                | app-flash-message.mb-4(type="success", title="Success", text="I'm letting you know it worked")
                | app-flash-message.mb-4(type="success", title="Success", text="I'm letting you know it worked", @click="onClick", :action="action")

        app-tab(name="Usage")
          app-header Usage

          p
            | Alerts are generally triggered in response to a user action. There are two ways of triggering an alert, depending on whether the code
            | is in a component or not.

          hr.h-px.border-0.bg-grey-30.my-8

          app-header In a component

          p
            | Presenting an alert from a component can be achieved using the #[code $flash] object.

          demo-code-container
            template(#default)
              a.block(@click="flash('info')") Present info flash
              a.block(@click="flash('warn')") Present warning flash
              a.block(@click="flash('success')") Present success flash
              a.block(@click="flash('error')") Present error flash

            template(#code)
              pre
                | a(@click="runSomething") Run something
                | ...
                | runSomething() {
                |   doSomethingAsync().then(() => {
                |     this.$flash.success("Something succeeded");
                |   }).catch(() => {
                |     this.$flash.error("Something went wrong");
                |   })
                | }

          hr.h-px.border-0.bg-grey-30.my-8

          app-header Outside of a component

          p Presenting an alert outside of a component is similar, however you need to import the #[code Flash] object from the #[code ui-kit]

          demo-code-container
            template(#default)
              a.block(@click="flash('info')") Present info flash
              a.block(@click="flash('warn')") Present warning flash
              a.block(@click="flash('success')") Present success flash
              a.block(@click="flash('error')") Present error flash

            template(#code)
              pre
                | import { Flash } from "manageplaces-ui-kit";
                | .....
                |
                | runSomething() {
                |   doSomethingAsync().then(() => {
                |     Flash.success("Something succeeded");
                |   }).catch(() => {
                |     Flash.error("Something went wrong");
                |   });
                | }

        app-tab(name="Props")

      .grid.grid-cols-4.h-full.w-full
        .col-span-1

        .col-span-1.col-start-3

    app-panel

      p Flash messages are used to notify the user that something happened, whether it is good or bad.
      p There are two key types of flash message:

      ul.list-decimal.mt-8
        li Temporary
        li Persistent

      p.mt-8 By default, flash messages will be shown temporarily, and will automatically disappear after 5 seconds.


      app-tabs
        app-tab(name="Demo" selected)
          a.block.mt-8(@click="flash('info')") Present info flash
          a.block(@click="flash('warn')") Present warning flash
          a.block(@click="flash('success')") Present success flash
          a.block(@click="flash('error')") Present error flash

        app-tab(name="Usage")
          pre.my-8
            code.language-javascript
              | a(@click="runSomething") Run something
              | ...
              | runSomething() {
              |   doSomethingAsync().then(() => {
              |     this.$flash.success("Something succeeded");
              |   }).catch(() => {
              |     this.$flash.error("Something went wrong");
              |   })
              | }


    app-panel

      p Optionally a flash message can contain a button to perform some action. This
      p is most useful when combined with making the flash persistent, so users can read
      p the full message and make a decision

      app-tabs
        app-tab(name="Demo" selected)
          a.block.mt-8(@click="buttonFlash('info')") Click me

        app-tab(name="Usage")
          pre.my-8
            code.language-javascript
              | a(@click="askUser") Confirm
              | ...
              | askUser() {
              |   this.$flash.info('Something happened', {
              |     persist: true,
              |     action: {
              |       text: "Got it",
              |       handler(dismissFn) {
              |         // Do something
              |         dismissFn();
              |       }
              |     }
              |   });
              | }

          p.mt-8 The handler function will be passed a function to dismiss the flash message.
          p You should invoke this when you're ready to remove the flash message
</template>

<script>
import AppFlashMessage from "@/components/elements/AppFlashMessage.vue";
import AppHeader from "@/components/elements/AppHeader.vue";
import AppPanel from "@/components/elements/AppPanel.vue";
import AppTab from "@/components/elements/AppTab.vue";
import AppTabs from "@/components/elements/AppTabs.vue";
import DemoCodeContainer from "@/views/demos/components/DemoCodeContainer.vue";

export default {
  components: {
    AppFlashMessage,
    AppHeader,
    AppPanel,
    AppTab,
    AppTabs,
    DemoCodeContainer,
  },
  data() {
    return {
      counter: 0,
      action: {
        text: "Click me",
      },
    };
  },
  methods: {
    onClick() {
      alert("hi");
    },
    flash(type) {
      this.counter++;
      this.$flash[type](`Hello ${this.counter}`);
    },
    buttonFlash() {
      this.counter++;
      this.$flash.info(`Hello ${this.counter}`, {
        persist: true,
        action: {
          text: "Got it!",
          handler(dismiss) {
            alert("Dismissed");
            dismiss();
          },
        },
      });
    },
  },
};
</script>
