import LoadingOverlay from "@/components/collections/base_table/overlays/BaseTableLoadingOverlay.vue";
import NoResultsOverlay from "@/components/collections/base_table/overlays/BaseTableNoResultsOverlay.vue";

/**
 * Generate the ag-grid options for custom components.
 *
 * @param {Object} component Table component
 */
function componentOptions(component) {
  return {
    frameworkComponents: Object.assign(
      {
        loadingOverlay: LoadingOverlay,
        noResultsOverlay: NoResultsOverlay,
      },
      {},
      component.components.vue
    ),
    components: component.components.agGrid,
  };
}

function componentProps() {
  return {
    loadingOverlayComponent: "loadingOverlay",
    noRowsOverlayComponent: "noResultsOverlay",
  };
}

export { componentOptions, componentProps };
