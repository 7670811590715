<template lang="pug">
  .mb-4.w-full
    label(:for="name" :class="errorClasses")
      slot(name="label" :invalid="hasErrors")
        span(:class="labelClasses") {{ fieldLabel }}
      slot(name="caption" v-if="hasDescription")
        span(:class="descriptionClasses") {{ description }}

    .flex.flex-row.flex-wrap.justify-start
      .pr-8(v-for="option in options" :key="option.name")
        input.hidden(type="radio" :id="name + option.value" :value="option.value" v-model="fieldValue")
        label.flex.items-center.cursor-pointer.text-grey-70.text-lg.outline-none(:for="name + option.value" :class="errorClasses + ' focus:font-bold'"
                                                                                  tabindex="0" @keyup.space.enter.prevent="fieldValue = option.value")
          span.w-8.h-8.inline-block.mr-4.rounded-full.border.flex-no-shrink
          | {{ option.name }}

    slot(name="errors" v-if="hasErrors" :errors="activeErrors" :errorMessages="activeErrorMessages" :has-errors="hasErrors" :first-error-message="firstErrorMessage")
      div.font-bold(:class="errorClasses") {{ firstErrorMessage }}
</template>

<script>
import FieldMixin from "@/mixins/Field";

export default {
  mixins: [FieldMixin],

  props: {
    options: {
      type: Array,
      required: true,
    },
  },

  computed: {
    // We'll mark this as required, as radio buttons always have
    // one thing selected at least
    required() {
      return true;
    },
  },
};
</script>

<style lang="postcss" scoped>
input[type="radio"] {
  & + label span {
    transition: background 0.2s, transform 0.2s;
    @apply border-grey-60;
  }

  &:checked + label span {
    @apply bg-tribal-aqua;
    @apply border-tribal-aqua;
    box-shadow: 0px 0px 0px 2px var(--white) inset;
  }
}
</style>
