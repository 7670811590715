<template lang="pug">
  .flex-1.overvlow-auto(v-show="isActive")
    template(v-if="shouldLoadContent")
      slot
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isActive: false,
    };
  },

  computed: {
    shouldLoadContent() {
      return this.lazy ? this.isActive : true;
    },
  },

  mounted() {
    this.isActive = this.selected;
  },
};
</script>
