<template lang="pug">
  app-panel.flex.flex-row(compact)
    .flex-initial.bg-grey-10.px-4.py-6.border-grey-30.border-r(class="w-1/4")
      template(v-for="option in internalMenuOptions")
        .flex.flex-col(:key="option.key")
          template(v-if="option.children")
            strong.mb-2.px-4.mt-8 {{ option.groupLabel }}

            template(v-for="child in option.children")
              app-panel.my-4(nested v-if="child.renaming" :key="child.key")
                .flex.flex-col
                  app-text-input.mb-4(v-model="renamingItems[child.key].label" :ref="`rename-${child.key}`" @keyup.enter="renameItem(child)")
                  app-button.text-center(primary slim @click="renameItem(child)") Rename
                  a.mt-2(href="#" @click.prevent="cancelRenaming(child)") Cancel

              menu-item(v-else :key="child.key" :option="child" :actions="option.childActions" :active="active.key === child.key" @click="menuItemClicked" @action-click="menuActionClicked(child, $event)")

            template(v-if="option.allowAdditions")
              a.px-4.py-2(href="#" @click.prevent="startAddingItem(option)" v-if="!groupsAddingItems[option.key]") {{ option.addLabel || "Add" }}
              app-panel.mt-4(nested v-else)
                .flex.flex-col
                  app-text-input.mb-4(v-model="groupsAddingItems[option.key].label" :ref="`input-${option.key}`" @keyup.enter="addNewItem(option)")
                  app-button.text-center(primary slim @click="addNewItem(option)") {{ option.addLabel || "Add" }}
                  a.mt-2(href="#" @click.prevent="cancelEditing(option)") Cancel

          template(v-else)
            menu-item(:option="option" :actions="option.actions" :active="active.key === option.key" @click="menuItemClicked")

    .flex-1
      template(v-if="$slots[active.slot]")
        slot(:name="active.slot")

      template(v-else)
        slot
</template>

<script>
import AppButton from "@/components/elements/AppButton.vue";
import AppTextInput from "@/components/elements/AppTextInput.vue";
import AppPanel from "@/components/elements/AppPanel.vue";
import MenuItem from "./app_split_view_container/AppSplitViewContainerMenuItem.vue";
import { random } from "@/components/helpers/string-helpers";

const ACTIONS = {
  rename: "RENAME",
  delete: "DELETE",
};

export default {
  components: {
    AppButton,
    AppTextInput,
    AppPanel,
    MenuItem,
  },
  props: {
    menuOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      active: {},
      internalMenuOptions: [],
      groupsAddingItems: {},
      renamingItems: {},
    };
  },
  mounted() {
    this.processMenuOptions();
  },
  methods: {
    addNewItem(group) {
      const item = this.groupsAddingItems[group.key];
      if (item.label && item.label.trim() !== "") {
        this.$emit("menu-item-added", {
          group,
          ...item,
        });
        this.cancelEditing(group);
      }
    },
    cancelEditing(group) {
      this.$delete(this.groupsAddingItems, group.key);
    },
    cancelRenaming(menuItem) {
      this.$delete(this.renamingItems, menuItem.key);
      menuItem.renaming = false;
    },
    focusOnGroupInput(group) {
      this.$refs[`input-${group.key}`][0].$el.focus();
    },
    menuActionClicked(menuItem, action) {
      if (action.action === ACTIONS.delete) {
        // Delete
        this.$dialog
          .confirm({
            message: "Deleting this item cannot be undone",
            danger: true,
            confirmLabel: "Delete item",
          })
          .onOk(({ api }) => {
            this.$emit("menu-item-deleted", menuItem);
            api.hide();
          });
      } else if (action.action === ACTIONS.rename) {
        // Rename
        this.startRenamingItem(menuItem);
      } else {
        this.$emit("menu-item-action-clicked", { menuItem, action });
      }
    },
    menuItemClicked(item) {
      this.active = item;
      this.$emit("menu-item-clicked", item);
    },
    processChildActions(group) {
      const { childActions, allowDeletion, allowRename } = group;
      let newOptions = [];

      if (allowRename) {
        newOptions.push({
          label: "Rename",
          icon: "edit",
          action: ACTIONS.rename,
        });
      }

      if (allowDeletion) {
        newOptions.push(
          {
            type: "divider",
          },
          {
            label: "Delete",
            icon: "delete",
            action: ACTIONS.delete,
            iconColour: "red",
          }
        );
      }

      return [...(childActions || []), ...newOptions];
    },
    processMenuOptions() {
      this.internalMenuOptions = this.menuOptions.map(opt => {
        let internalOpt = { ...opt };

        internalOpt.key = internalOpt.id || random();
        if (internalOpt.children) {
          internalOpt.type = "group";
          internalOpt.childActions = this.processChildActions(internalOpt);
          internalOpt.children = opt.children.map(child => {
            let internalChild = { ...child };
            internalChild.type = "item";
            internalChild.key = child.id || random();
            internalChild.renaming = false;

            return internalChild;
          });
        } else {
          internalOpt.type = "item";
        }

        return internalOpt;
      });

      const firstOption = this.internalMenuOptions[0];
      if (firstOption.type === "group") {
        this.active = firstOption.children[0];
      } else {
        this.active = firstOption;
      }
    },
    renameItem(item) {
      item.label = this.renamingItems[item.key].label;
      this.cancelRenaming(item);
      this.$emit("menu-item-renamed", item);
    },
    startAddingItem(group) {
      this.$set(this.groupsAddingItems, group.key, { label: null });
      this.$nextTick(() => {
        this.focusOnGroupInput(group);
      });
    },
    startRenamingItem(menuItem) {
      menuItem.renaming = true;
      this.renamingItems[menuItem.key] = { label: menuItem.label };
      this.$nextTick(() => {
        const el = this.$refs[`rename-${menuItem.key}`][0].$el;
        el.select();
      });
    },
  },
  watch: {
    menuOptions: {
      handler() {
        this.processMenuOptions();
      },
      deep: true,
    },
  },
};
</script>
