<template lang="pug">
  div.flex.flex-col.justify-center(@mouseenter="onMouseEnter", @mouseleave="onMouseLeave", :class="{ 'inline-flex': inline }")
    div.flex-1(ref="trigger", @click="onClick", v-if="trigger !== 'manual'", :class="{ 'inline-flex': inline }")
      slot(name="trigger" :open="open")
        app-button(v-bind="binds")
          | Actions
          app-icon.ml-6(:icon="open ? 'caretUp' : 'caretDown'")

    mounting-portal(mount-to="body", append, v-if="open")
      .dropdown.bg-white.shadow.border.border-grey-30.py-2.z-50.rounded-lg(:id="dropdownId", v-click-outside="vcoConfig", @mouseenter="onMouseEnter", @mouseleave="onMouseLeave")
        .arrow(data-popper-arrow, v-if="pointing")
        slot(name="content", :close="close")

        template(v-if="!$slots.content")
          template(v-for="(option, idx) in dropdownOptions")

            .py-2.px-4(v-if="option.type == 'content'", :key="idx")
              slot(:name="option.slot", :option="option")

            .py-2.px-4(v-else-if="option.type == 'divider'", :key="idx")
              .h-px.bg-grey-30.w-full

            .dropdown-option.py-2.px-4.flex.flex-row.items-center.cursor-pointer(v-else, @click="optionClicked(option)")
              app-icon.mr-4(:icon="option.icon", v-if="option.icon", :colour="option.iconColour")
              span| {{ option.label }}
</template>

<script>
import AppButton from "@/components/elements/AppButton.vue";
import AppIcon from "@/components/elements/AppIcon.vue";
import { createPopper } from "@popperjs/core";
import { placements } from "@popperjs/core/dist/esm/enums.js";
import { random } from "@/components/helpers/string-helpers";

export default {
  components: {
    AppButton,
    AppIcon,
  },
  data() {
    return {
      dropdownId: this.generateId(),
      open: false,
      popper: null,
      hideTimeout: null,
      vcoConfig: {
        events: ["contextmenu", "click"],
        handler: this.close,
      },
    };
  },
  props: {
    options: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    pointing: {
      type: Boolean,
      required: false,
      default: false,
    },
    trigger: {
      type: String,
      required: false,
      default: "click",
      validator: trigger =>
        ["click", "hover", "manual"].indexOf(trigger) !== -1,
    },
    origin: {
      type: [Object, HTMLElement],
      required: false,
      default: null,
    },
    inline: {
      type: Boolean,
      required: false,
      default: true,
    },
    placement: {
      type: String,
      required: false,
      default: "bottom-end",
      validator: placement => placements.includes(placement),
    },
    flip: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    binds() {
      return Object.assign({}, this.$attrs);
    },
    dropdownOptions() {
      return this.options.map(opt => {
        if (typeof opt === "string") {
          return { label: opt, icon: null };
        } else {
          return Object.assign(
            { type: "option", icon: null, iconColour: "grey" },
            opt
          );
        }
      });
    },
    popperTarget() {
      if (this.trigger === "manual") {
        return this.origin;
      }

      return this.$refs["trigger"];
    },
  },
  beforeDestroy() {
    this.destroyPopper();
  },
  methods: {
    close() {
      this.open = false;
      this.$emit("close");
    },
    async createPopper() {
      await this.$nextTick();

      const dropdown = document.getElementById(this.dropdownId);
      const modifiers = [
        {
          name: "offset",
          options: {
            offset: this.pointing ? [0, 14] : [0, 0],
          },
        },
      ];

      if (this.flip) {
        modifiers.push({
          name: "flip",
          options: {
            fallbackPlacements: ["top", "right", "left", "bottom"],
          },
        });
      }

      this.popper = createPopper(this.popperTarget, dropdown, {
        placement: this.placement,
        modifiers,
      });
    },
    destroyPopper() {
      if (this.popper) {
        this.popper.destroy();
        this.popper = null;
      }
    },
    generateId() {
      const id = random();
      return `dropdown-${id}`;
    },
    onMouseEnter() {
      if (this.trigger === "hover") {
        if (this.hideTimeout) {
          clearTimeout(this.hideTimeout);
          this.hideTimeout = null;
        }

        this.open = true;
      }
    },
    onMouseLeave() {
      if (this.trigger === "hover") {
        this.hideTimeout = setTimeout(() => {
          this.open = false;
        }, 100);
      }
    },
    onClick() {
      if (this.trigger === "click") {
        this.open = true;
      }
    },
    optionClicked(opt) {
      this.$emit("click", opt);
      this.open = false;
    },
  },
  watch: {
    open(isOpen) {
      if (isOpen) {
        this.createPopper();
      } else {
        this.destroyPopper();
      }
    },
    origin: {
      immediate: true,
      handler(newOrigin) {
        if (newOrigin === null) {
          this.open = false;
        } else if (this.open) {
          this.destroyPopper();
          this.createPopper();
        } else {
          this.open = true;
        }
      },
    },
  },
};
</script>

<style lang="postcss" scoped>
.arrow,
.arrow::before {
  position: absolute;
  width: 1rem;
  height: 1rem;
  z-index: -1;
}

.arrow::before {
  content: "";
  transform: rotate(45deg);
  background: var(--white);
  border-color: var(--grey-20) var(--white) var(--white) var(--grey-20);
  border-width: 1px;
  border-style: solid;
}

.dropdown[data-popper-placement^="top"] > .arrow {
  bottom: -0.5rem;
}

.dropdown[data-popper-placement^="bottom"] > .arrow {
  top: -0.5rem;
}

.dropdown[data-popper-placement^="left"] > .arrow {
  right: -0.5rem;
}

.dropdown[data-popper-placement^="right"] > .arrow {
  left: -0.5rem;
}

.dropdown-option {
  position: relative;
  z-index: 1;

  &:hover:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0.5rem;
    left: 0.5rem;
    @apply bg-grey-20;
    z-index: -1;
    @apply p-2;
    @apply rounded-lg;
  }
}
</style>
