<template lang="pug">
app-sidebar(@close="$emit('input', false)")
  template(#title) Select columns
  .p-8
    app-panel(secondary)
      app-checkbox.mb-4(
        v-for="(column, index) in options"
        :label="column.headerName"
        :key="column.headerName"
        :value="!column.hide"
        @input="toggleColumn(column)")
</template>

<script>
import AppCheckbox from "@/components/elements/AppCheckbox.vue";
import AppHeader from "@/components/elements/AppHeader.vue";
import AppIcon from "@/components/elements/AppIcon.vue";
import AppPanel from "@/components/elements/AppPanel.vue";
import AppSidebar from "@/components/elements/AppSidebar.vue";

export default {
  components: {
    AppCheckbox,
    AppHeader,
    AppIcon,
    AppPanel,
    AppSidebar,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
  },
  computed: {
    options() {
      return this.columns.filter(({ canToggle }) => {
        return canToggle ?? true;
      });
    },
  },
  methods: {
    toggleColumn(column) {
      this.$emit("toggle", column.field);
    },
  },
};
</script>
