import SuccessTypes from "@/config/types/success-types";
import Vue from "vue";

export default {
  namespaced: true,
  state() {
    return {
      messageCount: 0,
      messages: [],
    };
  },
  mutations: {
    showFlashMessage(state, message) {
      const id = `${message.type}_${state.messageCount}`;
      state.messageCount++;

      Vue.set(state.messages, id, message);
      message.id = id;

      if (!message.persist) {
        setTimeout(() => {
          Vue.delete(state.messages, id);
        }, 5000);
      }
    },
    dismissFlashMessage(state, message) {
      Vue.delete(state.messages, message.id);
    },
  },
  getters: {
    messages(state) {
      return Object.values(state.messages).sort((a, b) => b.time - a.time);
    },
  },
  actions: {
    error({ commit }, { text, opts = {} }) {
      commit("showFlashMessage", {
        type: SuccessTypes.ERROR,
        title: "Error",
        text: text,
        time: Date.now(),
        ...opts,
      });
    },
    info({ commit }, { text, opts = {} }) {
      commit("showFlashMessage", {
        type: SuccessTypes.INFO,
        title: "Info",
        text: text,
        time: Date.now(),
        ...opts,
      });
    },
    success({ commit }, { text, opts = {} }) {
      commit("showFlashMessage", {
        type: SuccessTypes.SUCCESS,
        title: "Success",
        text: text,
        time: Date.now(),
        ...opts,
      });
    },
    warn({ commit }, { text, opts = {} }) {
      commit("showFlashMessage", {
        type: SuccessTypes.WARNING,
        title: "Warning",
        text: text,
        time: Date.now(),
        ...opts,
      });
    },
  },
};
