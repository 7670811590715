import Vue from "vue";
import VueRouter from "vue-router";
import Demo from "../views/Demo.vue";

// import AppIconsDemo from "../views/demos/AppIconDemo.vue";
import ButtonDemo from "../views/demos/ButtonDemo.vue";
// import FileIconsDemo from "../views/demos/FileIconsDemo.vue";
import FilterDemo from "../views/demos/FilterDemo.vue";
import FlashDemo from "../views/demos/FlashDemo.vue";
import IconDemo from "../views/demos/IconDemo.vue";
import InputsDemo from "../views/demos/InputsDemo.vue";
import LabelsDemo from "../views/demos/LabelsDemo.vue";
import MessagesDemo from "../views/demos/MessagesDemo.vue";
import NoticesDemo from "../views/demos/NoticesDemo.vue";
import OtherDemo from "../views/demos/OtherDemo.vue";
import PanelsDemo from "../views/demos/PanelsDemo.vue";
import ProgressDemo from "../views/demos/ProgressDemo.vue";
import SidebarsDemo from "../views/demos/SidebarsDemo.vue";
import SplitViewDemo from "../views/demos/SplitViewDemo.vue";
import TablesDemo from "../views/demos/TableDemo.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Demo",
    component: Demo,
    children: [
      {
        path: "alerts",
        component: FlashDemo,
      },
      {
        path: "buttons",
        component: ButtonDemo,
      },
      {
        path: "filters",
        component: FilterDemo,
      },
      {
        path: "icons",
        component: IconDemo,
      },
      {
        path: "inputs",
        component: InputsDemo,
      },
      {
        path: "labels",
        component: LabelsDemo,
      },
      {
        path: "messages",
        component: MessagesDemo,
      },
      {
        path: "notices",
        component: NoticesDemo,
      },
      {
        path: "other",
        component: OtherDemo,
      },
      {
        path: "panels",
        component: PanelsDemo,
      },
      {
        path: "progress",
        component: ProgressDemo,
      },
      {
        path: "sidebars",
        component: SidebarsDemo,
      },
      {
        path: "split-view",
        component: SplitViewDemo,
      },
      {
        path: "tables",
        component: TablesDemo,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
