export default {
  install(Vue, { store }) {
    const fns = {
      info(text, actions = null) {
        store.dispatch("notices/info", { text, actions });
      },
      warn(text, actions = null) {
        store.dispatch("notices/warn", { text, actions });
      },
      error(text, actions = null) {
        store.dispatch("notices/error", { text, actions });
      },
      success(text, actions = null) {
        store.dispatch("notices/success", { text, actions });
      },
    };

    Vue.prototype.$notice = fns;
    this.info = fns.info;
    this.warn = fns.warn;
    this.error = fns.error;
    this.success = fns.success;
  },
};
