<template lang="pug">
  .flex.flex-row.h-full
    app-avatar.flex-none.self-center(v-if="user", :avatar="user.avatar")
    .flex-1.ml-4.self-center(v-if="user")
      p.leading-none {{ user.name }}
      p.leading-none.text-grey-60.text-sm.mt-1(v-if="user.roleName")  {{ user.roleName }}
</template>

<script>
import AppAvatar from "@/components/elements/AppAvatar.vue";

export default {
  components: {
    AppAvatar,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>
