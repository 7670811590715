/**
 * Returns the grid options required to enabled row selection
 * if applicable. If enabled, the column definitions will need
 * to include a checkbox, as this cannot be defined in the
 * grid options alone.
 *
 * @param {Object} component Table component
 */
function rowSelectionOptions(component) {
  if (component.selection === "none") {
    return {};
  }

  return {
    rowSelection: component.selection,
    suppressRowClickSelection: false,
  };
}

export default rowSelectionOptions;
