<template lang="pug">
  .h-full.bg-white.shadow-lg.flex.flex-col(v-bind="$attrs" :class="{ 'overflow-y-auto': !internalTabs.length }")
    .flex-initial.px-8.pt-8.shadow.z-10(:class="{ 'pb-8': !internalTabs.length }")
      .flex.flex-row.overflow-hidden
        .flex-1
          slot(name="header")
            app-header(margin="")
              slot(name="title")

        .flex-initial
          .flex.flex-row.items-center
            app-dropdown.mr-2(v-if="actions.length" :options="actions" @click="$emit('action-clicked', $event)")
              template(#trigger)
                app-icon.cursor-pointer(icon="actions" size="normal")
            app-icon.cursor-pointer(icon="close" @click.native="$emit('close')" size="normal")

      template(v-if="internalTabs.length")
        .flex-initial.flex.flex-row.mt-4
          .tab.flex-1.px-8.py-4.text-center(v-for="tab in internalTabs", :key="tab.name", :class="{ isActive: tab.active }")
            a.text-grey-80.no-underline(@click.prevent="selectTab(tab)") {{ tab.name }}


    .flex-1.overflow-y-auto.z-0
      transition(name="fade" v-if="internalTabs.length")
        template(v-for="tab in internalTabs")
          .tab-content.h-full.overflow-y-auto(v-if="activeSlot === tab.slot" :key="tab.slot")
            slot(:name="tab.slot")

      slot(v-else)

    .p-8.flex-initial(v-if="$slots.footer")
      slot(name="footer")

</template>

<script>
import AppDropdown from "@/components/collections/AppDropdown.vue";
import AppHeader from "@/components/elements/AppHeader.vue";
import AppIcon from "@/components/elements/AppIcon.vue";

export default {
  components: {
    AppDropdown,
    AppHeader,
    AppIcon,
  },
  props: {
    actions: {
      type: Array,
      required: false,
      default: () => [],
    },
    tabs: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      activeSlot: null,
      internalTabs: [],
    };
  },

  mounted() {
    this.processTabs();
  },

  methods: {
    processTabs() {
      this.internalTabs = this.tabs.map(tab => ({ ...tab, active: false }));
      if (this.internalTabs.length) {
        this.internalTabs[0].active = true;
        this.activeSlot = this.internalTabs[0].slot;
      }
    },
    selectTab(selectedTab) {
      this.internalTabs.forEach(tab => {
        tab.active = tab.name === selectedTab.name;
      });
      this.activeSlot = selectedTab.slot;
    },
  },
};
</script>

<style lang="postcss" scoped>
.tab {
  margin-bottom: -2px;

  &.isActive {
    @apply border-b-2;
    @apply border-tribal-aqua;

    a {
      @apply text-tribal-aqua;
    }
  }
}

$duration: 0.15s;

.fade-enter-active,
.fade-leave-active {
  transition: opacity $duration;
}
.fade-enter-active {
  transition-delay: $duration;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
 {
  opacity: 0;
}
</style>
