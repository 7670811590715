<template lang="pug">
  div(:class="containerClasses()" ref="button")
    span.px-4.py-3.border-r.border-diving-sapphire(:class="buttonClasses()" @click="$emit('click')")
      slot

    .py-3.px-2(:class="buttonClasses()" @click="open = !open")
      app-icon(:icon="open ? 'caretUp' : 'caretDown'" colour="white")

    mounting-portal(mount-to="body", append, v-if="open")
      .bg-white.shadow-lg.border.border-grey-20.py-2.z-50(:id="dropdownId" v-click-outside="vcoConfig")
        template(v-for="(option, idx) in options")
          .py-2.px-4(v-if="option.type == 'divider'", :key="idx")
            .h-px.bg-grey-30.w-full

          .py-2.px-4.flex.flex-row.items-center.cursor-pointer(v-else, @click="optionClicked(option)", class="hover:bg-grey-20")
            app-icon.mr-4(:icon="option.icon", v-if="option.icon", :colour="option.iconColour")
            span| {{ option.label }}
 </template>

<script>
import AppIcon from "@/components/elements/AppIcon.vue";
import { createPopper } from "@popperjs/core";
import { random } from "@/components/helpers/string-helpers";

export default {
  components: {
    AppIcon,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dropdownId: this.generateId(),
      open: false,
      popper: null,
      vcoConfig: {
        events: ["contextmenu", "click"],
        handler: this.close,
      },
    };
  },
  computed: {
    popperTarget() {
      return this.$refs["button"];
    },
  },
  methods: {
    close() {
      this.open = false;
    },
    containerClasses() {
      return [
        "cursor-pointer",
        "inline-block",
        "rounded",
        "border",
        "text-white",
        "font-bold",
        "hover:shadow",
        "active:shadow-none",
        "border-tribal-aqua",
        "bg-tribal-aqua",
        "no-underline",
        "hover:no-underline",
        "hover:text-white",
        "inline-flex",
        "flex-row",
        "items-center",
      ];
    },
    async createPopper() {
      await this.$nextTick();

      const dropdown = document.getElementById(this.dropdownId);
      const modifiers = [
        {
          name: "offset",
          options: {
            offset: [0, 0],
          },
        },
      ];

      if (this.flip) {
        modifiers.push({
          name: "flip",
          options: {
            fallbackPlacements: ["top", "right", "left", "bottom"],
          },
        });
      }

      this.popper = createPopper(this.popperTarget, dropdown, {
        modifiers,
      });
    },
    destroyPopper() {
      if (this.popper) {
        this.popper.destroy();
        this.popper = null;
      }
    },
    buttonClasses() {
      return ["hover:bg-diving-sapphire"];
    },
    generateId() {
      const id = random();
      return `dropdown-${id}`;
    },
    optionClicked(opt) {
      this.$emit("click", opt);
      this.open = false;
    },
  },
  watch: {
    open(isOpen) {
      if (isOpen) {
        this.createPopper();
      } else {
        this.destroyPopper();
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.dropdown[data-popper-placement^="top"] > .arrow {
  bottom: -0.5rem;
}

.dropdown[data-popper-placement^="bottom"] > .arrow {
  top: -0.5rem;
}

.dropdown[data-popper-placement^="left"] > .arrow {
  right: -0.5rem;
}

.dropdown[data-popper-placement^="right"] > .arrow {
  left: -0.5rem;
}
</style>
