<template lang="pug">
app-panel
  app-header(size="h1")
    | Panels
    template(#subheader) Group related content

  p Panels are used as a way of grouping content into sections on the main page, as well as in sidebars. This text is contained with an #[code AppPanel] component

  app-tabs.pt-16
    app-tab(name="Details" selected)

      app-header Primary panels

      p Primary panels are used for the main body of a page. The panel this content in is a primary panel

      
      demo-code-container
        template(#default)
          app-panel
            | This is a primary panel

        template(#code)
          pre
            | app-panel
            |   | This is a primary panel



      hr.h-px.border-0.bg-grey-30.my-8

      app-header Secondary panels

      p A secondary panel is used to group a content within a primary panel

      demo-code-container
        template(#default)
          .flex.flex-row.flex-stretch
            app-panel.flex-1(secondary)
              | Secondary panel 1
            
            app-panel.flex-1.ml-8(secondary)
              | Secondary panel 2

        template(#code)
          pre
            | .flex.flex-row.flex-stretch
            |   app-panel.flex-1(secondary)
            |     | Secondary panel 1
            |   
            |   app-panel.flex-1.ml-8(secondary)
            |     | Secondary panel 2


      hr.h-px.border-0.bg-grey-30.my-8

      app-header Nested panels

      p A nested panel is used inside a secondary panel, often in a sidebar to present an inline form

      demo-code-container
        template(#default)
          app-panel(secondary)
            app-header(size="h3") Secondary panel
            app-panel(nested)
              | This is a nested panel

        template(#code)
          pre
            | app-panel(secondary)
            |   app-header(size="h3") Secondary panel
            |   app-panel(nested)
            |     | This is a nested panel
    app-tab(name="Props")
</template>

<script>
import AppHeader from "@/components/elements/AppHeader.vue";
import AppPanel from "@/components/elements/AppPanel.vue";
import AppTab from "@/components/elements/AppTab.vue";
import AppTabs from "@/components/elements/AppTabs.vue";
import DemoCodeContainer from "@/views/demos/components/DemoCodeContainer.vue";

export default {
  components: {
    AppHeader,
    AppPanel,
    AppTab,
    AppTabs,
    DemoCodeContainer,
  },
};
</script>
