<template lang="pug">
  a(href="#", @click.prevent="onClick") {{ params.value }}
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  methods: {
    onClick() {
      if (this.params.onClick) {
        this.params.onClick(this.params.data);
      }
    },
  },
});
</script>
