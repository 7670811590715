<template lang="pug">
  app-icon.cursor-pointer(@click.native="buttonClicked", v-bind="binds")
</template>

<script>
import Vue from "vue";
import AppIcon from "@/components/elements/AppIcon.vue";

export default Vue.extend({
  components: {
    AppIcon,
  },
  methods: {
    buttonClicked() {
      if (this.params.onClick) {
        this.params.onClick(this.params.node);
      }
    },
  },
  computed: {
    binds() {
      return {
        icon: this.params.icon,
        stroke: this.params.stroke,
      };
    },
  },
});
</script>
