<script>
import Vue from "vue";
import AppIcon from "@/components/elements/AppIcon.vue";

export default Vue.extend({
  render(h) {
    const { tableComponent } = this.params.context;

    return h(AppIcon, {
      props: { icon: "columns" },
      staticClass: "cursor-pointer",
      nativeOn: {
        click() {
          tableComponent.openColumnToggle();
        },
      },
    });
  },
});
</script>
