import Dialog from "@/plugins/Dialog";
import Notice from "@/plugins/Notice";
import Flash from "@/plugins/Flash";
import Sidebar from "@/plugins/Sidebar";

export default (Vue, options) => {
  Dialog.install(Vue, options);
  Notice.install(Vue, options);
  Flash.install(Vue, options);
  Sidebar.install(Vue, options);
};
