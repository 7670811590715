<template lang="pug">
app-dropdown-field(
  ref="dropdown"
  :value="value"
  :error="$v.value.$error"
  :show-optional="false"
  :options="options"
  :open-direction="direction"
  v-bind="dropdownProps"
  @input="setValue"
  @close="finishEditing"
)
</template>

<script>
import Vue from "vue";
import CellEditorMixin from "./BaseTableCellEditorMixin";
import AppDropdownField from "@/components/elements/fields/AppDropdownField.vue";

const MAX_HEIGHT = 300;

export default Vue.extend({
  mixins: [CellEditorMixin],
  components: {
    AppDropdownField,
  },
  computed: {
    dropdownProps() {
      return this.params.dropdownProps;
    },
    options() {
      if (typeof this.params.options === "function") {
        return this.params.options(this.params.data);
      }

      return this.params.options;
    },
  },
  data() {
    return {
      direction: "bottom",
    };
  },
  created() {
    const gridEl = this.params.context.tableComponent.$refs.grid.$el;
    const gridBottom = gridEl.getBoundingClientRect().bottom;
    const gridCellTop = this.params.eGridCell.getBoundingClientRect().top;

    if (gridBottom < gridCellTop + MAX_HEIGHT) {
      this.direction = "top";
    } else {
      this.direction = "bottom";
    }
  },
  mounted() {
    this.$refs.dropdown.focus();
  },
  methods: {
    isPopup() {
      return true;
    },
  },
});
</script>
