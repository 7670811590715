<script>
import AppDropdown from "@/components/collections/AppDropdown.vue";

const getContent = (createElement, label) => {
  return createElement(
    "div",
    {
      staticClass: "px-4 py-2",
    },
    label
  );
};

export default {
  functional: true,
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  render(createElement, { slots, props }) {
    const trigger = slots().default;

    return createElement(AppDropdown, {
      props: {
        pointing: true,
        trigger: "hover",
      },
      scopedSlots: {
        trigger: () => trigger,
        content: () => getContent(createElement, props.label),
      },
    });
  },
};
</script>
