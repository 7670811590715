var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-4"},[_c('label',{class:_vm.errorClasses,attrs:{"for":_vm.name}},[_vm._t("label",[_c('span',{class:_vm.labelClasses},[_vm._v(_vm._s(_vm.fieldLabel))])],{"invalid":_vm.hasErrors}),(_vm.description)?_vm._t("caption",[_c('span',{class:_vm.descriptionClasses},[_vm._v(_vm._s(_vm.description))])]):_vm._e()],2),_c('multiselect',_vm._g(_vm._b({ref:"dropdown",class:_vm.errorClasses,attrs:{"label":_vm.labelAttr,"multiple":_vm.multiple,"options":_vm.options,"placeholder":"","select-label":"","deselect-label":""},scopedSlots:_vm._u([{key:"selection",fn:function(scope){return [_vm._t("selection",null,null,scope)]}},{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_vm._t("singleLabel",[(option.icon)?_c('app-icon',{staticClass:"mr-4",attrs:{"icon":option.icon,"colour":_vm.iconColour(option)}}):_vm._e(),_c('div',{staticClass:"inline-block p-1"},[_vm._v(_vm._s(option[_vm.labelAttr]))])],null,option)]}},{key:"tag",fn:function(ref){
var option = ref.option;
var remove = ref.remove;
return [_c('div',{staticClass:"bg-tribal-aqua mb-2 mr-2 inline-block rounded-lg"},[_c('span',{staticClass:"text-white font-bold p-2"},[_vm._v(_vm._s(option[_vm.labelAttr]))]),_c('button',{staticClass:"text-white bg-tribal-aqua p-2 rounded-lg rounded-tl-none rounded-bl-none hover:bg-diving-sapphire",on:{"click":function($event){$event.preventDefault();return remove(option)}}},[_vm._v("X")])])]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_vm._t("option",[_c('div',{staticClass:"flex flex-row justify-start text-grey-80"},[(option.icon)?_c('app-icon',{staticClass:"mr-4",attrs:{"icon":option.icon,"colour":_vm.iconColour(option)}}):_vm._e(),_c('span',[_vm._v(_vm._s(option[_vm.labelAttr]))])],1)],null,option)]}}],null,true),model:{value:(_vm.fieldValue),callback:function ($$v) {_vm.fieldValue=$$v},expression:"fieldValue"}},'multiselect',_vm.binds,false),_vm.listeners)),(_vm.hasErrors)?_vm._t("errors",[_c('div',{staticClass:"font-bold text-right mt-1",class:_vm.errorClasses},[_vm._v(_vm._s(_vm.firstErrorMessage))])],{"errors":_vm.activeErrors,"errorMessages":_vm.activeErrorMessages,"hasErrors":_vm.hasErrors,"firstErrorMessage":_vm.firstErrorMessage}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }