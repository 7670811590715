<template lang="pug" functional>
  .flex-0(v-bind="data.attrs", :class="data.staticClass || ''")
    input.hidden(type="checkbox", :value="value")
    label.flex.items-center.cursor-pointer(@click="$options.changed(props, listeners)", :class="$options.labelClasses(props.error)")

      span.w-6.h-6.inline-block.mr-4.rounded.flex-no-shrink.flex.justify-center.items-center(:class="$options.checkboxClasses(props.value, props.indeterminate, props.error)")
        svg(v-if="props.value", xmlns="http://www.w3.org/2000/svg", width="15.1", height="10.8", viewBox="0 0 15.1 10.8")
          path(d="M6.5,10.8,0,4.3,2.1,2.2,6.5,6.5,13,0l2.1,2.1Z" fill="#fff")

        span.text-white(v-else-if="props.indeterminate") &ndash;

      | {{ props.label }}
</template>

<script>
export default {
  functional: true,
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      require: true,
    },
    indeterminate: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  changed(props, listeners) {
    if (listeners.input) {
      listeners.input(!props.value);
    }
  },

  checkboxClasses(value, indeterminate, error) {
    if (indeterminate || value) {
      return ["bg-tribal-aqua", "border", "border-tribal-aqua"];
    }

    if (error) {
      return ["border", "border-withered-cherry"];
    }

    return ["border", "border-grey-60"];
  },

  labelClasses(error) {
    if (error) {
      return ["text-withered-cherry", "font-bold"];
    } else {
      return ["text-grey-70"];
    }
  },
};
</script>
