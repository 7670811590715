<script>
import AppIcon from "@/components/elements/AppIcon.vue";
import Types, {
  icons,
  bgColours,
  textColours,
  lightIconColours as iconColours,
} from "@/config/types/success-types";
import { mergeData } from "vue-functional-data-merge";

const getTitle = ({ slots, props }) => {
  if (slots().title) {
    return slots().title;
  }

  return `${props.type.charAt(0).toUpperCase()}${props.type.slice(1)}!`;
};
const getContent = (h, context) => {
  const children = [
    h("strong", { staticClass: "flex-initial" }, getTitle(context)),
    h("div", { staticClass: "flex-1" }, context.slots().default),
  ];

  return h("div", { staticClass: "flex flex-col ml-4" }, children);
};

const getIcon = (h, { props }) => {
  return h(AppIcon, {
    staticClass: "flex-initial",
    props: {
      icon: icons[props.type],
      size: "large",
      colour: iconColours[props.type],
    },
  });
};

export default {
  functional: true,
  props: {
    type: {
      type: String,
      required: false,
      default: Types.INFO,
      validator: type => {
        return Object.values(Types).indexOf(type) !== -1;
      },
    },
  },
  render(h, context) {
    return h(
      "div",
      mergeData(context.data, {
        staticClass: `p-4 flex flex-row ${bgColours[context.props.type]} ${
          textColours[context.props.type]
        }`,
      }),
      [getIcon(h, context), getContent(h, context)]
    );
  },
};
</script>
