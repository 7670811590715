<template lang="pug">
  table.mt-8.table-auto
    thead
      tr
        th.text-grey-60.px-4.py-2 Name
        th.text-grey-60.px-4.py-2 Type
        th.text-grey-60.px-4.py-2 Desc
        th.text-grey-60.px-4.py-2 Required
        th.text-grey-60.px-4.py-2 Default

    tbody
      tr(v-for="prop in p", :key="p.name")
        td.border.border-grey-30.px-4.py-2.font-bold {{ prop.name }}
        td.border.border-grey-30.px-4.py-2 {{ prop.type }}
        td.border.border-grey-30.px-4.py-2 {{ prop.desc }}
        td.border.border-grey-30.px-4.py-2 {{ prop.required ? 'Yes' : 'No' }}
        td.border.border-grey-30.px-4.py-2 {{ prop.default }}
</template>

<script>
export default {
  props: {
    p: {
      type: Array,
      required: true,
    },
  },
};
</script>
