<template lang="pug">
  app-panel
    .mb-4
      app-message Just thought I'd let you know about something.
      app-message.mt-4(type="success") Oh goody, something worked!!
      app-message.mt-4(type="error") Uh oh, something really super bad went wrong.
      app-message.mt-4(type="warning") I am a warning. I warn you about stuff!
</template>

<script>
import Types from "@/config/types/label-types";
import AppMessage from "@/components/elements/AppMessage.vue";
import AppPanel from "@/components/elements/AppPanel.vue";

export default {
  components: {
    AppMessage,
    AppPanel,
  },
  computed: {
    types() {
      return Types;
    },
  },
};
</script>
