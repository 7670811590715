<template lang="pug">
  .options
    .mb-4(v-for="option in options" :key="option.value" @click="fieldValue = option.value")
      input.hidden(type="radio" :id="option.value" :value="option.value" v-model="fieldValue")
      label.flex.items-center.cursor-pointer.text-grey-70.outline-none(:for="option.value"
                                                                                tabindex="0" @keyup.space.enter.prevent="fieldValue = option.value")
        span.w-8.h-8.inline-block.mr-4.rounded-full.border.flex-no-shrink
        | {{ option.label }}

      .mt-4.ml-12(v-if="option.value === fieldValue")
        slot(:name="option.slot")
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Boolean],
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  computed: {
    fieldValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="postcss" scoped>
input[type="radio"] {
  & + label span {
    transition: background 0.2s, transform 0.2s;
    @apply border-grey-60;
  }

  &:checked + label span {
    @apply bg-tribal-aqua;
    @apply border-tribal-aqua;
    box-shadow: 0px 0px 0px 2px var(--white) inset;
  }
}
</style>
