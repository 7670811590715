<template lang="pug">
  .w-full
    app-panel
      app-header Tables

    .w-full(style="height: 600px;")
      demo-table
        template(#action-bar)
          | Hello
        template(#buttons)
          app-button Add folder
          app-button.ml-4(primary) Add document

    app-panel
      app-header Cell editors
    
      | BaseTableDropdownCellEditor

    app-panel
      app-header Cell renderer
</template>

<script>
import AppButton from "@/components/elements/AppButton.vue";
import AppHeader from "@/components/elements/AppHeader.vue";
import AppPanel from "@/components/elements/AppPanel.vue";
import DemoTable from "./DemoTable.vue";

export default {
  components: {
    AppButton,
    AppHeader,
    AppPanel,
    DemoTable,
  },
};
</script>
