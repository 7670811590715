<template lang="pug">
  .other
    app-panel
      app-header Dialogs

      app-header(size="h4") Props
      app-header(size="h4") Slots

      table
        thead
          th Name
          th Description

        tbody
          tr
            td Title
            td Set a single title

          tr
            td Header
            td Custom content for the header section

    app-panel
      .flex.flex-row
        app-team-member.flex-initial(:user="user")
        app-avatar.flex-initial.ml-8(:user="user")
        app-avatar.flex-initial.ml-8

      .flex.flex-row
        .flex-initial.text-tribal-aqua
          app-loading-spinner.testing

          .text-lg
            app-loading-spinner

        .flex-initial.text-split-crimson
          app-loading-spinner

      .flex.flex-row
        a(href="#", @click.prevent="confirm") Confirm action
        a.ml-4(href="#", @click.prevent="confirmDanger") Confirm deletion
        a.ml-4(href="#", @click.prevent="confirmCustom") Custom content
        a.ml-4(href="#", @click.prevent="modalShowing = true", @close="modalShowing = false") Custom modal

        app-dialog(v-model="modalShowing")
          template(#header)
            | Hi, I'm a title

          | HELLO THERE!
          app-button(@click="modalShowing = false") Close me

          template(#footer)
            | I am a footer

      template(#footer)
        | I'm a footer

    app-panel
      .flex.flex-row
        span.inline-block.strong.mr-2 Slider
      .flex.flex-row
        .block(class="w-1/3")
          app-slider-field.w-full(label="Price" :min="0" :max="1000" :lazy="true" :values="projectsPrices"
              unit="$" :unit-first="true" element-name="project" description="Filter projects by price range" v-model="projectPrice")

    app-panel
      app-header Tooltip

      app-tooltip(label="HELLO")
        app-icon(icon="activity")
      
      p.mt-8 Has a `label` prop to specify the text to display. The default scope will be the trigger

      pre.mt-8
        | app-tooltip(label="Hello world")
        |   app-icon(icon="activity")

    app-panel
      app-header Nested panels

      app-panel(secondary)
        | Hello
</template>

<script>
import Dialog from "@/plugins/Dialog";
import DemoDialog from "./components/DemoDialog.vue";
import AppAvatar from "@/components/elements/AppAvatar.vue";
import AppLoadingSpinner from "@/components/elements/AppLoadingSpinner.vue";
import AppButton from "@/components/elements/AppButton.vue";
import AppDialog from "@/components/elements/dialog/AppDialog.vue";
import AppHeader from "@/components/elements/AppHeader.vue";
import AppIcon from "@/components/elements/AppIcon.vue";
import AppPanel from "@/components/elements/AppPanel.vue";
import AppTooltip from "@/components/elements/AppTooltip.vue";
import AppTeamMember from "@/components/elements/AppTeamMember.vue";
import AppSliderField from "@/components/elements/fields/AppSliderField.vue";

export default {
  components: {
    AppAvatar,
    AppButton,
    AppDialog,
    AppHeader,
    AppIcon,
    AppLoadingSpinner,
    AppPanel,
    AppTeamMember,
    AppTooltip,
    AppSliderField,
  },
  data() {
    return {
      user: {
        name: "Adrian Hooper",
        roleName: "Administrator",
        avatar: {
          url: "https://via.placeholder.com/200",
        },
      },
      modalShowing: false,
      projectPrice: [20, 500],
      projectsPrices: [10, 20, 5, 50, 55, 36, 300, 200, 500, 520, 510, 40, 980],
    };
  },
  methods: {
    testing() {
      alert("hi");
    },
    confirm() {
      this.$dialog
        .confirm({
          message: "This is going to rock, should we proceed?",
          confirmLabel: "Lets do this!",
          cancelLabel: "Nope, I'm too scared",
        })
        .onOk(({ api }) => {
          api.hide();
        });
    },
    confirmDanger() {
      Dialog.confirm({
        message:
          "If you proceed, this cannot be undone. A nuke WILL be launched!",
        danger: true,
        confirmLabel: "Launch away!",
        cancelLabel: "Maybe not",
      })
        .onOk(({ api }) => {
          alert("Ok clicked");
          api.hide();
        })
        .onCancel(() => {
          alert("Cancel clicked");
        });
    },
    confirmCustom() {
      this.$dialog.show(DemoDialog);
    },
  },
};
</script>
