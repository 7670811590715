<template lang="pug">
  .mb-4
    label(:for="name" :class="errorClasses")
      slot(name="label" :invalid="hasErrors")
        span(:class="labelClasses") {{ fieldLabel }}
      slot(name="caption" v-if="hasDescription")
        span(:class="descriptionClasses") {{ description || `Date format "${dateFormat}"` }}

    slot
      app-date-picker.h-12(v-model="fieldValue" :disable="disable" :format="dateFormat" v-bind="$attrs" :class="[fieldClasses, errorClasses]" v-on="$listeners" ref="datePicker")

    slot(name="errors" v-if="hasErrors" :errors="activeErrors" :errorMessages="activeErrorMessages" :has-errors="hasErrors" :first-error-message="firstErrorMessage")
      div.font-bold(:class="errorClasses") {{ firstErrorMessage }}
</template>

<script>
import FieldMixin from "@/mixins/Field";
import AppDatePicker from "@/components/elements/AppDatePicker";

export default {
  components: { AppDatePicker },

  mixins: [FieldMixin],

  props: {
    value: {
      type: [String, Date],
      default: () => new Date(),
    },

    format: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    disable: {
      type: [Array, Date, Function],
      default: () => [],
    },

    fieldClasses: {
      type: String,
      default: "",
    },

    showCaption: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    dateFormat() {
      return this.format || this.$store.state.dateFormat;
    },
    // Override the mixin version
    hasDescription() {
      return !!this.format || !!this.description;
    },
  },

  methods: {
    focus() {
      this.$refs.datePicker.focus();
    },
  },
};
</script>
