<script>
import Vue from "vue";
import { format, parse, parseISO } from "date-fns";

export default Vue.extend({
  computed: {
    formattedDate() {
      let date = this.date;
      let formatString = this.$store.state.dateFormats.dateFns;

      if (this.params.time) {
        formatString = `${formatString} HH:mm`;
      }

      if (!date) {
        return "";
      }

      return format(date, formatString);
    },
    isOverdue() {
      const date = this.date;
      const current = new Date();

      return date < current;
    },
    date() {
      const date = this.params.value;

      if (typeof date === "string") {
        if (this.params.time) {
          return parseISO(date);
        } else {
          return parse(date, "yyyy/MM/dd", new Date());
        }
      }

      return date;
    },
  },
  render(h) {
    let classes = "";
    if (this.params.highlightOverdue && this.isOverdue) {
      classes += "text-withered-cherry font-bold";
    }

    return h("span", { staticClass: classes }, this.formattedDate);
  },
});
</script>
