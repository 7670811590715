<script>
import { MountingPortal } from "portal-vue";

export default {
  props: {
    doNotMount: {
      type: Boolean,
      require: false,
      default: false,
    },
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  render(h) {
    const classes = "sidebar fixed top-0 right-0 bottom-0 z-40";
    const sidebar = h("div", { staticClass: classes }, this.$slots.default);

    if (this.doNotMount) {
      return sidebar;
    }

    const transition = h(
      "transition",
      { props: { name: "slide", appear: true }, on: { ...this.$listeners } },
      this.open ? [sidebar] : []
    );
    return h(MountingPortal, { props: { mountTo: "body", append: true } }, [
      transition,
    ]);
  },
  watch: {
    value: {
      handler(open, oldValue) {
        this.open = open;
        if (!open && oldValue !== undefined) {
          setTimeout(() => {
            this.$emit("hide");
          }, 250);
        }
      },
      immediate: true,
    },
  },
  methods: {
    hide() {
      this.open = false;
      setTimeout(() => {
        this.$emit("hide");
      }, 250);
    },
    show() {
      this.open = true;
    },
  },
};
</script>

<style lang="postcss" scoped>
$width: 500px;

.sidebar {
  top: 4rem;
  width: $width;
}

.slide-enter-active,
.slide-leave-active {
  transition: right 0.25s;
}

.slide-enter,
.slide-leave-to {
  right: -$width;
}
</style>
