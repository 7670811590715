<template lang="pug">
  app-text-input.w-full(
    ref="input"
    v-model="value"
    :error="$v.value.$error"
    @keyup.esc="cancelEdit"
    @keyup.enter="saveEdit"
    @input="$v.value.$touch()")
</template>

<script>
import Vue from "vue";
import CellEditorMixin from "./BaseTableCellEditorMixin";
import AppTextInput from "@/components/elements/AppTextInput.vue";

export default Vue.extend({
  mixins: [CellEditorMixin],
  components: {
    AppTextInput,
  },
  mounted() {
    this.$nextTick(() => {
      this.$el.focus();
      this.$el.select();
    });
    // console.log(this, this.$refs);
    // this.$nextTick(() => {
    //   console.log(this, this.$refs);
    //   this.$refs.input.$el.focus();
    //   this.$refs.input.$el.select();
    // });
  },
});
</script>
