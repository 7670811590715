<template lang="pug">
  .w-full.bg-grey-30.h-1
    .h-full.transition-width.ease-in-out.duration-500(:style="{ width: width }", :class="progressColour")
</template>

<script>
import Labels from "@/config/types/label-types";

const COLOURS = {
  [Labels.RED]: "bg-withered-cherry",
  [Labels.BLUE]: "bg-tribal-aqua",
  [Labels.GREEN]: "bg-subatomic-sky",
  [Labels.YELLOW]: "bg-laughing-amber",
  [Labels.GREY]: "bg-grey-60",
};

export default {
  props: {
    min: {
      type: Number,
      required: false,
      default: 0,
    },
    max: {
      type: Number,
      required: false,
      default: 100,
    },
    progress: {
      type: Number,
      required: true,
      default: 0,
    },

    /**
     *  Specify the colour, or colours to use for the
     *  progress bar. If a single colour is specified
     *  it will be used regardless of the percentage.
     *  If multiple colours are used, then the colour
     *  will change depending on the percentage specified.
     */
    colour: {
      type: [String, Object],
      required: false,
      default: Labels.BLUE,
    },
  },
  computed: {
    progressColour() {
      if (typeof this.colour === "string") {
        return COLOURS[this.colour];
      }

      const progress = this.progress;
      const threshold = this.thresholds.find(t => progress <= t);

      return threshold ? COLOURS[this.colour[threshold]] : COLOURS[Labels.BLUE];
    },
    thresholds() {
      if (typeof this.colour === "string") {
        return [];
      }

      return Object.keys(this.colour).sort((a, b) => a - b);
    },
    width() {
      const percentage = (this.progress / this.max) * 100.0;
      return `${percentage}%`;
    },
  },
};
</script>
