<template lang="pug">
  filter-panel(:title="title" :description="description" :enabled="isEnabled" @enable="enableFilter" @disable="disableFilter")
    radio-buttons(:options="options" :value="selected" @input="selectionChanged")
</template>

<script>
import FilterPanel from "@/components/collections/filters/AppFilterSidebarFilterPanel.vue";
import FilterPanelMixin from "@/components/collections/filters/AppFilterSidebarFilterPanelMixin";
import RadioButtons from "@/components/collections/filters/AppFilterSidebarFilterRadioButtons.vue";

export default {
  name: "SingleChoiceFilter",
  mixins: [FilterPanelMixin],
  components: {
    FilterPanel,
    RadioButtons,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    let filterValues = {};
    this.options.forEach(opt => {
      filterValues[opt.value] = opt;
    });

    return {
      filterValues,
      selected: this.options[0].value,
    };
  },
  mounted() {
    if (this.value && this.value.field) {
      const appliedFilter = this.value[this.value.field];
      this.selected = appliedFilter;
    }
  },
  computed: {
    appliedFilters() {
      const value = this.filterValues[this.selected];
      return {
        field: value.field,
        [value.field]: value.filterValue,
      };
    },
  },
};
</script>
