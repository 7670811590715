<template lang="pug">
app-sidebar(@close="$emit('close')")
  template(#header)
    app-header(margin="")
      | {{ title }}
      template(#subheader) {{ filtersAppliedLabel }}

  .p-8
    component.mb-4(
      v-for="(filter, name) in filters"
      :key="name"
      :is="components[filter.type]"
      v-model="appliedFilters[name]"
      :enabled="isEnabled(name)",
      v-bind="filter")

  template(#footer)
    .flex.flex-col
      app-button.text-center(primary @click="applyFilters") Apply filters
      a.mt-4(href="#" @click.prevent="clearFilters") Clear all filters
</template>

<script>
import AppButton from "@/components/elements/AppButton.vue";
import AppHeader from "@/components/elements/AppHeader.vue";
import AppSidebar from "@/components/elements/AppSidebar.vue";

import BooleanFilter from "@/components/collections/filters/AppFilterSidebarBooleanFilterPanel.vue";
import DateFilter from "@/components/collections/filters/AppFilterSidebarDateFilterPanel.vue";
import OptionFilter from "@/components/collections/filters/AppFilterSidebarOptionFilterPanel.vue";
import SingleChoiceFilter from "@/components/collections/filters/AppFilterSidebarSingleChoiceFilterPanel.vue";
import TextFilter from "@/components/collections/filters/AppFilterSidebarTextFilterPanel.vue";

export default {
  components: {
    AppButton,
    AppHeader,
    AppSidebar,

    BooleanFilter,
    DateFilter,
    OptionFilter,
    SingleChoiceFilter,
    TextFilter,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  beforeMount() {
    // Build up a list of applied filters
    const filters = this.deepCopyAppliedFilters(this.value);
    Object.keys(this.filters).forEach(name => {
      this.$set(this.appliedFilters, name, filters[name] || {});
    });
  },
  data() {
    return {
      appliedFilters: {},
      components: {
        boolean: BooleanFilter,
        date: DateFilter,
        text: TextFilter,
        singleChoice: SingleChoiceFilter,
        option: OptionFilter,
      },
    };
  },
  methods: {
    applyFilters() {
      this.emitFilters();
    },
    clearFilters() {
      Object.keys(this.appliedFilters).forEach(name => {
        this.$set(this.appliedFilters, name, {});
      });
      this.emitFilters();
    },
    deepCopyAppliedFilters(value) {
      return JSON.parse(JSON.stringify(value));
    },
    emitFilters() {
      const clonedFilters = JSON.parse(JSON.stringify(this.appliedFilters));
      this.$emit("input", clonedFilters);
    },
    isEnabled(name) {
      return Object.keys(this.appliedFilters[name]).length > 0;
    },
  },
  computed: {
    enabledCount() {
      return Object.values(this.appliedFilters).reduce(
        (total, filters) =>
          (total += Object.keys(filters).length === 0 ? 0 : 1),
        0
      );
    },
    filtersAppliedLabel() {
      const count = this.enabledCount;
      let filter = "filter";
      if (count !== 1) filter += "s";

      return `${count} ${filter} enabled`;
    },
  },
  watch: {
    value(value) {
      const newFilters = this.deepCopyAppliedFilters(value);
      Object.keys(this.filters).forEach(name => {
        this.$set(this.appliedFilters, name, newFilters[name] || []);
      });
    },
  },
};
</script>
