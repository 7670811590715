<template lang="pug">
  filter-panel(:title="title" :description="description" :enabled="isEnabled" @enable="enableFilter" @disable="disableFilter")
    radio-buttons(:options="options" :value="selected" @input="selectionChanged")
      template(#between)
        .flex.flex-row
          app-date-picker.mr-4(:value="datePickerStart" @input="startChanged")
          app-date-picker(:value="datePickerEnd" @input="endChanged")
</template>

<script>
import AppDatePicker from "@/components/elements/AppDatePicker.vue";
import FilterPanel from "@/components/collections/filters/AppFilterSidebarFilterPanel.vue";
import FilterPanelMixin from "@/components/collections/filters/AppFilterSidebarFilterPanelMixin";
import {
  format,
  startOfWeek,
  endOfWeek,
  nextMonday,
  startOfMonth,
  endOfMonth,
  sub,
  parse,
} from "date-fns";
import RadioButtons from "@/components/collections/filters/AppFilterSidebarFilterRadioButtons.vue";

const DATE_OPTS = { weekStartsOn: 1 };
const DATE_FORMAT = "yyyy-MM-dd";

export default {
  name: "DateFilter",
  components: {
    AppDatePicker,
    FilterPanel,
    RadioButtons,
  },
  mixins: [FilterPanelMixin],
  data() {
    return {
      options: [
        { label: "Today", value: "today" },
        { label: "This week", value: "thisWeek" },
        { label: "Last week", value: "lastWeek" },
        { label: "Next week", value: "nextWeek" },
        { label: "This month", value: "thisMonth" },
        { label: "Last month", value: "lastMonth" },
        { label: "Between", value: "between", slot: "between" },
      ],
      selected: "today",
      startDate: format(new Date(), DATE_FORMAT),
      endDate: format(new Date(), DATE_FORMAT),
      filterType: "range",
      today: new Date(),
    };
  },
  mounted() {
    if (this.value && this.value[this.field]) {
      const name = this.value.filterName;
      const now = new Date();
      const value = this.value[this.field];
      this.selected = name;

      if (name === "today") {
        this.filterType = "single";
        this.startDate = parse(value, DATE_FORMAT, now);
      } else {
        this.filterType = "range";
        this.startDate = parse(value.gte, DATE_FORMAT, now);
        this.endDate = parse(value.lte, DATE_FORMAT, now);
      }
    }
  },
  methods: {
    startChanged(newDate) {
      this.startDate = this.formatDate(new Date(newDate));
      this.$emit("input", this.appliedFilters);
    },
    endChanged(newDate) {
      this.endDate = this.formatDate(new Date(newDate));
      this.$emit("input", this.appliedFilters);
    },
    selectionChanged(newSelection) {
      this.selected = newSelection;
      this[`${newSelection}Selected`]();
      this.$emit("input", this.appliedFilters);
    },

    todaySelected() {
      this.startDate = this.formatDate(this.today);
      this.filterType = "single";
    },

    thisWeekSelected() {
      this.startDate = this.formatDate(startOfWeek(this.today, DATE_OPTS));
      this.endDate = this.formatDate(endOfWeek(this.today, DATE_OPTS));
      this.filterType = "range";
    },

    lastWeekSelected() {
      const monday = sub(startOfWeek(this.today, DATE_OPTS), { weeks: 1 });
      this.startDate = this.formatDate(monday);
      this.endDate = this.formatDate(endOfWeek(monday, DATE_OPTS));
      this.filterType = "range";
    },

    nextWeekSelected() {
      const monday = nextMonday(this.today);
      this.startDate = this.formatDate(monday);
      this.endDate = this.formatDate(endOfWeek(monday, DATE_OPTS));
      this.filterType = "range";
    },

    thisMonthSelected() {
      this.startDate = this.formatDate(startOfMonth(this.today));
      this.endDate = this.formatDate(endOfMonth(this.today));
      this.filterType = "range";
    },

    lastMonthSelected() {
      const lastMonth = sub(startOfMonth(this.today), { days: 1 });
      this.startDate = this.formatDate(startOfMonth(lastMonth));
      this.endDate = this.formatDate(endOfMonth(lastMonth));
      this.filterType = "range";
    },

    betweenSelected() {
      this.filterType = "range";
    },

    formatDate(date) {
      return format(date, "yyyy-MM-dd");
    },
  },
  computed: {
    appliedFilters() {
      let conditions;

      if (this.filterType === "single") {
        conditions = this.startDate;
      } else {
        conditions = {
          gte: this.startDate,
          lte: this.endDate,
        };
      }

      return {
        filterName: this.selected,
        [this.field]: conditions,
      };
    },
    datePickerStart() {
      return new Date(this.startDate);
    },
    datePickerEnd() {
      return new Date(this.endDate);
    },
  },
};
</script>
