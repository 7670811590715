<template lang="pug">
  .flex.flex-col
    .flex-initial.flex.flex-row.border-b.border-grey-30
      .tab.flex-1.px-8.py-4.text-center(v-for="tab in tabs", :key="tab.name", :class="{ isActive: tab.isActive }")
        a.text-grey-80.no-underline(@click.prevent="selectTab(tab)") {{ tab.name }}

    .flex-1.flex.flex-col.flex-shrink.overflow-auto.mt-8
      slot
</template>

<script>
export default {
  data() {
    return {
      tabs: [],
    };
  },
  created() {
    this.tabs = this.$children;
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach(tab => {
        tab.isActive = tab.name === selectedTab.name;
      });
    },
  },
};
</script>

<style lang="postcss" scoped>
.tab {
  margin-bottom: -2px;

  &.isActive {
    @apply border-b-2;
    @apply border-tribal-aqua;

    a {
      @apply text-tribal-aqua;
    }
  }
}
</style>
