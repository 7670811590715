/**
 * Provides a mapping between a file extension, and the file icon
 * extension used by the AppFileIcon component
 */
export default {
  // Images
  bmp: "image",
  gif: "image",
  jpg: "image",
  jpeg: "image",
  png: "image",
  tif: "image",

  // Documents
  csv: "csv",
  doc: "word",
  docx: "word",
  xls: "excel",
  xlsx: "excel",
  pdf: "pdf",
  ppt: "powerpoint",
  pptx: "powerpoint",
  rtf: "text",
  txt: "text",

  // Media
  mov: "video",
  mp4: "video",
  mpg: "video",
  mpeg: "video",

  // Compressed files
  zip: "zip",
  rar: "rar",

  // Other
  folder: "folder",

  default: "default",
};
