<script>
import AppIcon from "@/components/elements/AppIcon.vue";
import Labels from "@/config/types/label-types";
import { mergeData } from "vue-functional-data-merge";
import { bgColours, textColours } from "@/config/labelColours";

const noop = () => {};

export default {
  functional: true,
  props: {
    colour: {
      type: String,
      required: false,
      default: Labels.GREY,
      validator: colour => {
        return Object.values(Labels).indexOf(colour) !== -1;
      },
    },
    removable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  render(h, { slots, props, data, listeners }) {
    let content = [slots().default];
    const classes = [
      "px-2",
      "py-1",
      "text-base",
      "inline-block",
      "rounded-md",
      "leading-none",
      "whitespace-no-wrap",
      bgColours[props.colour],
      textColours[props.colour],
    ];

    if (props.removable) {
      const removeIcon = h(AppIcon, {
        staticClass: "ml-4 cursor-pointer",
        props: { icon: "error", colour: "inherit" },
        nativeOn: {
          click: listeners["remove-click"] || noop,
        },
      });
      content.push(removeIcon);
    }

    return h("div", mergeData(data, { class: classes }), content);
  },
};
</script>
