<script>
import { mergeData } from "vue-functional-data-merge";

export default {
  functional: true,
  props: {
    /**
     * Whether or not the panel should include
     * the default padding.
     */
    compact: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    secondary: {
      type: Boolean,
      required: false,
      default: false,
    },
    nested: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  render(h, { data, props, slots }) {
    let classes = ["relative", "rounded-lg", "border"];
    let children = [];
    if (props.secondary) {
      classes.push("bg-grey-10", "p-4", "border-grey-30");
    } else if (props.nested) {
      classes.push("bg-white", "p-4", "border", "border-tribal-aqua");
    } else {
      classes.push("bg-white", "mb-4", "border-grey-30");
    }

    if (!props.secondary && !props.nested && !props.compact) {
      classes.push("p-8");
    }

    if (slots().header) {
      children.push(h("div", slots().header));
    }

    if (!props.loading) {
      children.push(slots().default);

      if (slots().footer) {
        children.push(
          h(
            "div",
            { staticClass: "border-t border-grey-40 pt-4 mt-8 -mb-4" },
            slots().footer
          )
        );
      }
    }
    return h("div", mergeData(data, { class: classes }), children);
  },
};
</script>
