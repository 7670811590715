<script>
import Vue from "vue";
import AppIcon from "@/components/elements/AppIcon.vue";

export default Vue.extend({
  components: {
    AppIcon,
  },
  data() {
    return {
      priorities: {
        urgent: {
          label: "Urgent",
          icon: "warning",
          iconColour: "red",
          value: "URGENT",
        },
        high: {
          label: "High",
          icon: "warning",
          iconColour: "yellow",
          value: "HIGH",
        },
        normal: {
          label: "Normal",
          icon: "normalPriority",
          iconColour: "blue",
          value: "NORMAL",
        },
        low: {
          label: "Low",
          icon: "lowPriority",
          iconColour: "grey",
          value: "LOW",
        },
      },
    };
  },
  render(h) {
    const priority = this.params.value.toLowerCase();
    const priorityDetails = this.priorities[priority];

    const icon = h(AppIcon, {
      props: { icon: priorityDetails.icon, colour: priorityDetails.iconColour },
      staticClass: "mr-2",
    });
    const text = h("span", priorityDetails.label);

    return h("div", { staticClass: "flex flex-row items-center" }, [
      icon,
      text,
    ]);
  },
});
</script>
