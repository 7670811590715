<template lang="pug">
app-panel(secondary)
  .flex.flex-row
    app-header.flex-1(size="h3" margin="")
      | {{ title }}
      template(#subheader) {{ description }}

    a(v-if="!enabled" href="#" @click.prevent="$emit('enable')") Add
 
  .mt-8(v-if="enabled")
    slot

    a(href="#" @click.prevent="$emit('disable')") Clear filter
</template>

<script>
import AppHeader from "@/components/elements/AppHeader.vue";
import AppPanel from "@/components/elements/AppPanel.vue";

export default {
  components: { AppHeader, AppPanel },
  props: {
    title: { type: String, required: true },
    description: { type: String, required: true },
    enabled: { type: Boolean, required: false, default: false },
  },
};
</script>
