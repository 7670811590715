<template lang="pug">
.flex.flex-col
  .rows
    .flex.flex-row.row.items-center(v-for="item in listItems")
      .flex-1.item.border.border-grey-50.overflow-hidden.flex.border-t-0
        app-dropdown.flex-0(class="w-1/3" :options="availableCategories(item)" @click="categorySelected(item, $event)")
          template(#trigger)
            .bg-grey-20.px-4.py-2.border-r.border-grey-30.cursor-pointer.h-12.w-full
              .h-full.flex.flex-row.items-center
                span.flex-1.font-bold {{ category(item) }}
                app-icon.ml-4(icon="caretDown")

        input.flex-1.px-4.py-2.outline-none.h-12(v-model="item.value")
      .flex-0.ml-4
        app-icon.cursor-pointer(colour="red" icon="delete" @click.native="removeItem(item)")

  .flex.flex-row.mt-4
    app-button(slim primary @click="addItem") Add {{ resourceTypeLabel }}
    app-button.ml-4(slim @click="done") Done

  a.mt-4(href="#" @click.prevent="$emit('cancel')") Cancel
</template>

<script>
import AppButton from "@/components/elements/AppButton.vue";
import AppDropdown from "@/components/collections/AppDropdown.vue";
import AppIcon from "@/components/elements/AppIcon.vue";

export default {
  name: "CategorisedListEditor",
  components: {
    AppButton,
    AppDropdown,
    AppIcon,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    resourceTypeLabel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      listItems: this.value.map(li => ({ ...li })),
    };
  },
  mounted() {
    if (!this.listItems.length) {
      // Load an empty item
      this.listItems.push(this.createEmptyItem());
    }
  },
  computed: {
    categoriesRemaining() {
      const usedCategories = this.listItems.filter(item => !!item.category);
      return usedCategories.length !== this.categories.length;
    },
  },
  methods: {
    addItem() {
      if (!this.categoriesRemaining) return;
      if (
        this.listItems.length &&
        !this.listItems[this.listItems.length - 1].category
      )
        return;

      this.listItems.push(this.createEmptyItem());
    },
    availableCategories(item) {
      const usedItems = this.listItems.map(item => item.category);
      return this.categories.filter(
        cat =>
          !usedItems.find(used => used?.value === cat.value) &&
          cat.value !== item.value
      );
    },
    categorySelected(item, category) {
      item.category = category;
    },
    category(item) {
      if (!item.category) {
        return "Select";
      }

      return item.category.label;
    },
    createEmptyItem() {
      return {
        category: null,
        value: null,
      };
    },
    done() {
      const validItems = this.listItems
        .filter(item => !!item.category)
        .map(item => ({
          value: item.value,
          category: {
            label: item.category.label,
            value: item.category.value,
          },
        }));
      this.$emit("input", validItems);
    },
    removeItem(item) {
      const idx = this.listItems.findIndex(listItem => listItem === item);
      this.listItems.splice(idx, 1);

      if (!this.listItems.length) {
        this.listItems.push(this.createEmptyItem());
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.row:first-child .item {
  @apply border-t;
  @apply rounded-t-lg;
}

.row:last-child .item {
  @apply rounded-b-lg;
}
</style>
