<template lang="pug">
  app-panel
    app-progress.mb-4(:progress="10")
    app-progress.mb-4(:progress="25")
    app-progress.mb-4(:progress="50")
    app-progress.mb-4(:progress="75")
    app-progress.mb-4(:progress="100")

    app-progress.mb-4(:progress="progress")

    p.mt-8.mb-4 Progress bars can have different colours

    app-progress.mb-4(:progress="progress", colour="green", :max="max")
    app-progress.mb-4(:progress="progress", colour="red", :max="max")
    app-progress.mb-4(:progress="progress", colour="yellow", :max="max")
    app-progress.mb-4(:progress="progress", colour="blue", :max="max")
    app-progress.mb-4(:progress="progress", colour="grey", :max="max")

    p.mt-8.mb-4 They can also have changing colours based on the percentage

    app-progress.mb-4(:progress="progress", :colour="colours", :max="max")
</template>

<script>
import AppPanel from "@/components/elements/AppPanel.vue";
import AppProgress from "@/components/elements/AppProgress.vue";

export default {
  components: {
    AppPanel,
    AppProgress,
  },
  data() {
    return {
      timer: null,
      progress: 0,
      max: 44,
      colours: {
        12: "green",
        35: "yellow",
        44: "red",
      },
      //colours: {
      //  25: "green",
      //  50: "blue",
      //  75: "yellow",
      //  100: "red",
      //},
    };
  },
  created() {
    this.timer = setInterval(this.updateProgress, 500);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    updateProgress() {
      const newProgress = this.progress + 1;

      if (newProgress > this.max) {
        this.progress = 0;
      } else {
        this.progress = newProgress;
      }
    },
  },
};
</script>
