<template lang="pug">
  app-panel
    app-header(size="h1")
      | Split view
      template(#subheader) Display a menu and content side by side

    app-tabs
      app-tab(name="Details" selected)
        app-header Introduction

        p
          | The #[code AppSplitView] component allows you to present a panel similar to the #[code AppPanel], however it will
          | present a menu on the left side, and a blank space for content on the right.


        hr.h-px.border-0.bg-grey-30.my-8

        demo-code-container
          app-split-view(:menu-options="menuOptions" @menu-item-added="newItemAdded" @menu-item-deleted="menuItemDeleted" @menu-item-renamed="menuItemRenamed")
            template(#all)
              .p-8
                app-header
                  | All contacts
                  template(#subheader) I am the content for the `all contacts` option

            template(#customers)
              .p-8
                app-header
                  | Customers
                  template(#subheader) I am the content for the `customers` option


            template(#suppliers)
              .p-8
                app-header
                  | Suppliers
                  template(#subheader) I am the content for the `suppliers` option


            template(#default)
              .p-8
                app-header
                  | Fallback content
                  template(#subheader) When no slot is specified, I am displayed

          template(#code)
            pre
              | app-split-view(:menu-options="menuOptions" @menu-item-added="newItemAdded" @menu-item-deleted="menuItemDeleted" @menu-item-renamed="menuItemRenamed")
              |   template(#all)
              |     .p-8
              |       app-header
              |         | All contacts
              |         template(#subheader) I am the content for the `all contacts` option

              |   template(#customers)
              |     .p-8
              |       app-header
              |         | Customers
              |         template(#subheader) I am the content for the `customers` option

              |
              |   template(#suppliers)
              |     .p-8
              |       app-header
              |         | Suppliers
              |         template(#subheader) I am the content for the `suppliers` option


              |   template(#default)
              |     .p-8
              |       app-header
              |         | Fallback content
              |         template(#subheader) When no slot is specified, I am displayed

      app-tab(name="Usage")

        app-header Usage

        p The #[code AppSplitView] component requires two key elements in order to function

        ul.list-disc.mt-8.ml-4
          li Menu items
          li Content


        hr.h-px.border-0.bg-grey-30.my-8

        app-header Menu items

        p
          | The left side of the #[code AppSplitView] component presents a menu. This menu is generated based on the array of items you set for the
          | #[code menuOptions] prop. This prop expect an array of objects, with the following properties

        table.mt-8
          tr
            th.text-grey-60.px-4.py-2 Property
            th.text-grey-60.px-4.py-2 Description
            th.text-grey-60.px-4.py-2 Required

          tr
            td.border.border-grey-30.px-4.py-2.font-bold label
            td.border.border-grey-30.px-4.py-2 The text that will be displayed for the menu item
            td.border.border-grey-30.px-4.py-2 yes

          tr
            td.border.border-grey-30.px-4.py-2.font-bold icon
            td.border.border-grey-30.px-4.py-2 Display an icon next to the text
            td.border.border-grey-30.px-4.py-2 no

          tr
            td.border.border-grey-30.px-4.py-2.font-bold children
            td.border.border-grey-30.px-4.py-2 A list of sub-items. This will display these items in a separated group. You must set a #[code name] for this item
            td.border.border-grey-30.px-4.py-2 no

          tr
            td.border.border-grey-30.px-4.py-2.font-bold name
            td.border.border-grey-30.px-4.py-2 A name for a group of items. Required if #[code children] has been set
            td.border.border-grey-30.px-4.py-2 conditional

          tr
            td.border.border-grey-30.px-4.py-2.font-bold actions
            td.border.border-grey-30.px-4.py-2 A list of dropdown actions. If supplied, an actions menu will appear next to the menu item. See #[code AppDropdown] for more details
            td.border.border-grey-30.px-4.py-2 no

          tr
            td.border.border-grey-30.px-4.py-2.font-bold childActions
            td.border.border-grey-30.px-4.py-2 A list of dropdown actions that a groups child items should show. If supplied, an actions menu will appear next to the menu item of each child. See #[code AppDropdown] for more details
            td.border.border-grey-30.px-4.py-2 no

          tr
            td.border.border-grey-30.px-4.py-2.font-bold slot
            td.border.border-grey-30.px-4.py-2 The name of the slot that you will provide the content in
            td.border.border-grey-30.px-4.py-2 no

          tr
            td.border.border-grey-30.px-4.py-2.font-bold allowAdditions
            td.border.border-grey-30.px-4.py-2 Allow the user to add new items into a group. Only applicable for group items
            td.border.border-grey-30.px-4.py-2 no

          tr
            td.border.border-grey-30.px-4.py-2.font-bold allowDeletions
            td.border.border-grey-30.px-4.py-2 Allow the user to delete items from a group. Only applicable for group items
            td.border.border-grey-30.px-4.py-2 no

          tr
            td.border.border-grey-30.px-4.py-2.font-bold allowRename
            td.border.border-grey-30.px-4.py-2 Allow the user to rename items in a group. Only applicable for group items
            td.border.border-grey-30.px-4.py-2 no

          tr
            td.border.border-grey-30.px-4.py-2.font-bold addLabel
            td.border.border-grey-30.px-4.py-2 The label to show on the add link, if #[code allowAdditions] is true. Defaults to #[code add]
            td.border.border-grey-30.px-4.py-2 no

        p.mt-8 Below is an example list of menu items

        demo-code-container
          template(#code)
            pre
              | data() {
              |   return {
              |     menuOptions: [
              |       { label: "All contants", slot: "all", icon: "contacts" },
              |       { label: "Customers", slot: "customers", icon: "invoice" },
              |       { label: "Suppliers", slot: "suppliers", icon: "bill" },
              |       {
              |         name: "Groups",
              |         allowAdditions: true,
              |         addLabel: "Add group",
              |         children: [
              |           {
              |             label: "Child 1",
              |             slot: "child1",
              |           },
              |           { label: "Child 2", slot: "child2" },
              |         ],
              |         childActions: [
              |           { label: "Action 1" },
              |           { label: "Action 2" },
              |           { label: "Action 3" },
              |         ],
              |       },
              |     ],
              |   };
              | },

        hr.h-px.border-0.bg-grey-30.my-8

        app-header Content

        p There are two ways you can supply content - the #[code default] slot, and a named slot.

        app-header.mt-8(size="h3") Default slot

        p
          | The #[code default] slot can be used when the content doesn't change very much, or if the menu is active as a kind of filter for the data.
          | Alternatively, it can act as a fallback if there are no named slots present for some menu items

        demo-code-container
          template(#code)
            pre
              | app-split-view(:menuOptions="menuOptions")
              |   p Content here will be shown all the time

        app-header.mt-8(size="h3") Named slots

        p
          | When you have very different content for different menu items, you can specify a slot name for the menu item. When you do this, the #[code AppSplitView]
          | component will fetch the content from a slot with that name.


        p.mt-4 For example, if you had the following menu item in your #[code menuOptions] array

        demo-code-container
          template(#code) { label: "Customers", slot: "customers" }

        p.mt-4 You would then specify content using a slot named #[code customers]

        demo-code-container
          template(#code)
            pre
              | app-split-view(:menuOptions="menuOptions")
              |   template(#customers)
              |     p Customer content goes here

      app-tab(name="Props & Events")

        app-header Props

        p #[code menuOptions]

        hr.h-px.border-0.bg-grey-30.my-8

        app-header Events

        table.mt-8
          tr
            th.text-grey-60.px-4.py-2 Name
            th.text-grey-60.px-4.py-2 Attributes
            th.text-grey-60.px-4.py-2 Listen to
            th.text-grey-60.px-4.py-2 Description

          tr
            td.border.border-grey-30.px-4.py-2.font-bold menuItemClicked
            td.border.border-grey-30.px-4.py-2 #[code (menuItem)]
            td.border.border-grey-30.px-4.py-2 #[code @menu-item-clicked]
            td.border.border-grey-30.px-4.py-2 Emitted after an item in the left menu is selected

          tr
            td.border.border-grey-30.px-4.py-2.font-bold menuItemAdded
            td.border.border-grey-30.px-4.py-2 #[code ({ groupItem, label })]
            td.border.border-grey-30.px-4.py-2 #[code @menu-item-added]
            td.border.border-grey-30.px-4.py-2 Emitted after a new item is added. The parent component should then handle persistence, and update the list of menu items passed to the split view

          tr
            td.border.border-grey-30.px-4.py-2.font-bold menuItemRenamed
            td.border.border-grey-30.px-4.py-2 #[code (menuItem)]
            td.border.border-grey-30.px-4.py-2 #[code @menu-item-renamed]
            td.border.border-grey-30.px-4.py-2 Emitted after an item is renamed. The parent component should then handle persistence, and update the list of menu items passed to the split view

          tr
            td.border.border-grey-30.px-4.py-2.font-bold menuItemDeleted
            td.border.border-grey-30.px-4.py-2 #[code (menuItem)]
            td.border.border-grey-30.px-4.py-2 #[code @menu-item-deleted]
            td.border.border-grey-30.px-4.py-2 Emitted after a menu item is deleted. The parent component should then handle persistence, and update the list of menu items passed to the split view
</template>

<script>
import AppHeader from "@/components/elements/AppHeader.vue";
import AppPanel from "@/components/elements/AppPanel.vue";
import AppSplitView from "@/components/containers/AppSplitViewContainer.vue";
import AppTab from "@/components/elements/AppTab.vue";
import AppTabs from "@/components/elements/AppTabs.vue";
import DemoCodeContainer from "@/views/demos/components/DemoCodeContainer.vue";

export default {
  components: {
    AppHeader,
    AppPanel,
    AppSplitView,
    AppTab,
    AppTabs,
    DemoCodeContainer,
  },
  data() {
    return {
      menuOptions: [
        { label: "All contants", slot: "all", icon: "contacts" },
        { label: "Customers", slot: "customers", icon: "invoice" },
        { label: "Suppliers", slot: "suppliers", icon: "bill" },
        {
          groupLabel: "Groups",
          allowAdditions: true,
          allowRename: true,
          allowDeletion: true,
          addLabel: "Add group",
          children: [
            {
              label: "Child 1",
              slot: "child1",
            },
            { label: "Child 2", slot: "child2" },
          ],
          childActions: [
            { label: "Action 1" },
            { label: "Action 2" },
            { label: "Action 3" },
          ],
        },
      ],
    };
  },
  methods: {
    newItemAdded(evt) {
      this.menuOptions[3].children.push({ label: evt.label });
      console.log(evt);
    },
    menuItemDeleted(menuItem) {
      console.log(menuItem);
    },
    menuItemRenamed(menuItem) {
      console.log(menuItem.label);
    },
  },
};
</script>
