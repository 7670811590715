<template lang="pug">
  .flex.flex-col.p-5.pb-10
    slot(name="label")
      .flex-col.text-center
        span.block.text-grey-70.text-lg.mb-1 {{ sliderLabel }}
    slot(name="caption")
      .flex-col.text-center
        span.block.text-grey-60.text-sm.mb-3(v-html="caption")

    .flex-col.w-full.grid.grid-cols-1.grid-rows-1
      .sticks.flex.flex-row.items-end.col-start-1.row-start-1.h-full(:style="{ height: this.height }")
        k-pop.stick.flex-grow.h-full(v-for="interval in valuesPerInterval" :key="interval.end" boundary="viewport" :offset="15" placement="top")
          slot(name="tooltip" v-bind="interval")
            .p-4.bg-white.border.border-grey-30.text-grey-70.font-md
              .inline-block.font-bold.mr-2 {{ label }}:
              .inline-block from {{ interval.start }}{{ unit }} to {{ interval.end }}{{unit}}
              .block {{ interval.count }} {{ interval.count === 1 ? `${elementName}` : `${elementName}s` }}
              .text-grey-60.bg-grey-20.mt-3(class="sm:hidden lg:block")
                b.italic.mx-2.block Min: click
                b.italic.mx-2.block Max: CTRL + click
          template(v-slot:trigger="{ show, hide }")
            span.h-full.w-full.inline-block.bg-white.relative.opacity-50.stick(@mouseenter="show" @mouseleave="hide" @click.exact="setValueMin(interval.start)" @click.ctrl.exact="setValueMax(interval.end)" class="hover:opacity-100 hover:bg-grey-20")
              span.bottom-0.block.w-full.bg-grey-50.absolute(:style="{ height: stickHeight(interval.count) }")
      .slider.col-start-1.row-start-1
        VueSlider(ref="slider" :value="sliderValue" v-bind="$attrs" :interval="interval" :min-range="interval" :lazy="lazy" @change="updated")
          template(v-slot:dot)
            .rounded-full.w-full.h-full.inline-block.bg-tribal-aqua

    .flex-col.w-full
      slot(name="errors" v-if="hasErrors" :errors="activeErrors" :errorMessages="activeErrorMessages" :has-errors="hasErrors" :first-error-message="firstErrorMessage")
        div.font-bold(:class="errorClasses") {{ firstErrorMessage }}

</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import countBy from "ramda/src/countBy";

import FieldMixin from "@/mixins/Field";

export default {
  components: {
    VueSlider,
  },

  mixins: [FieldMixin],

  props: {
    value: {
      type: Array,
      default: null,
    },

    values: {
      type: Array,
      default: () => [],
    },

    unit: {
      type: String,
      default: "",
    },

    unitFirst: {
      type: Boolean,
      default: true,
    },

    elementName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      sliderValue: this.value || [this.min, this.max],
    };
  },

  computed: {
    sliderLabel() {
      const { label, value, valUnit } = this;

      if (value) {
        return `${label} between ${valUnit(value[0])} and ${valUnit(value[1])}`;
      } else {
        return label;
      }
    },

    lazy() {
      return this.$attrs.lazy;
    },

    min() {
      return this.$attrs.min || 0;
    },

    max() {
      return this.$attrs.max || 1000;
    },

    interval() {
      return this.$attrs.interval || (this.max - this.min) / this.sticksCount;
    },

    sticksCount() {
      return 50;
    },

    sticksStep() {
      // return this.$attrs.interval || (this.max - this.min) / this.sticksCount;
      return this.interval;
    },

    stickWidth() {
      return this.percentage(this.sticksCount, 100);
    },

    height() {
      return this.$attrs.height || "50px";
    },

    valuesPerInterval() {
      const valuesPerInterval = [];
      const { min, max, sticksStep, values } = this;

      if (!values.length) return valuesPerInterval;

      let end = min;

      do {
        const start = end;
        end += sticksStep;
        end = parseFloat(end.toFixed(2));

        const inInterval = val =>
          (start === min && val === min) || (val > start && val <= end);
        const count = countBy(inInterval)(values)[true] || 0;
        const interval = { start, end, count };
        valuesPerInterval.push(interval);
      } while (end < max);

      return valuesPerInterval;
    },

    maxValuesPerInterval() {
      let max = 0;

      for (let { count } of this.valuesPerInterval) {
        if (max < parseInt(count)) max = count;
      }

      return max;
    },

    caption() {
      let { description, min, max, valUnit } = this;

      if (description) {
        return description;
      } else {
        return `Between ${valUnit(min)} and ${valUnit(max)}`;
      }
    },
  },

  watch: {
    max(newMax) {
      const { slider } = this.$refs;
      // This is to make sure that the max value is updated before
      // The value is set, just to avoid getting the error
      // "Value must be less than the max"
      this.$nextTick(() => {
        slider.setValue([this.min, newMax]);
      });
    },
  },

  methods: {
    setValueMin(val) {
      const { slider } = this.$refs;
      const value = slider.getValue();
      value[0] = val;
      slider.setValue(value);
    },

    setValueMax(val) {
      const { slider } = this.$refs;
      const value = slider.getValue();
      value[1] = val;
      slider.setValue(value);
    },

    updated(value) {
      this.sliderValue = value;
      this.$emit("input", value);
    },

    valUnit(val) {
      if (this.unitFirst) {
        return `${this.unit}${val}`;
      } else {
        return `${val}${this.unit}`;
      }
    },

    stickHeight(valuesCount) {
      let maxHeight = this.maxValuesPerInterval;
      let valueHeight = valuesCount;

      return `${this.percentage(valueHeight, maxHeight + 1)}%`;
    },

    percentage(val, total) {
      return (val / total) * 100;
    },
  },
};
</script>
<style lang="scss">
.slider {
  justify-self: stretch;
  align-self: end;
  margin-bottom: -11px;
}
</style>
