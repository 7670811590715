import Vue from "vue";
import Container from "@/components/containers/AppSidebarContainer.vue";

const open = (Sidebar, opts) => {
  let vm;
  const api = {
    hide() {
      vm.$refs.sidebar.hide();
    },
  };

  const node = document.createElement("div");
  document.body.appendChild(node);

  const closeListener = (opts.on || {}).close;
  const sidebarListeners = {
    ...(opts.on || {}),
    close() {
      vm.$refs.sidebar.hide();

      if (closeListener) {
        closeListener();
      }
    },
  };

  const vmListeners = {
    hide() {
      vm.$destroy();
      vm.$el.remove();
      vm = null;
    },
  };

  const sidebarOpts = { ...opts };
  sidebarOpts.on = sidebarListeners;

  vm = new Vue({
    el: node,
    render(h) {
      const sidebar = h(Sidebar, sidebarOpts);

      return h(
        Container,
        {
          ref: "sidebar",
          on: vmListeners,
        },
        [sidebar]
      );
    },
    mounted() {
      this.$refs.sidebar.show();
    },
  });

  return api;
};

export default {
  install(Vue) {
    Vue.prototype.$sidebar = {
      open,
    };

    this.confirm = confirm;
    this.open = open;
  },
};
