<template lang="pug">
  .toggle-button.bg-white.relative.inline-flex.flex-row.rounded-full.border.border-grey-40.h-12.p-1(ref="toggleButton")
    .button.flex-none.z-10(v-for="(option, index) in options", @click="changeOption(option)")
      template(v-if="isOptionObject(option)")
        .flex.flex-row.items-center(v-if="option.icon") 
          app-icon.mr-4(:icon="option.icon", colour="inherit")
          span {{ optionLabel(option) }}
        template(v-else)
          | {{ optionLabel(option) }}

      template(v-else)
        | {{ optionLabel(option) }}
    .selection.absolute.rounded-full.z-0.bg-grey-30.border.border-grey-50(:style="selectionStyles")
</template>

<script>
import AppIcon from "@/components/elements/AppIcon.vue";

export default {
  components: {
    AppIcon,
  },
  props: {
    options: {
      type: Array,
      required: false,
      default() {
        return [false, true];
      },
    },
    value: {
      type: [String, Boolean, Object],
      required: false,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      internalValue: this.value || this.options[0],
      selectionStyles: {
        left: 0 + "px",
        width: 0 + "px",
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.value === null || this.value === undefined) {
        this.$emit("input", this.internalValue);
      }
      this.recalculateSelectionPosition();
    });
  },
  methods: {
    changeOption(option) {
      this.$emit("input", option);
    },
    isOptionObject(option) {
      return typeof option === "object";
    },
    optionLabel(option) {
      if (typeof option === "boolean") {
        return option ? "Enabled" : "Disabled";
      } else if (typeof option === "object") {
        return option.label;
      }

      return option;
    },
    recalculateSelectionPosition() {
      const { toggleButton } = this.$refs;
      const buttons = toggleButton.querySelectorAll(".button");
      const selectedButton = buttons[this.selectedIdx];
      const buttonPosition = selectedButton.getBoundingClientRect();
      const parentPosition = toggleButton.getBoundingClientRect();

      this.selectionStyles = {
        left: buttonPosition.x - parentPosition.x + "px",
        height: buttonPosition.height + "px",
        width: buttonPosition.width + "px",
      };
    },
  },
  computed: {
    selectedIdx() {
      if (typeof this.internalValue === "object") {
        return this.options.findIndex(
          val => val.value === this.internalValue.value
        );
      } else {
        return this.options.indexOf(this.internalValue);
      }
    },
  },
  watch: {
    value(newValue) {
      this.internalValue = newValue;
      this.recalculateSelectionPosition();
    },
    isVisible(visible) {
      if (visible) {
        // Changed, so recalculate position
        this.recalculateSelectionPosition();
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.toggle-button {
  /* padding: 0.1rem; */
}

.button {
  @apply ml-4;
  @apply py-2;
  @apply px-4;
  @apply rounded;
  @apply cursor-pointer;
  transition: color 0.15s linear;

  &:first-child {
    @apply ml-0;
  }
}

.selection {
  transition: left 0.15s ease-out, background 0.15s ease-out;
}
</style>
