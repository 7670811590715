<template lang="pug">
  .labels
    app-panel
      app-header
          | Labels
          template(#subheader) Useful for showing a status

      app-tabs
        app-tab(name="Details" selected)

          app-header Introduction

          p Labels are designed to show either a status of something such as a task, or a small piece of information such as an applied filter.

          hr.h-px.border-0.bg-grey-30.my-8

          app-header Basic labels

          p Basic labels show a small piece of text, and come in a range of colours

          demo-code-container
            app-label Default
            app-label.ml-4 Not started
            app-label.ml-4(:colour="types.BLUE") In progress
            app-label.ml-4(:colour="types.RED") Overdue
            app-label.ml-4(:colour="types.GREEN") Complete
            app-label.ml-4(:colour="types.YELLOW") Warning

            template(#code)
              pre
                | app-label Default
                | app-label.ml-4 Not started
                | app-label.ml-4(colour="blue") In progress
                | app-label.ml-4(colour="red") Overdue
                | app-label.ml-4(colour="green") Complete
                | app-label.ml-4(colour="yellow") Warning


          hr.h-px.border-0.bg-grey-30.my-8

          app-header Removable labels

          p A removable label provides a small button on the right side of the label. This button will emit an event allowing you to remove the label

          demo-code-container
            app-label(removable @remove-click="remove") Default
            app-label.ml-4(removable @remove-click="remove") Not started
            app-label.ml-4(:colour="types.BLUE" removable @remove-click="remove") In progress
            app-label.ml-4(:colour="types.RED" removable @remove-click="remove") Overdue
            app-label.ml-4(:colour="types.GREEN" removable @remove-click="remove") Complete
            app-label.ml-4(:colour="types.YELLOW" removable @remove-click="remove") Warning

            template(#code)
              pre
                | app-label(removable) Default
                | app-label.ml-4(removable) Not started
                | app-label.ml-4(colour="blue" removable) In progress
                | app-label.ml-4(colour="red" removable) Overdue
                | app-label.ml-4(colour="green" removable) Complete
                | app-label.ml-4(colour="yellow" removable) Warning

          hr.h-px.border-0.bg-grey-30.my-8

          app-header Ribbon labels

          p Ribbon labels are attached to their container element

          demo-code-container
            .grid.grid-cols-2.gap-8.mt-8
              app-panel.bg-grey-10
                app-ribbon-label Hello
                p A label by default will be grey


              app-panel.bg-grey-10
                app-ribbon-label(colour="red") Danger
                p Ribbons can be red


              app-panel.bg-grey-10
                app-ribbon-label(colour="yellow") Warning
                p Ribbons can be yellow


              app-panel.bg-grey-10
                app-ribbon-label(colour="green") Success
                p Ribbons can also be green

            template(#code)
              pre
                | .grid.grid-cols-2.gap-8.mt-8
                |   app-panel.bg-grey-10
                |     app-ribbon-label Hello
                |     p A label by default will be grey
                |
                |   app-panel.bg-grey-10
                |     app-ribbon-label(colour="red") Danger
                |     p Ribbons can be red
                |
                |   app-panel.bg-grey-10
                |     app-ribbon-label(colour="yellow") Warning
                |     p Ribbons can be yellow
                |
                |   app-panel.bg-grey-10
                |     app-ribbon-label(colour="green") Success
                |     p Ribbons can also be green

        app-tab(name="Usage")

          app-header Usage

          p
            | Labels are inline components and can be used anywhere, with the exception of ribbon labels. Ribbon labels are designed
            | to work with #[code AppPanel] components only

          hr.h-px.border-0.bg-grey-30.my-8

          app-header Content

          p Setting the text content of a label is done through the #[code default] slot

          demo-code-container
            template(#code)
              pre
                | app-label Your content goes here
                | app-ribbon-label Ribbon content here

          hr.h-px.border-0.bg-grey-30.my-8

          app-header Colours

          p All labels are available in a range of colours. The colours currently available are

          ul.list-disc.mt-8.ml-4
            li(v-for="colour in types") {{ colour }}

          p.mt-8 To use a particular colour, you must set the #[code colour] prop

          demo-code-container
            template(#code)
              pre
                | app-label(colour="red") Overdue

          hr.h-px.border-0.bg-grey-30.my-8

          app-header Removable

          p
            | Marking a label as #[code removable] is done by setting the #[code removable] prop to true. You will also need to register a listener
            | for the #[code remove-clicked] event to handle any logic required

          demo-code-container
            template(#code)
              pre
                | app-label(removable @remove-clicked="removeMyLabel") Removable

        app-tab(name="Props & Events")
          app-header Props

          table.mt-8.table-auto
            thead
              tr
                th.text-grey-60.px-4.py-2 Name
                th.text-grey-60.px-4.py-2 Type
                th.text-grey-60.px-4.py-2 Desc
                th.text-grey-60.px-4.py-2 Required
                th.text-grey-60.px-4.py-2 Default

            tbody
              tr
                td.border.border-grey-30.px-4.py-2.font-bold colour
                td.border.border-grey-30.px-4.py-2 String
                td.border.border-grey-30.px-4.py-2 The colour of the label
                td.border.border-grey-30.px-4.py-2 No
                td.border.border-grey-30.px-4.py-2 #[code "grey"]

              tr
                td.border.border-grey-30.px-4.py-2.font-bold removable
                td.border.border-grey-30.px-4.py-2 Boolean
                td.border.border-grey-30.px-4.py-2 Whether or not to show a remove button
                td.border.border-grey-30.px-4.py-2 No
                td.border.border-grey-30.px-4.py-2 #[code false]

          hr.h-px.border-0.bg-grey-30.my-8

          app-header Events

          table.mt-8
            thead
              tr
                th.text-grey-60.px-4.py-2 Name
                th.text-grey-60.px-4.py-2 Attributes
                th.text-grey-60.px-4.py-2 Listen to
                th.text-grey-60.px-4.py-2 Description

            tbody
              tr
                td.border.border-grey-30.px-4.py-2.font-bold removeClicked
                td.border.border-grey-30.px-4.py-2
                td.border.border-grey-30.px-4.py-2 #[code @remove-clicked]
                td.border.border-grey-30.px-4.py-2 Emitted after the #[code remove] button is clicked
</template>

<script>
import Types from "@/config/types/label-types";
import AppHeader from "@/components/elements/AppHeader.vue";
import AppLabel from "@/components/elements/AppLabel.vue";
import AppPanel from "@/components/elements/AppPanel.vue";
import AppRibbonLabel from "@/components/elements/AppRibbonLabel.vue";
import AppTab from "@/components/elements/AppTab.vue";
import AppTabs from "@/components/elements/AppTabs.vue";
import DemoCodeContainer from "@/views/demos/components/DemoCodeContainer.vue";

export default {
  components: {
    AppHeader,
    AppLabel,
    AppPanel,
    AppRibbonLabel,
    AppTab,
    AppTabs,
    DemoCodeContainer,
  },
  computed: {
    types() {
      return Types;
    },
  },
  methods: {
    remove() {
      alert("REMOVE");
    },
  },
};
</script>
