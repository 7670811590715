<script>
import { mergeData } from "vue-functional-data-merge";
import SuccessTypes from "@/config/types/success-types";
import {
  icons as flashIcons,
  darkIconColours as iconColours,
} from "@/config/types/success-types";
import AppButton from "@/components/elements/AppButton.vue";
import AppHeader from "@/components/elements/AppHeader.vue";
import AppIcon from "@/components/elements/AppIcon.vue";

const titleColours = {
  [SuccessTypes.ERROR]: "text-withered-cherry",
  [SuccessTypes.WARNING]: "text-rough-mustard",
  [SuccessTypes.INFO]: "text-grey-80",
  [SuccessTypes.SUCCESS]: "text-subatomic-sky",
};
const flashBorders = {
  [SuccessTypes.ERROR]: "border-withered-cherry",
  [SuccessTypes.WARNING]: "border-laughing-amber",
  [SuccessTypes.INFO]: "border-grey-40",
  [SuccessTypes.SUCCESS]: "border-subatomic-sky",
};
const flashColours = {
  [SuccessTypes.ERROR]: "bg-withered-cherry",
  [SuccessTypes.WARNING]: "bg-laughing-amber",
  [SuccessTypes.INFO]: "bg-grey-40",
  [SuccessTypes.SUCCESS]: "bg-subatomic-sky",
};

const noop = () => {};
const getIcon = (h, props) => {
  const classes = ["flex-0", "w-20", flashColours[props.type]];
  const icon = h(AppIcon, {
    props: {
      icon: flashIcons[props.type],
      size: "large",
      colour: iconColours[props.type],
      block: true,
    },
    class: "block w-12 mx-auto mt-4",
  });
  return h("div", { class: classes }, [icon]);
};
const getContent = (h, props, listeners) => {
  const children = [
    getTitle(h, props),
    getCloseButton(h, listeners["close"] || noop),
    getText(h, props),
    getAction(h, props, listeners),
  ];

  return h("div", { class: "flex-0 w-64 p-4" }, children);
};
const getAction = (h, props, listeners) => {
  if (!props.action) {
    return null;
  }

  return h(
    AppButton,
    { on: { click: listeners.click || noop }, class: "mt-8" },
    props.action.text
  );
};
const getCloseButton = () => {};
const getText = (h, props) => {
  return h("p", { class: "text-grey-60" }, props.text);
};
const getTitle = (h, props) => {
  const classes = [getTitleColour(props), "uppercase"];

  return h(
    AppHeader,
    { props: { margin: "mb-2", size: "h4" }, class: classes },
    props.title
  );
};

const getContainerClasses = props => [
  flashBorders[props.type],
  "flex",
  "flex-row",
  "border",
  "bg-white",
  "shadow-lg",
  "pointer-events-auto",
  "rounded-lg",
];
const getTitleColour = props => titleColours[props.type];

export default {
  functional: true,
  props: {
    type: {
      type: String,
      required: true,
      validator(val) {
        return Object.values(SuccessTypes).indexOf(val) !== -1;
      },
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    action: {
      type: Object,
      required: false,
    },
  },
  render(h, { props, listeners, data }) {
    const containerClasses = getContainerClasses(props);
    const children = [getIcon(h, props), getContent(h, props, listeners)];

    delete listeners.click;
    delete listeners.close;

    return h("div", mergeData(data, { class: containerClasses }), children);
  },
};
</script>
