export function actionsCell() {
  return {
    field: "actions",
    headerName: "",
    cellRenderer: "actionsMenu",
    headerComponent: "actionsHeader",
    width: 65,
    resizable: false,
    pinned: "right",
    suppressSizeToFit: true,
    sortable: false,
    editable: false,
    cellStyle() {
      return {
        padding: "0",
      };
    },
    canToggle: false,
  };
}

export default {
  actionsCell,
};
