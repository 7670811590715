/**
 * Provides functions for generating ag-grid options related
 * to the datasource of the table. This also
 */

/**
 * Generate the ag-grid options for the datasource.
 */
function datasourceOptions(component) {
  return {
    rowModelType: component.config.datasource.rowModelType,
  };
}

function datasourceProps(component) {
  if (component.config.datasource.rowModelType === "clientSide") {
    return { rowData: component.rowData };
  } else {
    return { datasource: component.datasource };
  }
}

export { datasourceProps, datasourceOptions };
