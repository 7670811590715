import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import setPrototypes from "./includes";

import KPop from "@ckienle/k-pop";
import vClickOutside from "v-click-outside";
import PortalVue from "portal-vue";
import Vuelidate from "vuelidate";
import vuelidateErrorExtractor, { templates } from "vuelidate-error-extractor";

Vue.config.productionTip = false;

// CSS
import "@ckienle/k-pop/themes/clean.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "@/assets/css/ag-grid/mp-theme.scss";
// import "ag-grid-community/dist/styles/ag-theme-material.css";
import "@/assets/css/tailwind-init.css";
import "@/assets/css/globals.css";

// Syntax highlighting
// import hljs from "highlight.js";
// import javascript from "highlight.js/lib/languages/javascript";
// hljs.registerLanguage("javascript", javascript);
// import "highlight.js/styles/github-dark-dimmed.css";
// hljs.initHighlightingOnLoad();

Vue.use(KPop);
Vue.use(vClickOutside);
Vue.use(PortalVue);
Vue.use(Vuelidate);
Vue.use(vuelidateErrorExtractor, {
  //   template: FormInputWrapper,
  messages: {
    required: "The {attribute} is required",
    decimal: "{attribute} must be a decimal number",
    inList: "The {attribute} is not a valid option",
    email: "The {attribute} is not a valid email address",
    positive: "The {attribute} must be >= 0",
  },
});
Vue.component("FormWrapper", templates.FormWrapper);
setPrototypes(Vue, { store });

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
