<template lang="pug" functional>
  .flex.flex-row.items-center(v-bind="data.attrs", :class="data.staticClass || ''")
    input.hidden(type="checkbox", :checked="props.value")
    label(@click="$options.toggle(props, listeners)") 

    span.ml-4 {{ $options.labelValue(props) }}
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    trueLabel: {
      type: [String],
      required: false,
      default: "Yes",
    },
    falseLabel: {
      type: [String, Boolean],
      required: false,
      default: "No",
    },
  },
  toggle(props, listeners) {
    if (listeners.input) {
      listeners.input(!props.value);
    }
  },
  labelValue({ value, trueLabel, falseLabel }) {
    return value ? trueLabel : falseLabel;
  },
};
</script>

<style lang="postcss" scoped>
input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label {
  cursor: pointer;
  text-indent: -9999px;
  width: 60px;
  height: 30px;
  @apply bg-grey-60;
  display: block;
  border-radius: 30px;
  position: relative;
  transition: background 0.25s linear;
}

label:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 20px;
  transition: 0.25s;
}

input:checked + label {
  @apply bg-tribal-aqua;
}

input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

label:active:after {
  width: 30px;
}
</style>
