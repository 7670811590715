import AppHeader from "@/components/elements/AppHeader.vue";
import AppPanel from "@/components/elements/AppPanel.vue";

export default {
  components: { AppHeader, AppPanel },
  props: {
    title: { type: String, required: true },
    description: { type: String, required: true },
    value: { type: Object, required: true },
    field: { type: String, required: false },
    enabled: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      isEnabled: this.enabled,
    };
  },
  methods: {
    disableFilter() {
      this.isEnabled = false;
      this.$emit("input", {});
      this.$emit("disabled");
    },
    enableFilter() {
      this.isEnabled = true;
      this.$emit("input", this.appliedFilters);
      this.$emit("enabled");
    },
    selectionChanged(selection) {
      this.selected = selection;
      this.$emit("input", this.appliedFilters);
    },
  },
  watch: {
    enabled(enabled) {
      if (enabled !== this.isEnabled) {
        this.isEnabled = enabled;
        this.$emit("input", enabled ? this.appliedFilters : {});
      }
    },
  },
};
