<template lang="pug">
  filter-panel(:title="title" :description="description" :enabled="isEnabled" @enable="enableFilter" @disable="disableFilter")
    radio-buttons(:options="options" :value="selected" @input="selectionChanged")
      template(#startsWith)
        app-text-input.w-full(:value="startsWith" ref="startsWith" @input="valueChanged('startsWith', $event)")

      template(#endsWith)
        app-text-input.w-full(:value="endsWith" ref="endsWith" @input="valueChanged('endsWith', $event)")

      template(#contains)
        app-text-input.w-full(:value="contains" ref="contains" @input="valueChanged('contains', $event)")

      template(#notContain)
        app-text-input.w-full(:value="notContain" ref="notContain" @input="valueChanged('notContain', $event)")
</template>

<script>
import AppTextInput from "@/components/elements/AppTextInput.vue";
import FilterPanel from "@/components/collections/filters/AppFilterSidebarFilterPanel.vue";
import FilterPanelMixin from "@/components/collections/filters/AppFilterSidebarFilterPanelMixin";
import RadioButtons from "@/components/collections/filters/AppFilterSidebarFilterRadioButtons.vue";

export default {
  name: "TextFilter",
  components: {
    AppTextInput,
    FilterPanel,
    RadioButtons,
  },
  mixins: [FilterPanelMixin],
  data() {
    return {
      options: [
        { label: "Starts with", value: "startsWith", slot: "startsWith" },
        { label: "Ends with", value: "endsWith", slot: "endsWith" },
        { label: "Contains", value: "contains", slot: "contains" },
        { label: "Does not contain", value: "notContain", slot: "notContain" },
      ],
      selected: "startsWith",
      startsWith: "",
      endsWith: "",
      contains: "",
      notContain: "",
    };
  },
  mounted() {
    if (this.value && (this.value[this.field] || this.value._not)) {
      this.selected = this.value.filterName;
      this[this.selected] = this.parseFilterValue(this.value);
    }
  },
  computed: {
    appliedFilters() {
      let condition;

      if (this.selected === "startsWith") {
        condition = { [this.field]: { prefix: this.startsWith } };
      } else if (this.selected === "endsWith") {
        condition = { [this.field]: { like: `%${this.endsWith}` } };
      } else if (this.selected === "contains") {
        condition = { [this.field]: { like: `%${this.contains}%` } };
      } else if (this.selected === "notContain") {
        condition = {
          _not: { [this.field]: { like: `%${this.notContain}%` } },
        };
      }

      return {
        filterName: this.selected,
        ...condition,
      };
    },
  },
  methods: {
    parseFilterValue(value) {
      switch (this.selected) {
        case "startsWith":
          return value[this.field].prefix;

        case "endsWith":
        case "contains": {
          const { like } = value[this.field];
          return like.replaceAll("%", "");
        }

        case "notContain": {
          const like = value._not[this.field].like;
          return like.replaceAll("%", "");
        }
      }
    },
    valueChanged(field, val) {
      this[field] = val;
      this.$emit("input", this.appliedFilters);
    },
  },
};
</script>
