import SuccessTypes from "@/config/types/success-types";

export default {
  namespaced: true,
  state() {
    return {
      notices: [],
    };
  },
  mutations: {
    showNotice(state, notice) {
      state.notices.push(notice);
    },
    popNotice(state) {
      state.notices.pop();
    },
  },
  getters: {
    notices(state) {
      return state.notices.reverse();
    },
  },
  actions: {
    error({ commit }, { text, actions }) {
      commit("showNotice", {
        type: SuccessTypes.ERROR,
        text: text,
        actions: actions,
      });
    },
    info({ commit }, { text, actions }) {
      commit("showNotice", {
        type: SuccessTypes.INFO,
        text: text,
        actions: actions,
      });
    },
    success({ commit }, { text, actions }) {
      commit("showNotice", {
        type: SuccessTypes.SUCCESS,
        text: text,
        actions: actions,
      });
    },
    warn({ commit }, { text, actions }) {
      commit("showNotice", {
        type: SuccessTypes.WARNING,
        text: text,
        actions: actions,
      });
    },
  },
};
