<template lang="pug">
app-date-field(
  ref="datePicker",
  :value="value",
  :error="$v.value.$error",
  :show-optional="false"
  v-bind="datePickerProps",
  @input="setValueAndClose"
)
</template>

<script>
import Vue from "vue";
import CellEditorMixin from "./BaseTableCellEditorMixin";
import AppDateField from "@/components/elements/fields/AppDateField.vue";

export default Vue.extend({
  mixins: [CellEditorMixin],
  components: {
    AppDateField,
  },
  computed: {
    datePickerProps() {
      return this.params.datePickerOpts;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.datePicker.focus();
    });
  },
  methods: {
    isPopup() {
      return true;
    },
  },
});
</script>
