<template lang="pug">
  app-panel
    app-header
      | Sidebars
      template(#subheader) Present details in a slide out sidebar

    p The `AppSidebar` component can be used to present a pop-out sidebar to show detailed information
    p about a particular resource, without the user losing context of where they were

    app-tabs
      app-tab(name="Demo" selected)
        p Click the button below to open or close the sidebar

        app-button.mt-8(@click="visible = !visible") Toggle sidebar

      app-tab(name="Usage" lazy)
        p Simply render the `AppSidebar` component in your template, and use the `v-model` binding with
        p a boolean value. If it's true, the sidebar will show, otherwise it will be hidden

        pre.my-8
          code.langage-javascript
            | app-button(@click="isVisible = !isVisible") View project details
            |
            | app-sidebar(v-model="isVisible")
            |  app-header
            |    | Project X
            |    template(#subheader) Deadline 01/01/2020
            |
            |  | Project details here

        p To open or close the sidebar, simply change the value of the `v-model` bound data item to
        p true or false respectively

        app-button(@click="openSidebar")  Open 2

      app-tab(name="Props")
        props-table(:p="props")


    app-sidebar-container(v-model="visible")
      app-sidebar(@close="visible = false")
        app-header
          | Hello there
          template(#subheader) I am a sidebar, and contain whatever you want

        app-tabs
          app-tab(name="Tab 1" selected)
            | I can contain tabs
          app-tab(name="Tab 2")
            | Or any content

      app-button.mt-8(@click="visible = false") Close me
</template>

<script>
import AppButton from "@/components/elements/AppButton.vue";
import AppHeader from "@/components/elements/AppHeader.vue";
import AppPanel from "@/components/elements/AppPanel.vue";
import AppSidebar from "@/components/elements/AppSidebar.vue";
import AppSidebarContainer from "@/components/containers/AppSidebarContainer.vue";
import AppTab from "@/components/elements/AppTab.vue";
import AppTabs from "@/components/elements/AppTabs.vue";
import DemoSidebar from "@/views/demos/DemoSidebar.vue";
import PropsTable from "@/views/demos/components/PropsTable.vue";

export default {
  components: {
    AppButton,
    AppHeader,
    AppPanel,
    AppSidebar,
    AppSidebarContainer,
    AppTab,
    AppTabs,
    DemoSidebar,
    PropsTable,
  },
  data() {
    return {
      visible: false,
      props: [
        {
          name: "value",
          type: "Boolean",
          desc:
            "Whether the sidebar is open or not. Can be bound with `v-model`",
          required: false,
          default: false,
        },
        {
          name: "compact",
          type: "Boolean",
          desc: "Whether or not the sidebar should include 2rem padding",
          required: false,
          default: false,
        },
      ],
    };
  },
  methods: {
    openSidebar() {
      this.$sidebar.open(DemoSidebar);
    },
  },
};
</script>
