import { render, staticRenderFns } from "./Demo.vue?vue&type=template&id=594524e1&scoped=true&lang=pug&"
import script from "./Demo.vue?vue&type=script&lang=js&"
export * from "./Demo.vue?vue&type=script&lang=js&"
import style0 from "./Demo.vue?vue&type=style&index=0&id=594524e1&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "594524e1",
  null
  
)

export default component.exports