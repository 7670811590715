<template lang="pug">
  .bg-white.w-full.h-full.overflow-hidden.relative
    .flex.flex-row.border-b.border-grey-30.px-8.items-center.h-20.justify-center(v-for="n in 20")
      .message(v-if="n === 3")
        strong {{ title }}
        p.text-grey-60(v-if="message") {{ message }}
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  computed: {
    title() {
      return this.params.title || "No results";
    },
    message() {
      return this.params.message;
    },
  },
});
</script>
