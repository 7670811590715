<script>
import BaseTable from "@/components/collections/BaseTable.vue";
import { actionsCell } from "@/components/helpers/table-helpers";

export default {
  extends: BaseTable,
  data() {
    return {
      selection: "multiple",
      columns: [
        {
          field: "position",
          hide: true,
        },
        {
          headerName: "Name",
          field: "name",
          checkboxSelection: true,
          headerCheckboxSelection: true,
          cellRenderer: "link",
          cellRendererParams: {
            onClick() {
              alert("You clicked me!");
            },
          },
          editable: true,
          flex: 1,
          cellEditorParams: {
            required: true,
          },
          canToggle: false,
        },
        { headerName: "Size", field: "size", maxWidth: 200, hide: true },
        { headerName: "Labels", field: "labels", cellRenderer: "labels" },
        { headerName: "Status", field: "status", cellRenderer: "status" },
        {
          headerName: "Priority",
          field: "priority",
          cellRenderer: "priority",
          cellEditor: "dropdownCellEditor",
          cellEditorParams: {
            options: [
              {
                label: "Urgent",
                value: "URGENT",
                icon: "warning",
                iconColour: "red",
              },
              {
                label: "High",
                value: "HIGH",
                icon: "warning",
                iconColour: "yellow",
              },
              {
                label: "Normal",
                value: "NORMAL",
                icon: "normalPriority",
                iconColour: "blue",
              },
              {
                label: "Low",
                value: "LOW",
                icon: "lowPriority",
                iconColour: "grey",
              },
            ],
            dropdownProps: {
              allowEmpty: false,
              deselectLabel: "",
              valueAttr: "value",
              labelAttr: "label",
            },
          },
        },
        {
          headerName: "Last upload",
          field: "last_upload",
          cellEditor: "dateCellEditor",
          cellRenderer: "date",
          cellRendererParams: {
            time: false,
            highlightOverdue: true,
          },
        },
        {
          headerName: "Uploader",
          field: "uploader",
          cellRenderer: "teamMember",
        },
        {
          headerName: "",
          width: 65,
          resizable: false,
          pinned: "right",
          suppressSizeToFit: true,
          sortable: false,
          cellRenderer: "iconButton",
          cellRendererParams: {
            icon: "download",
            onClick() {
              alert("You clicked me");
            },
          },
          canToggle: false,
        },
        actionsCell(),
      ],
      config: {
        resourceType: "test thing",
        filters: {
          enabled: {
            type: "boolean",
            title: "Enabled",
            description: "A boolean filter",
            field: "enabled",
            trueLabel: "Is enabled",
            falseLabel: "Is not enabled",
          },
          testing: {
            type: "text",
            title: "Name",
            description: "Filter based on the name",
            field: "name",
          },
          status: {
            type: "option",
            title: "Status",
            description: "The status of the task",
            field: "status",
            options: [
              { label: "Not started", value: "NOT_STARTED" },
              { label: "Started", value: "STARTED" },
              { label: "Complete", value: "COMPLETE" },
            ],
          },
          lastUpload: {
            type: "date",
            title: "Last uploaded",
            description: "Filtering on a date field",
            field: "last_uploaded",
          },
          priority: {
            type: "singleChoice",
            title: "Single choice",
            description: "Single choice filtering",
            field: "priority",
            options: [
              {
                label: "Urgent",
                value: "URGENT",
                field: "priority",
                filterValue: "URGENT",
              },
              {
                label: "High",
                value: "HIGH",
                field: "priority",
                filterValue: "HIGH",
              },
              {
                label: "Normal",
                value: "NORMAL",
                field: "priority",
                filterValue: "NORMAL",
              },
              {
                label: "Low",
                value: "LOW",
                field: "priority",
                filterValue: "LOW",
              },
            ],
          },
        },
      },

      defaultColumn: {
        sortable: true,
        editable: true,
        cellEditor: "standardCellEditor",
        resizable: true,
        headerComponentParams: {
          customSort(direction, colId) {
            console.log(direction, colId);
          },
        },
      },
      options: {
        suppressClickEdit: false,
      },
    };
  },
  mounted() {
    this.startLoading();
    setTimeout(() => {
      this.setRowData(this.computedRowData);
      this.stopLoading();
    }, 3000);
  },
  methods: {
    contextMenuItemClicked(option, row) {
      if (option.label === "Edit") {
        this.gridApi.startEditingCell({
          rowIndex: row.rowIndex,
          colKey: "name",
        });
      }
    },
    getContextMenuItems() {
      let items = [{ label: "Edit", icon: "edit" }];
      let i = 0;

      for (i; i < 5; i++) {
        items.push({
          label: `Option ${i}`,
        });
      }

      return items;
    },
    getBulkActions() {
      return this.getContextMenuItems();
    },
    getFooter(h) {
      return h("div", {}, "I'm a footer");
    },
    //columnVisibilityChanged(column) {
    //  alert(`Toggled column ${column.headerName}. Visible: ${!column.hide}`);
    //},
  },
  computed: {
    computedRowData() {
      let items = [];
      let i = 0;

      for (i; i < 30; i++) {
        items.push({
          id: i,
          position: 30 - i,
          name: `Test ${i}`,
          size: i * 1024,
          labels: ["a", "b", "c", "d"],
          // last_upload: new Date(),
          last_upload: "2020/09/01",
          status: "STARTED",
          priority: "URGENT",
          overdue: true,
          uploader: {
            name: "Adrian Hooper",
            role: "Administrator",
            avatar: {
              url: "https://via.placeholder.com/200",
            },
          },
        });
      }

      return items;
    },
    tableBinds() {
      return {
        noRowsOverlayComponentParams: {
          title: "No results",
          message: "Afraid there are no results",
        },
      };
    },
  },
};
</script>
