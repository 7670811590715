/* eslint-disable no-unused-vars */

/**
 * Defines a Vue Mixin containing methods that are
 * used as hooks/callbacks for the base table. Tables
 * that extend the base table will be able to override
 * these callbacks to get notifications when things
 * happen
 **/
export default {
  methods: {
    /**
     *
     *
     * GRID RELATED CALLBACKS
     *
     * These callbacks are specific to events related to the
     * table itself, such as column changes, grid ready events
     * etc.
     *
     **/

    /**
     * Notified when the grid is ready
     */
    gridReady() {},

    /**
     *  Called when a column is toggled by the user. The column
     *  object will be supplied, which contains a `hide` property.
     *  This will be `true` when the column has been hidden, or
     *  `false` when it has been displayed
     *
     *  @param column The ag-grid column object
     **/
    columnVisibilityChanged(column) {},

    /**
     * Called when the number of selected rows is changed.
     **/
    rowSelectionChanged() {},

    /**
     *
     *
     * CONTEXT MENU/BULK ACTION CALLBACKS
     *
     * These callbacks related to the context menu, or the bulk
     * action menu. These should be used to respond to users
     * clicking on these actions
     *
     *
     **/

    /**
     * Called when a context menu item is clicked, either from
     * the right click menu, or the actions menu. Supplied the
     * item that was clicked, and the row that it applies to.
     *
     * @param option The context menu option clicked
     * @param node The ag-grid row node
     */
    contextMenuItemClicked(option, node) {},

    /**
     * Called when a bulk action is chosen. Will be supplied with
     * the item that was selected
     *
     * @param item The bulk action item that was selected
     */
    performBulkAction(item) {},

    /**
     *
     *
     * OTHER CALLBACKS
     *
     * These callbacks don't fall into any specific category
     *
     *
     **/

    /**
     * Called when a file is dropped onto the table. Supplied with
     * the row the file was dropped on, and the original drop event
     *
     * @param row The ag-grid row node
     * @param evt The drop event that triggered the callback
     */
    fileDropped(row, evt) {},
  },
};
