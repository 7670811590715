<template lang="pug">
  .mb-4
    label(:for="name" :class="errorClasses")
      slot(name="label" :invalid="hasErrors")
        span(:class="labelClasses") {{ fieldLabel }}
      slot(name="caption", v-if="description")
        span(:class="descriptionClasses") {{ description }}

    slot(:has-errors="hasErrors", :listeners="listeners", :value="fieldValue")
      quill-editor.border-grey-50.w-full.rounded-lg(v-if="isRichtext", :value="fieldValue" :options="editorOptions", v-on="listeners")
        template(#toolbar)
          #toolbar.bg-grey-80.rounded-t-lg
            .mr-5.border-r.border-grey-50.bg-grey-30
              button(v-for="format in ['bold', 'italic', 'underline', 'strike', 'clean']" :key="format" :class="classFor(format)") {{ format }}

            select.ql-header
              option(v-for="l in [false,5,4,3,2,1]" :value="l")
            select(v-for="format in ['size', 'align', 'color', 'background']" :key="format" :class="classFor(format)") {{ format }}

            button.ql-indent(value='-1') indent
            button.ql-indent(value='+1') indent
            button.ql-direction(value='rtl') direction
            button(v-for="format in ['link']" :key="format" :class="classFor(format)") {{ format }}

      app-text-input.w-full(v-else :type="type", :value="fieldValue", :icon="icon", v-on="listeners", :error="hasErrors", :clearable="clearable")

    slot(name="errors" v-if="hasErrors" :errors="activeErrors" :errorMessages="activeErrorMessages" :has-errors="hasErrors" :first-error-message="firstErrorMessage")
      div.font-bold.text-right.mt-1(:class="errorClasses") {{ firstErrorMessage }}
</template>

<script>
import AppTextInput from "@/components/elements/AppTextInput.vue";
import { quillEditor } from "vue-quill-editor";
import FieldMixin from "@/mixins/Field";
export default {
  components: {
    AppTextInput,
    quillEditor,
  },
  mixins: [FieldMixin],
  props: {
    type: {
      type: String,
      required: false,
      default: "text",
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      editorOptions: {
        modules: {
          toolbar: "#toolbar",
        },
      },
    };
  },
  computed: {
    isRichtext() {
      return this.type == "richtext";
    },
    listeners() {
      return Object.assign({}, this.$listeners, {
        input: evt => {
          this.$emit("input", evt);
        },
        focus: () => {
          this.$emit("focus");
        },
      });
    },
  },
  methods: {
    classFor(format) {
      return `ql-${format}`;
    },
  },
};
</script>

<style lang="postcss">
@import "quill/dist/quill.core.css";
@import "quill/dist/quill.snow.css";

button.btn,
select.btn,
.ql-snow.ql-toolbar .ql-picker-label {
  &:hover,
  &.ql-active {
    @apply text-grey-30 !important;
    @apply bg-grey-60;
  }
}

.ql-snow.ql-toolbar {
  .ql-picker-options {
    @apply p-0;
    @apply text-grey-80;

    .ql-picker-item {
      @apply py-1;
      @apply px-2;

      &:hover {
        @apply bg-grey-30;
        @apply text-grey-80;
      }

      &.ql-selected {
        @apply bg-grey-30;
        @apply text-tribal-aqua;
      }
    }
  }
}

.ql-snow.ql-container {
  @apply rounded-b-lg;
}

.ql-snow .ql-stroke {
  fill: none;
  stroke: #fff !important;
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: #fff !important;
  stroke: none;
}

.ql-snow .ql-picker {
  color: #fff !important;
}

.ql-editor {
  @apply h-56;
}
</style>
