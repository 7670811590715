<template lang="pug">
  .inputs
    app-panel
      app-header
        | Standalone inputs
        template(#subheader) Inputs that can be used anywhere

      p Standalone inputs are designed to be used anywhere, but do not provide functionality useful for forms.
      p The `AppTextInput` component will render only the input itself, and does not provide any labels or description.
      p If a label or description are required, the `AppTextField` component should be used.

      p.mt-4 You can add an icon to your input, and present it in an error state. See `props` for details

      app-tabs.mt-8
        app-tab(name="Demo" selected)
          app-text-input.w-full.mb-4(v-model="testing")
          app-text-input.w-64.mb-4(value="Hello world")
          app-text-input.w-full.mb-4(icon="search", v-model="searchValue", clearable)
          app-text-input.w-full.mb-4(icon="user", error)

        app-tab(name="Examples")
          pre.my-8
            code.language-javascript
              | app-text-input.w-full.mb-4(v-model="testing")
              |
              | app-text-input.w-64.mb-4(value="Hello world")
              |
              | app-text-input.w-full.mb-4(icon="search", clearable)
              |
              | app-text-input.w-full.mb-4(icon="user", error)

        app-tab(name="Props")
          props-table(:p="props.input")

    app-panel
      app-header
        | Form inputs
        template(#subheader) Inputs in a form can hook into validation rules


      form-wrapper(:validator="$v.inputs")
        app-text-field(label="Normal input")
        app-text-field(label="Normal input", description="With a description")
        app-text-field(label="Required input", name="invalid", v-model="inputs.invalid", description="With an error")

        app-text-field(label="Rich text editor", description="With a description", type="richtext")

        app-dropdown-field(label="Single select dropdown", description="With a description", :options="dropdownOptions", value-attr="id", label-attr="label", track-by="id", v-model="inputs.invalidDropdown", name="invalidDropdown")
        app-dropdown-field(label="Multi select dropdown", description="With a description", :options="dropdownOptions", value-attr="id", label-attr="label", track-by="id", v-model="multiDropdownValue", multiple)
        app-date-field(label="Date field", description="With a description", v-model="date")

        app-number-field(label="Number field", description="With a description", v-model="number")
        app-number-field(label="Currency number field", description="With a description", v-model="currency", type="currency", :currency="currencyObject")


        app-checkbox-field(option-label="Select me" label="Checkbox with a label", name="tAndC", v-model="cb1")
        app-checkbox-field(option-label="Select me" label="Checkbox with a label", description="And a description", name="tAndC", v-model="cb2")
        app-checkbox-field(option-label="No label at all, just inline" name="tAndC", v-model="cb3", :show-label="false")

        app-radio-field(name="rf" label="Radio field with a question: Yes or No?" :options="yesNoOptions" v-model="rf")

        app-stepper-field(description="Basic number stepper", v-model="numberStepper")
        app-stepper-field(description="Stepper with units", suffix="gb", :step-size="50", v-model="gbStepper")

    app-panel
      app-header
        | Form controls
        template(#subheader) Non text based inputs

      app-checkbox.mb-4(label="Normal checkbox", v-model="checkboxValue") Hello
      app-checkbox.mb-4(label="Indeterminate checkbox", :value="indeterminateCheckboxValue", @input="changeIndeterminateValue" :indeterminate="indeterminate")
      app-toggle-switch-field(name="test" label="Toggle switch" description="Enable or disable something immediately" v-model="toggleButton" true-label="I'm enabled" false-label="Now I'm not")

    app-panel
      app-header Categorised list

      div(style="width: 500px;")
        app-categorised-list-editor(:categories="categories" :value="categorisedList", resource-type-label="email address" @input="testInput")
</template>

<script>
import AppCategorisedListEditor from "@/components/collections/AppCategorisedListEditor.vue";
import AppCheckbox from "@/components/elements/AppCheckbox.vue";
import AppCheckboxField from "@/components/elements/fields/AppCheckboxField.vue";
import AppDropdownField from "@/components/elements/fields/AppDropdownField.vue";
import AppHeader from "@/components/elements/AppHeader.vue";
import AppPanel from "@/components/elements/AppPanel.vue";
import AppStepperField from "@/components/elements/fields/AppStepperField.vue";
import AppTab from "@/components/elements/AppTab.vue";
import AppTabs from "@/components/elements/AppTabs.vue";
import AppTextInput from "@/components/elements/AppTextInput.vue";
import AppTextField from "@/components/elements/fields/AppTextField.vue";
import AppDateField from "@/components/elements/fields/AppDateField.vue";
import AppNumberField from "@/components/elements/fields/AppNumberField.vue";
import AppRadioField from "@/components/elements/fields/AppRadioField.vue";
import AppToggleSwitchField from "@/components/elements/fields/AppToggleSwitchField.vue";
import PropsTable from "@/views/demos/components/PropsTable.vue";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    AppCategorisedListEditor,
    AppCheckbox,
    AppCheckboxField,
    AppDropdownField,
    AppHeader,
    AppPanel,
    AppStepperField,
    AppTab,
    AppTabs,
    AppTextInput,
    AppTextField,
    AppDateField,
    AppRadioField,
    AppNumberField,
    AppToggleSwitchField,
    PropsTable,
  },
  data() {
    return {
      testing: null,
      checkboxValue: false,
      indeterminateCheckboxValue: false,
      indeterminate: true,
      searchValue: "",
      dropdownOptions: [
        { id: 1, name: "test", label: "Option 1" },
        { id: 2, name: "test", label: "Option 2" },
        { id: 3, name: "test", label: "Option 3" },
      ],
      dropdownValue: null,
      multiDropdownValue: null,
      date: new Date(),
      number: null,
      currency: null,
      currencyObject: {
        symbol: "£",
        symbolFirst: true,
        decimalMark: ".",
        thousandsSeparator: ",",
        precision: 2,
      },
      inputs: {
        invalid: "",
        invalidDropdown: "",
      },
      cb1: false,
      cb2: false,
      cb3: false,
      rf: "yes",
      numberStepper: 1,
      gbStepper: 50,
      yesNoOptions: [
        { name: "Yes", value: "yes" },
        { name: "No", value: "no" },
      ],
      toggleButton: false,
      props: {
        input: [
          {
            name: "value",
            type: "String, Number",
            desc: "The value of the input. Can be bound with `v-model`",
            required: false,
          },
          {
            name: "icon",
            type: "String",
            desc:
              "The icon to display. This will appear on the left end of the input",
            required: false,
          },
          {
            name: "type",
            type: "String",
            desc: "The type of text input. This will set the `type` attribute",
            required: false,
            default: "text",
          },

          {
            name: "error",
            type: "Boolean",
            desc: "Whether the input is in an error state",
            required: false,
            default: "false",
          },

          {
            name: "borderless",
            type: "Boolean",
            desc: "Whether the input should display a border",
            required: false,
            default: "false",
          },

          {
            name: "clearable",
            type: "Boolean",
            desc:
              "Whether the input should be clearable. A clear icon will appear to the right of the input",
            required: false,
            default: "false",
          },
        ],
        field: [],
      },
      categories: [{ label: "test", value: "test" }],
      categorisedList: [],
    };
  },
  validations() {
    return {
      inputs: {
        invalid: {
          required,
        },
        invalidDropdown: {
          required,
        },
      },
    };
  },
  methods: {
    changeIndeterminateValue() {
      if (this.indeterminateCheckboxValue) {
        this.indeterminateCheckboxValue = false;
        this.indeterminate = false;
      } else if (this.indeterminate) {
        this.indeterminateCheckboxValue = true;
        this.indeterminate = false;
      } else {
        this.indeterminate = true;
      }
    },
    testInput(input) {
      console.log(input);
      this.categorisedList = input;
    },
  },
  mounted() {
    this.$v.$touch();
  },
};
</script>
