<template lang="pug">
  transition(name="fade", appear, v-on="$listeners")
    .fixed.top-0.left-0.right-0.bottom-0.z-100.bg-overlay(v-if="open")
      .flex.flex-row.items-center.justify-center.h-full.w-full
        .dialog-container
          slot(#default="{ hide }")
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    hide() {
      this.open = false;
      setTimeout(() => {
        this.$emit("hide");
      }, 250);
    },
    show() {
      this.open = true;
    },
  },
  watch: {
    value: {
      handler(open, oldValue) {
        this.open = open;
        if (!open && oldValue !== undefined) {
          setTimeout(() => {
            this.$emit("hide");
          }, 250);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="postcss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.dialog-container {
  @apply absolute;
  top: 10%;
}
.z-100 {
  z-index: 100;
}
</style>
