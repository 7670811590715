<script>
import { mergeData } from "vue-functional-data-merge";
import AppLoadingSpinner from "@/components/elements/AppLoadingSpinner.vue";
import AppIcon from "@/components/elements/AppIcon.vue";

export default {
  functional: true,
  props: {
    primary: {
      type: Boolean,
      required: false,
      default: false,
    },
    danger: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    slim: {
      type: Boolean,
      required: false,
      default: false,
    },
    round: {
      type: Boolean,
      required: false,
      default: false,
    },
    href: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    loadingText: {
      type: String,
      required: false,
      default: "Loading...",
    },
    split: {
      type: Boolean,
      required: false,
      default: false,
    },
    options: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  render(createElement, { props, slots, data, listeners }) {
    let classes = ["cursor-pointer", "inline-block"];

    if (props.round) {
      classes.push(
        "rounded-full",
        "w-12",
        "h-12",
        "inline-flex",
        "flex-row",
        "items-center",
        "justify-center"
      );
    } else {
      classes.push("px-4", props.slim ? "py-1" : "py-3", "rounded");
    }
    if (props.primary || props.danger) {
      classes.push(
        "border",
        "text-white",
        "font-bold",
        "hover:shadow",
        "active:shadow-none"
      );

      if (props.primary) {
        classes.push(
          "border-tribal-aqua",
          "bg-tribal-aqua",
          "hover:bg-diving-sapphire",
          "hover:border-diving-sapphire"
        );
      } else {
        classes.push(
          "border-withered-cherry",
          "bg-withered-cherry",
          "hover:bg-split-crimson",
          "hover:border-split-crimson"
        );
      }
    } else {
      classes.push(
        "border",
        "border-grey-50",
        "hover:bg-grey-20",
        "active:bg-grey-40"
      );
    }

    let element = "div";
    let newData = {
      class: classes,
    };

    if (listeners.click) {
      const originalClick = listeners.click;
      data.on.click = evt => {
        if (!props.loading && originalClick) {
          originalClick(evt);
        }
      };
    }

    if (props.href) {
      element = "a";
      newData.attrs = { href: props.href };
      classes.push("no-underline", "hover:no-underline", "hover:text-white");
    }

    let content;
    if (props.loading) {
      classes.push("inline-flex", "flex-row", "items-center");
      content = [
        createElement(AppLoadingSpinner, { staticClass: "mr-4 flex-initial" }),
        createElement(
          "span",
          { staticClass: "flex-initial" },
          props.loadingText
        ),
      ];
    } else if (props.icon) {
      classes.push("inline-flex", "flex-row", "items-center");
      content = [
        createElement(AppIcon, {
          props: { icon: props.icon, colour: "inherit" },
          staticClass: "mr-4 flex-initial",
        }),
        createElement("span", { staticClass: "flex-initial" }, slots().default),
      ];
    } else {
      content = slots().default;
    }
    return createElement(element, mergeData(data, newData), content);
  },
};
</script>
