<template lang="pug">
  .w-full
    app-panel
      app-header App icons

      .grid.grid-cols-4.gap-4
        .span-1(v-for="icon in icons", :key="icon")
          app-header(size="h4") {{ icon }}

          .flex.flex-row
            app-icon(:icon="icon", @click.native="test")
            app-icon.ml-4(:icon="icon", size="medium")
            app-icon.ml-4(:icon="icon", size="large")
            app-icon.ml-4(:icon="icon", size="xl")

    app-panel
      app-header File icons

      .grid.grid-cols-8.gap-4
        .span-1(v-for="ext in fileIcons", :key="ext")
          app-header(size="h4") {{ ext }}

          .flex.flex-row
            file-icon(:extension="ext", size="small")
            file-icon.ml-4(:extension="ext")
</template>

<script>
import AppHeader from "@/components/elements/AppHeader.vue";
import AppPanel from "@/components/elements/AppPanel.vue";
import AppIcon from "@/components/elements/AppIcon.vue";
import extMappings from "@/config/fileExtensionMapping";
import FileIcon from "@/components/elements/AppFileIcon.vue";

export default {
  components: {
    AppHeader,
    AppIcon,
    AppPanel,
    FileIcon,
  },
  data() {
    return {
      icons: [
        "accounts",
        "actions",
        "activity",
        "add",
        "addDocument",
        "addFolder",
        "addUserLicense",
        "address",
        "addTask",
        "backArrow",
        "baselines",
        "bill",
        "books",
        "calendar",
        "caretDown",
        "caretLeft",
        "caretRight",
        "caretUp",
        "changeOrder",
        "checklist",
        "circleSolid",
        "close",
        "collapseAll",
        "columns",
        "company",
        "contact",
        "contacts",
        "copy",
        "convert",
        "creditCard",
        "dashboard",
        "delete",
        "document",
        "download",
        "dragHandle",
        "edit",
        "email",
        "envelope",
        "error",
        "estimate",
        "expandAll",
        "export",
        "external",
        "facebookSolid",
        "filter",
        "finance",
        "form",
        "fullscreen",
        "gantt",
        "globe",
        "import",
        "info",
        "invoice",
        "kpi",
        "link",
        "linkedInSolid",
        "lowPriority",
        "milestone",
        "mobilePhone",
        "move",
        "moveDocument",
        "moveFolder",
        "normalPriority",
        "notification",
        "notificationSolid",
        "padlock",
        "paid",
        "phone",
        "plan",
        "preview",
        "print",
        "project",
        "purchaseOrder",
        "rateCard",
        "refresh",
        "removeUserLicense",
        "report",
        "roles",
        "search",
        "send",
        "share",
        "snapshot",
        "subscription",
        "subtract",
        "success",
        "support",
        "table",
        "template",
        "thumbsDown",
        "thumbsUp",
        "timesheetEntry",
        "tick",
        "task",
        "taxRate",
        "twitterSolid",
        "question",
        "unpaid",
        "upload",
        "user",
        "users",
        "void",
        "warning",
        "youtube",
        "youtubeSolid",
        "zoomIn",
        "zoomOut",
      ],
      fileIcons: Object.keys(extMappings),
    };
  },
  methods: {
    test() {
      console.log("testing");
    },
  },
};
</script>
